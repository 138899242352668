<template>
  <div class="content">
    <div class="main">

      <div class="title">
        <span>영업비밀 침해행위</span>
      </div>
      <div class="flex3">
        <span>가</span>
        <p>
          절취, 기망, 협박, 그 밖의 부정한 수단으로 영업비밀을 취득하는 행위 또는<br/>
          그 취득한 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
      <div class="flex3">
        <span>나</span>
        <p>
          영업비밀에 대하여 부정취득행위가 개입된 사실을 알거나 중대한 과실로 알지 못하고<br/>
          그 영업비밀을 취득하는 행위 또는 그 취득한 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
      <div class="flex3">
        <span>다</span>
        <p>
          영업비밀을 취득한 후에 그 영업비밀에 대하여 부정취득행위가 개입된 사실을 알거나<br/>
          중대한 과실로 알지 못하고 그 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
      <div class="flex3">
        <span>라</span>
        <p>
          계약관계 등에 따라 영업비밀을 비밀로서 유지하여야 할 의무가 있는 자가 부정한 이익을<br/>
          얻거나 그 영업비밀의 보유자에게 손해를 입힐 목적으로 그 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
      <div class="flex3">
        <span>마</span>
        <p>
          영업비밀이 위의 ‘라’에 따라 공개된 사실 또는 그러한 공개행위가 개입된 사실을 알거나 중대한 과실로<br/>
          알지 못하고 그 영업비밀을 취득하는 행위 또는 그 취득한 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
      <div class="flex3">
        <span>바</span>
        <p>
          영업비밀을 취득한 후에 그 영업비밀이 위의 ‘라’에 따라 공개된 사실 또는 그러한 공개행위가<br/>
          개입된 사실을 알거나 중대한 과실로 알지 못하고 그 영업비밀을 사용하거나 공개하는 행위
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',
    components: {
    },
    data() {
      return {
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .main{display: flex;flex-direction: column;align-items: center;}
  .main .title{width:400px;border-bottom:1px solid #000000;text-align: center;padding:10px 0;margin-bottom:20px;}
  .main .title span{font-size:24px;}
  .main .flex{display: flex;margin:30px 0 50px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex2{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex3{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex4{display: flex;align-items: flex-start;justify-content: center;width:80%;flex-direction: column;height:200px;}
  .main .flex img{flex-shrink: 0;}
  .main .flex p{width:100%;line-height:1.6;font-size:18px;}
  .main .remark{width:60%;margin:30px 0 50px 0;font-size:18px;}
  .main .btn{display: flex;justify-content: flex-end;width:100%;}
  .main .btn a{position: absolute;margin-top:-70px;border:1px solid #01388B;padding:6px 40px 6px 10px;background-image: url(../assets/img/point_right_act.png);background-position: center right 10px;background-repeat: no-repeat;}
  .main .btn a:hover{background-color: #01388B;color:#ffffff;background-image: url(../assets/img/point_right.png);}
  .main .table{background: rgba(136, 136, 136, 0.1);display: flex;flex-direction: column;width:100%;margin:0 10px;padding-bottom:30px;align-items: center;}
  .main .table p{background: rgba(1, 56, 139, 0.7);margin-bottom:20px;border-radius: 10px 10px 0 0;width:100%;display: flex;align-items: center;justify-content: center;color:#ffffff;padding:10px 0;letter-spacing: 20px;}
  .main .table span{display: flex;align-items: center;justify-content: center;margin:10px 0;width:100%;}
  .main .table span a{color: #0020C9;border-bottom:1px solid #0020C9;}
  .main .flex6{display: flex;width:100%;margin:30px 0;}
  .main .flex2{display: flex;flex-direction: column;width:100%;border-radius: 10px; overflow: hidden;}
  .main .flex2 span{display: flex;width:100%;}
  .main .flex2 span:nth-child(1) label{background-color: #4D74AE !important;color:#ffffff;}
  .main .flex2 span:nth-child(2n+1) label{background-color:#F3F3F3;}
  .main .flex2 span label:nth-child(1){width:200px;flex-shrink: 0;margin-right:5px;}
  .main .flex2 span label:nth-child(2){width:100%;}
  .main .flex2 span label{padding:10px 0;padding-left:40px;}
  .main h3{font-size:20px;margin-bottom:10px;}
  .main .flex3 span{width:120px;height:60px;flex-shrink: 0;font-size:30px;font-weight: bold;display: flex;align-items: center;justify-content: center;}
  .main .flex3 p{width:100%;font-size:18px;}
  .main .flex4 span{justify-content: flex-start;width:100%;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{margin:0 20px;padding:10px 0 !important;}
    .img{width:100%;}
    .main .title{width:180px;margin-bottom:10px;}
    .main .title span{font-size:18px;}
    .main .flex p{line-height:1.6;font-size:14px !important;}
    .main .remark{width:100%;margin:20px 0;font-size:14px !important;}
    .main .flex2 span label:nth-child(1){width:100px;margin-right:2px;}
    .main .flex2 span label{padding-left:10px;font-size:12px;}
    .main .flex3{margin:20px 0;width:100%;}
    .main .flex4{margin:0 0;flex-direction: column;width:80%;height:auto;}
    .main .flex6{flex-direction: column;}
    .main .table{width:100%;margin:0 !important;margin-bottom:20px !important;}
    .main .table span{font-size:14px;}
    .main h3{font-size:12px;margin-bottom:10px;letter-spacing:-1px;}
    .main .flex3 span{width:20px;height:20px;font-size:18px;margin-right: 20px;}
    .main .flex3 p{width:100%;font-size:13px;}
    .bg{position: absolute;z-index: -1;width:100vw;left:0;opacity: 0.2;display: flex;align-items: center;justify-content: center;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
