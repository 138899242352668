<template>
  <div class="content">
    <div class="main">
      <div class="title">
        <span>관련 법령</span>
      </div>
      <div class="title2">
        국　　내　　법
      </div>
      <div class="flex5 about" :class="showDH ? 'slide-in' : ''">
        ○ <a target="_blank" href="https://www.law.go.kr/%EB%B2%95%EB%A0%B9/%EB%B6%80%EC%A0%95%EA%B2%BD%EC%9F%81%EB%B0%A9%EC%A7%80%EB%B0%8F%EC%98%81%EC%97%85%EB%B9%84%EB%B0%80%EB%B3%B4%ED%98%B8%EC%97%90%EA%B4%80%ED%95%9C%EB%B2%95%EB%A5%A0">부정경쟁방지 및 영업비밀보호에 관한 법률</a><br/>
        <br/>
        ○ <a target="_blank" href="https://www.law.go.kr/%EB%B2%95%EB%A0%B9/%EC%82%B0%EC%97%85%EA%B8%B0%EC%88%A0%EC%9D%98%EC%9C%A0%EC%B6%9C%EB%B0%A9%EC%A7%80%EB%B0%8F%EB%B3%B4%ED%98%B8%EC%97%90%EA%B4%80%ED%95%9C%EB%B2%95%EB%A5%A0">산업기술의 유출방지 및 보호에 관한 법률</a>
      </div>
      <div class="title2">
        국　　제　　법
      </div>
      <div class="flex5 about" :class="showDH ? 'slide-in' : ''">
        <h1>UN</h1>
        ○ 유엔 경제 사회 이사회(United Nations Economic and Social Council, ECOSOC) 산하의 세계 지식 재산권 기구<br class="pc"/>
        　(World Intellectual Property Organization, WIPO)에서 업무 관할
        <br/><br/>
        ○ 국제 조약<br/>
        　- WIPO가 관리하는 산업 재산 보호를 위한 파리 협약(Paris Convention)은 세계 무역 기구(WTO)의 무역 관련 지적<br class="pc"/>
        　재산권 협정(TRIPS 협정)과 마찬가지로 부분적으로 영업 비밀 보호를 다루고 있다.
        <br/>
        　- <a target="_blank" href="https://www.wipo.int/treaties/en/ip/paris/">WIPO의 산업 재산 보호를 위한 파리 협약(Paris Convention)</a><br/>
        　- <a target="_blank" href="https://www.wto.org/english/tratop_e/trips_e/trips_e.htm">WTO의 무역 관련 지적 재산권 협정(TRIPS Agreement)</a><br/><br/>


        ○ IP 법률 및 조약(WIPO Lex)<br/>
        　- WIPO Lex 데이터베이스는 지적 재산에 관한 국내법 및 국제 조약을 검색할 수 있는 종합 검색 도구이다.<br/>
        　- <a target="_blank" href="https://www.wipo.int/wipolex/en/">WIPO Lex</a><br/>
        　- <a target="_blank" href="https://www.wipo.int/wipolex/en/main/legislation">법률 검색</a><br/>
        　- <a target="_blank" href="https://www.wipo.int/wipolex/en/main/treaties">조약 검색</a>

        <h1>미국(United States of America, USA)</h1>
        ○ <a target="_blank" href="https://www.wipo.int/wipolex/en/legislation/results?countryOrgs=US&subjectMatter=9&last=true">WIPO Lex 법률 검색</a><br/>
        　- 법률/규정 14건 (2007 ~ 2016)<br/>
        　- 지식 재산권 법률 문헌 6건 (1939 ~ 2015)<br/><br/>        ○ <a target="_blank" href="https://www.uniformlaws.org/committees/community-home?CommunityKey=3a2538fb-e030-4e2d-a9e2-90373dc05792">영업 비밀 통일법(The Uniform Trade Secrets Act, UTSA)</a><br/><br/>
        ○ <a target="_blank" href="https://www.congress.gov/bill/114th-congress/senate-bill/1890">영업 비밀 보호법(The Defend Trade Secrets Act of 2016, DTSA)</a><br/><br/>
        ○ <a target="_blank" href="https://www.uscourts.gov/rules-policies/current-rules-practice-procedure/federal-rules-civil-procedure">연방 민사 소송 규칙(Federal Rules of Civil Procedure)</a><br/><br/>
        ○ 뉴욕 -  <a target="_blank" href="https://www.nysenate.gov/legislation/laws/GBS/-CH20">New York's General Business Law (GBL)</a><br/><br/>
        ○ 캘리포니아 - <a target="_blank" href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CIV&division=4.&title=5.&part=1.&chapter=&article">California Uniform Trade Secrets Act (CUTSA)</a><br/><br/>
        ○ 델라웨어 - <a target="_blank" href="https://delcode.delaware.gov/title6/c020/">Title 6, Chapter 20 of the Delaware Code</a><br/><br/>
        ○ 조지아 - <a target="_blank" href="https://law.justia.com/codes/georgia/2020/title-10/chapter-1/article-27/">Georgia Trade Secrets Act (GTSA)</a><br/><br/>
        ○ 미시간 - <a target="_blank" href="https://www.legislature.mi.gov/mileg.aspx?page=GetObject&objectname=mcl-act-448-of-1998">Michigan Uniform Trade Secrets Act (MUTSA)</a><br/><br/>

        <h1>유럽 연합(European Union, EU)</h1>
        ○ 유럽은 특허를 보호받을 수 있는 방법이 2가지가 있다.<br/>
        <br/>
        ○ 첫째, 각 EU 회원국들이 자체적으로 운영하고 있는 특허 제도에 따른 권리의 보호이다. 이 형태의 제도는 기존의<br class="pc"/>
        　국가별로 운영되고 있는 특허 제도(예로 우리나라, 일본, 미국 등과 같은 국가의 특허 제도)와 거의 동일한 내용의 제도이다.<br/>        　- <a target="_blank" href="https://www.wipo.int/wipolex/en/main/legislation">WIPO Lex의 법률 검색에서 각 국가별로 조회</a><br/><br/>

        ○ 둘째, 유럽 특허청(European Patent Office, EPO)를 통하여 특허 등록을 받는 방법이다. 유럽 특허청에 제출한<br class="pc"/>
        　하나의 특허 출원을 통해 유럽의 여러 국가에서 특허를 취득할 수 있는 제도이다.<br/>
        　- <a target="_blank" href="https://new.epo.org/en/legal/epc">유럽 특허 협약(European Patent Convention, EPC)</a><br/>
        　- <a target="_blank" href="https://www.wipo.int/wipolex/en/legislation/results?countryOrgs=EU&subjectMatter=9&last=true">법률/규정 14건 (1957 ~ 2018)</a>

        <h1>중국(China)</h1>
        ○ <a target="_blank" href="https://www.wipo.int/wipolex/en/legislation/results?countryOrgs=CN&subjectMatter=9&last=true">WIPO Lex 법률 검색</a><br/>
        　- 법률/규정 11건 (1997 ~ 2021)<br/>
        　- 지식 재산권 법률 문헌 1건 (2021)
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',
    components: {
    },
    data() {
      return {
        showDH:false,
        url: {

        }
      }
    },
    computed: {

    },
    created(){
      const that = this;
      setTimeout(function(){
        that.showDH = true;
      },100)
    },
    methods:{

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .main{display: flex;flex-direction: column;align-items: center;width:100%;}
  .main .title{width:400px;border-bottom:1px solid #000000;text-align: center;padding:10px 0;margin-bottom:20px;}
  .main .title span{font-size:24px;}
  .about{position:relative;top: -50px;transition: all 1s ease;opacity: 0;}
  .slide-in {top: 0;opacity: 1;}

  .main .title2{font-size:24px;color:rgba(1, 56, 139, 1);margin:10px 0;}
  .main .flex{display: flex;margin:30px 0 50px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex2{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex3{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex4{display: flex;align-items: flex-start;justify-content: center;width:80%;flex-direction: column;height:200px;}
  .main .flex5{width:90%;font-size:18px;line-height:1.6;}
  .main .flex5 a{color:rgba(0, 32, 201, 1);text-decoration: underline;}
  .main .flex5 h1{margin:20px 0;font-size:20px;}
  .main .flex img{flex-shrink: 0;}
  .main .flex p{width:100%;line-height:1.6;font-size:18px;}
  .main .remark{width:60%;margin:30px 0 50px 0;font-size:18px;}
  .main .btn{display: flex;justify-content: flex-end;width:100%;}
  .main .btn a{position: absolute;margin-top:-70px;border:1px solid #01388B;padding:6px 40px 6px 10px;background-image: url(../assets/img/point_right_act.png);background-position: center right 10px;background-repeat: no-repeat;}
  .main .btn a:hover{background-color: #01388B;color:#ffffff;background-image: url(../assets/img/point_right.png);}
  .main .table{background: rgba(136, 136, 136, 0.1);display: flex;flex-direction: column;width:100%;margin:0 10px;padding-bottom:30px;align-items: center;}
  .main .table p{background: rgba(1, 56, 139, 0.7);margin-bottom:20px;border-radius: 10px 10px 0 0;width:100%;display: flex;align-items: center;justify-content: center;color:#ffffff;padding:10px 0;letter-spacing: 20px;}
  .main .table span{display: flex;align-items: center;justify-content: center;margin:10px 0;width:100%;}
  .main .table span a{color: #0020C9;border-bottom:1px solid #0020C9;}
  .main .flex6{display: flex;width:100%;margin:30px 0;}
  .main .flex2{display: flex;flex-direction: column;width:100%;border-radius: 10px; overflow: hidden;}
  .main .flex2 span{display: flex;width:100%;}
  .main .flex2 span:nth-child(1) label{background-color: #4D74AE !important;color:#ffffff;}
  .main .flex2 span:nth-child(2n+1) label{background-color:#F3F3F3;}
  .main .flex2 span label:nth-child(1){width:200px;flex-shrink: 0;margin-right:5px;}
  .main .flex2 span label:nth-child(2){width:100%;}
  .main .flex2 span label{padding:10px 0;padding-left:40px;}
  .main h3{font-size:20px;margin-bottom:10px;}
  .main .flex3 span{width:120px;height:60px;flex-shrink: 0;font-size:30px;font-weight: bold;display: flex;align-items: center;justify-content: center;}
  .main .flex3 p{width:100%;font-size:18px;}
  .main .flex4 span{justify-content: flex-start;width:100%;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{margin:0 20px;padding:10px 0 !important;}
    .img{width:100%;}
    .main .title{width:180px;margin-bottom:10px;}
    .main .title span{font-size:18px;}
    .main .title2{font-size:18px;}
    .main .flex p{line-height:1.6;font-size:14px !important;}
    .main .remark{width:100%;margin:20px 0;font-size:14px !important;}
    .main .flex2 span label:nth-child(1){width:100px;margin-right:2px;}
    .main .flex2 span label{padding-left:10px;font-size:12px;}
    .main .flex3{margin:20px 0;width:100%;}
    .main .flex4{margin:0 0;flex-direction: column;width:80%;height:auto;}
    .main .flex6{flex-direction: column;}
    .main .table{width:100%;margin:0 !important;margin-bottom:20px !important;}
    .main .table span{font-size:14px;}
    .main h3{font-size:12px;margin-bottom:10px;letter-spacing:-1px;}
    .main .flex3 span{width:20px;height:20px;font-size:18px;margin-right: 20px;}
    .main .flex3 p{width:100%;font-size:13px;}
    .bg{position: absolute;z-index: -1;width:100vw;left:0;opacity: 0.2;display: flex;align-items: center;justify-content: center;}
    .main .flex5{width:100%;font-size:13px;line-height:2;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .main .flex5{width:100%;font-size:15px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
