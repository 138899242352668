import Vue from 'vue'
import jsrsasign from 'jsrsasign'
import Encrypt from "encryptlong"
const PUBLIC_KEY = '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq3A5Xr/p+NKaVRF4maWhcpiTQq2J+gUXGUDPls3+xn5xXlGxhLdE6QfoN4Ce25SoQmYWV1kzFGVByB7L4lbNjfVSCuUYSsNn2/dvu354QtI8WTGOsc4NLBRMmt+fV1rtFQHqNMGSQCKTTcV9jnctCgdu3PLG2TppqtZby/RA6kl2pRQ0gaU8T2Vsxl3l6Z4OdfhmgE+L7L/l1oMVGNE+DxV7ZJvJ1TQnMdswaGZdUCg4Ii5a6eDJR1ZFInR2WRMQ/NcGGZFts9bs6fX7GUjDZcFWabzOrLk9QD+8vkuaSRIrJEeYmqioK6RE6kw9u0pRhldibT1mmiV1NL4fbFuWkQIDAQAB-----END PUBLIC KEY-----';

const PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCrcDlev+n40ppVEXiZpaFymJNCrYn6BRcZQM+Wzf7GfnFeUbGEt0TpB+g3gJ7blKhCZhZXWTMUZUHIHsviVs2N9VIK5RhKw2fb92+7fnhC0jxZMY6xzg0sFEya359XWu0VAeo0wZJAIpNNxX2Ody0KB27c8sbZOmmq1lvL9EDqSXalFDSBpTxPZWzGXeXpng51+GaAT4vsv+XWgxUY0T4PFXtkm8nVNCcx2zBoZl1QKDgiLlrp4MlHVkUidHZZExD81wYZkW2z1uzp9fsZSMNlwVZpvM6suT1AP7y+S5pJEiskR5iaqKgrpETqTD27SlGGV2JtPWaaJXU0vh9sW5aRAgMBAAECggEAU9mh1bvb/f1TysOuzsd5/YiujfA/CfAvagSPA91g/UievlkKkDTrGWbqpyrbh95fpYo05ygTmagk0nJtmhiGlSYlqHqAtSB5zdZp6bRjsZsONTnN5x02yBZtmVpCnwEz8BBtsrvygjfnACTV0jZr6vnTuXFs3JZ7VDlf0JN4pJ3YXsmozJ9tvZvbTryUvLi/NgrPBahGaHYooXBhAnLSS7lqnH50GwrJI6JYAroPb0Ytma+gntLZ6KHXFEGpbagskQsgf1LiNP0C+ZkvKnwS/pG+2G16BSxNRlYKZ5dENALrT94QRH5CGWYodQuZ+3CuOqKO/N1SW3HbeurfJmToGQKBgQDg0mAQRdlJbAAxWrVE8L8r67vMd155bb/HfGWXWPbT9sonA30rgN4cBe3Nx26Y9cN/BihL8SNEZKxE1YZM1HaW+ykfCLutot2TCHcGql/EsXvYCKuQKJXXuDdhES5vgXFkHnWpqa2sZvh9uBzTWG1ImgxzB0vjrj0bTR/PjZa2lwKBgQDDNqF9Am+GnVtae76UeIgGJ1DEZEf7J1HkTyZOA+6AqROEYQwVv3nm1F3/geppiSI4ZlYhAGleAtoM5whH1x19NRlXSe11P+SiZMLZohPvROKwZ8YB8PGvVnUxSWWXLbwYxDlHI9mw35Viknx6MPE+T3aLaVvyLwI4WuzNkWUpFwKBgAiJ1N4pWPRB5sv5DvNcVVZcsJg2XF8uPN9dqRoTZeRs9gZ/Smyxiz3zN7HeBiRHCTK00Cj6MoWxcR14Io6ebxaS2V/1W/NfQR0JcEPPiq2UtAhjY+iosfAsbeYA2gugFedghMV5zT/D9WII8QdRz2y3dQuVVluCTY3Q4wll7jhnAoGBALz6ohK2zOhwZQOPT8vDc0shvFqjCdvlEK4IwXg87iK3iyFYIa7cElKXu/eh+R/5lZsr1q1UDKzp7dUXwzho0y9mvAV4l9wt4lQjX034TXwQH7POgi1W52FqPUFdYG+d4r3HF/v6hL9pcuBeFwrjXmeH3abQdCZbw7Zyr1PCMUA5AoGBAMMPo13YAe18TWIvi5DHFvkE4DpAeM+HqgZXKqEUoqUNOeAzvzaMhOyF44qPMi0XdSCRQOIBeQyesGik5SG5WC0khUrL5Dkzrk+rG+NmrOvN4P1+nyTnib4RuK/VznTklMsoLZGSfYgABrmLoPfJdxnJ12eT5jqIv4icEZ6fDdRY-----END PRIVATE KEY-----';

// 加密方法  公钥
Vue.prototype.$getCode  = function(password){
    var rsa = new jsrsasign.RSAKey();
    //因为后端提供的是pck#8的密钥对，所以这里使用 KEYUTIL.getKey来解析密钥
    rsa = jsrsasign.KEYUTIL.getKey(PRIVATE_KEY);
    // rsa.readPrivateKeyFromPEMString(priK);
    let sig = new jsrsasign.KJUR.crypto.Signature({"alg": "SHA256withRSA"});
    // 初始化
    sig.init(rsa)
    // 传入待加密字符串
    sig.updateString(password)
    // 生成密文
    //let sign = jsrsasign.hextob64(sig.sign());
    //let sign = jsrsasign.hextoArrayBuffer(sig.sign())
    let sign = jsrsasign.hextob64(sig.sign())
    return sign
}
//    解密方法 私钥
Vue.prototype.$validateCode  = function(signData, data) {
  // signData: 加签之后得到的签文
  // data: 加签的数据
  try {
    // 验签
    let signatureVf = new jsrsasign.KJUR.crypto.Signature({
      alg: "SHA256withRSA",
      prvkeypem: PUBLIC_KEY,
    });
    signatureVf.updateString(data);
    //let result = signatureVf.verify(jsrsasign.ArrayBuffertohex(signData));
    let result = signatureVf.verify(jsrsasign.b64utohex(signData));
    console.log("jsrsasign verify: " + result);
    return result;
  } catch (e) {
    console.error(e);
  }
}
