<template>
  <div>
    <div class="pc titlebg">
      <h1 style="font-size:60px;font-family: serif;">FAQ</h1>
      <span style="margin-top:10px;font-family: serif;margin-bottom: 30px;font-weight: bold;">Frequently Asked Questions</span>
    </div>
    <div class="main content">
      <div class="menu">
        <a :class="menuIndex == -1 ? 'act' : ''" @click="menuIndex = -1">자주하는 질문</a>
        <hr class="pc" style="margin:10px 0;height:1px;width:80%;background-color: #000000;border:none;"/>
        <a :class="menuIndex != -1 ? 'act' : ''" class="other pc">이용절차</a>
        <span :class="menuIndex == 1 ? 'act' : ''" @click="menuIndex = 1">전자지문 추출</span>
        <span :class="menuIndex == 2 ? 'act' : ''" @click="menuIndex = 2">원본 파일 보관</span>
        <span :class="menuIndex == 3 ? 'act' : ''" @click="menuIndex = 3">파일 뷰어</span>
        <span :class="menuIndex == 4 ? 'act' : ''" @click="menuIndex = 4">원본증명서</span>
      </div>
      <div class="list" v-if="menuIndex == -1">
        <div class="item" v-for="(item,index) in list" :key="index">
          <div :class="'title ' + (ind == index ? 'act' : '')" @click="ind == index ? ind = -1 : ind = index">{{item.question}}</div>
          <div class="remark" v-if="index == ind">
            {{item.answer}}
          </div>
        </div>
      </div>
      <div class="listNew" v-if="menuIndex == 1">
        <div style="margin-bottom:30px;">영업비밀파일 전자지문 추출 이용 절차 안내</div>
        <div class="item">
          <img src="../assets/img2/faq9.png"/>
          <div class="cont">
            <p>1. 회원가입 및 로그인</p>
            <div>
              - 휴대폰 및 메일 인증 후 인증서 등록 후 가입 및 로그인<br/>
              - 기업일 경우 사업자번호 확인 후 가입 및 로그인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq8.png"/>
          <div class="cont">
            <p>2. 영업비밀파일 전자지문 보관의뢰 선택</p>
            <div>
              - 로그인후 -> 서비스 이용 -> 보관의뢰 메뉴 선택<br/>
              　　　　-> 원본파일업로드 버튼 선택
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq7.png"/>
          <div class="cont">
            <p>3. 전자지문추출용 영업비밀파일 업로드</p>
            <div>
              -  업로드 지원 하는 파일 확장자 확인 -> 업로드(파일 크기 확인됨)
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq5.png"/>
          <div class="cont">
            <p>4. 수수료 납부</p>
            <div>
              - 파일 개수 및 보관 기간에 따른 예상 결제 금액 확인 후 수수료 납부
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq4.png"/>
          <div class="cont">
            <p>5. 업로드 프로세싱 확인 후 전자지문 보관의뢰 완료</p>
            <div>
              - 결제 완료 -> 프로세싱 진행 -> 프로세싱 완료 및 확인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq2.png"/>
          <div class="cont">
            <p>6. 마이페이지에서 확인 가능</p>
            <div>
              - 로그인 후 -> 마이페이지 -> 비밀 보관 현황 확인
            </div>
          </div>
        </div>
      </div>

      <div class="listNew" v-if="menuIndex == 2">
        <div style="margin-bottom:30px;">영업비밀파일 원본파일 보관 이용 절차 안내</div>
        <div class="item">
          <img src="../assets/img2/faq9.png"/>
          <div class="cont">
            <p>1. 회원가입 및 로그인</p>
            <div>
              - 휴대폰 및 메일 인증 후 인증서 등록 후 가입 및 로그인<br/>
              - 기업일 경우 사업자번호 확인 후 가입 및 로그인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq8.png"/>
          <div class="cont">
            <p>2. 영업비밀파일 전자지문 보관의뢰 선택</p>
            <div>
              - 로그인후 -> 서비스 이용 -> 보관의뢰 메뉴 선택<br/>
              　　　　-> 원본파일업로드 버튼 선택
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq7.png"/>
          <div class="cont">
            <p>3. 전자지문추출용 영업비밀파일 업로드</p>
            <div>
              -  업로드 지원 하는 파일 확장자 확인 -> 업로드(파일 크기 확인됨)
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq6.png"/>
          <div class="cont">
            <p>4. 원본보관 옵션 선택</p>
            <div>
              - 원하는 영업비밀 원본 파일 선택(지원 파일 확장자 확인)
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq5.png"/>
          <div class="cont">
            <p>5. 수수료 납부</p>
            <div>
              - 파일 개수 및 보관 기간에 따른 예상 결제 금액 확인 후 수수료 납부
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq4.png"/>
          <div class="cont">
            <p>6. 업로드 프로세싱 확인 후 전자지문 보관의뢰 완료</p>
            <div>
              - 결제 완료 -> 프로세싱 진행 -> 프로세싱 완료 및 확인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq3.png"/>
          <div class="cont">
            <p>7. 전자지문 추출 및 원본보관 </p>
            <div>
              - 파일크기에 따라 시간이 어느정도 소요될 수 있음
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq2.png"/>
          <div class="cont">
            <p>8. 마이페이지에서 확인 가능</p>
            <div>
              - 로그인 후 -> 마이페이지 -> 비밀 보관 현황 확인
            </div>
          </div>
        </div>
      </div>

      <div class="listNew" v-if="menuIndex == 3">
        <div style="margin-bottom:30px;">뷰어 이용 절차 안내</div>
        <div class="item">
          <img src="../assets/img2/faq11.png"/>
          <div class="cont">
            <p>1. 로그인</p>
            <div>
              - 홈페이지 접속 -> 로그인(개인/기업)
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq2.png"/>
          <div class="cont">
            <p>2. 마이페이지에서 확인 가능</p>
            <div>
              - 마이페이지 -> 파일보관 현황 -> 열람이 가능한 자신의 영업비밀 원본 파일 확인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq10.png"/>
          <div class="cont">
            <p>3. 열람하기</p>
            <div>
              - 확인된 파일의 열람하기 메뉴선택
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq1.png"/>
          <div class="cont">
            <p>4. 파일 열람 및 다운로드</p>
            <div>
              - 열람 확인 후 다운로드가 가능한 영업비밀 원본 파일 일 경우 다운로드
            </div>
          </div>
        </div>
      </div>

      <div class="listNew" v-if="menuIndex == 4">
        <div style="margin-bottom:30px;">원본증명서 신청 및 출력</div>
        <div class="item">
          <img src="../assets/img2/faq11.png"/>
          <div class="cont">
            <p>1. 로그인</p>
            <div>
              - 홈페이지 접속 -> 로그인(개인/기업)
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq2.png"/>
          <div class="cont">
            <p>2. 증명서 신청 / 마이페이지</p>
            <div>
              - 로그인 후 우측 상단 증명서 발급 선택 혹은,<br/>
              - 마이페이지 -> 증명서 신청이 가능한 영업비밀 파일 확인
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq13.png"/>
          <div class="cont">
            <p>3. 원본증명서 발급 신청서 작성</p>
            <div>
              - 내용 입력 -> 영업비밀 원본 파일 업로드 -> 공동인증서 서명
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq14.png"/>
          <div class="cont">
            <p>4. 수수료 납부</p>
            <div>
              - 원본증명서 개수에 따른 수수료 확인 후 결제
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq15.png"/>
          <div class="cont">
            <p>5. 원본파일 전자지문 확인</p>
            <div>
              - 결제 완료 후 원본 진위 여부 프로세싱 진행<br/>
              - 원본이 맞으면 완료 / 틀린 경우 실패
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq16.png"/>
          <div class="cont">
            <p>6. 원본증명서 확인</p>
            <div>
              - 프로세싱 완료 후 원본증명서 열람 -> 증명서 다운로드 혹은,<br/>
              - 마이페이지 -> 증명서 신청 내역 -> 해당 증명서 다운로드
            </div>
          </div>
        </div>
        <div class="jt">
          <img src="../assets/img2/faq_jt.png"/>
        </div>
        <div class="item">
          <img src="../assets/img2/faq12.png"/>
          <div class="cont">
            <p>7. 원본증명서 발급 / 취소하기</p>
            <div>
              - 원본증명서 열람하여 내용 및 붙임파일 확인<br/>
              - 이상이 있거나 오타 및 수정사항 있을 시 취소하고 수정하여 재발급 가능
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Faq',
    components: {

    },
    data() {
      return {
        ind:-1,
        menuIndex:-1,
        list:[],
        url: {

        }
      }
    },
    computed: {

    },
    created(){
      const that = this;
      that.formLoading = true;
      if(this.$route.query.showType==1){
        this.menuIndex = 1
      }
      getAction('/user/userInformation/faqList').then(res => {
        if (res.success) {
          that.list = res.result;
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{padding:0 0 80px 0;margin:auto;}
  .listNew{width:100%;}
  .listNew .item{display: flex;margin:20px 0;}
  .listNew .item img{position: absolute;}
  .listNew .item .cont{display: flex;flex-direction: column;width:100%;height:120px;margin:9px 0 9px 69px;justify-content: flex-end;}
  .listNew .item .cont p{padding-left:80px;font-weight: bold;}
  .listNew .item .cont div{border-radius: 0 10px 10px 0;background-color: rgba(244, 244, 244, 1);width:80%;padding:20px 0 20px 80px;font-size:14px;height:82px;margin-top:5px;}
  .listNew .jt{width:138px;display: flex;align-items: center;justify-content: center;}
  .listNew .jt img{width:23px;}
  .titlebg{display: flex;align-items:center;display: flex;align-items: center;justify-content: center;flex-direction: column;color:#01388B;justify-content:center;height:400px;background-image: url(../assets/img/faq_bg.jpg);background-size:auto 100%;background-position: center;}
  .main{display: flex;margin-top:20px;}
  .main .menu{width:16%;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;color: #888888;margin:10px 0;cursor: pointer;letter-spacing: -1px;}
  .main .menu a:hover{color:rgba(1, 56, 139, 1);font-weight: bold;}
  .main .menu .other{display: flex;width:80%;justify-content: space-between;align-items: center;font-weight: bold;cursor: default;}
  .main .menu .other:hover span{color:rgba(1, 56, 139, 1);}
  .main .menu .other span{font-size: 20px;transform: rotate(90deg);font-weight: bold;}
  .main .menu span{font-size:16px;color: #888888;margin:10px 0;cursor: pointer;letter-spacing: -1px;font-family: 'Pretendard';}
  .main .menu span:hover{color:rgba(1, 56, 139, 1);font-weight: bold;}
  .main .menu .act{color:rgba(1, 56, 139, 1);font-weight: bold;}
  .main .list{width:100%;}
  .main .list .item .title{border-bottom: 1px solid #01388B;padding:20px 0;cursor: pointer;font-family: 'Pretendard';letter-spacing: -0.6px;background-image: url(../assets/img/bottom.png);background-repeat: no-repeat;background-position: right 20px center;}
  .main .list .item .act{border-bottom-width: 0;background-image: url(../assets/img/bottom_top.png);}
  .main .list .item .remark{background: #F0F0F0;border-radius: 10px;padding:20px 10px;margin-right:50px;font-family: 'Pretendard';letter-spacing: -0.6px;line-height: 1.5;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{width:100%;padding:10px 20px 30px 20px;}
    .listNew{text-align: center;}
    .listNew .item{margin:10px 0;}
    .listNew .item img{width:92px;}
    .listNew .item .cont{height:80px;margin:9px 0 9px 46px;text-align: left;}
    .listNew .item .cont p{padding-left:50px;font-size:12px;}
    .listNew .item .cont div{width:100%;padding:10px 0 10px 50px;font-size:12px;height:82px;margin-top:5px;}
    .listNew .jt{width:92px;}
    .listNew .jt img{width:16px;}

    .main{display: flex;flex-direction: column;margin-top:0;}
    .main .menu{flex-direction: row;width:100%;overflow-x: auto;overflow: auto;margin-bottom:20px;}
    .main .menu a{flex-shrink: 0;font-size:16px;padding:10px 10px 10px 0;margin-right:10px;color:#888888;}
    .main .menu span{flex-shrink: 0;font-size:16px;padding:10px 10px 10px 0;margin-right:10px;}
    .main .menu span:nth-last-child(1){margin-right:0;}
    .main .menu .act{color: #01388B;border-bottom:1px solid #01388B;}
    .main .list .item .title{font-size:14px;padding:16px 0;}
    .main .list .item .remark{width:100%;color: #888888;font-size: 12px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 606px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 606px);}
  }
</style>
