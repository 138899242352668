<template>
  <div v-loading="formLoading">
    <div class="top-img">
      <div class="zz">
        <div class="content">
          온누리 국제영업비밀보호센터에서<br/>
          영업비밀 보호에 어려움을 겪고 계신분들을 위한<br/>
          컨설팅 서비스를 제공합니다.
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title pc">컨설팅 신청 하기</div>
      <div class="remark pc">We'd love to hear from you</div>
      <div class="info">
        <div class="item">
          <span>성함</span>
          <input v-model="param.name" maxlength="20"/>
        </div>
        <div class="item">
          <span>회사</span>
          <input v-model="param.companyName" maxlength="50"/>
        </div>
        <div class="item">
          <span>이메일</span>
          <input v-model="param.email" maxlength="50"/>
        </div>
        <div class="item">
          <span>컨설팅 신청 제목</span>
          <input v-model="param.title" maxlength="50"/>
        </div>
        <div class="item">
          <span>컨설팅 신청 내용</span>
          <textarea v-model="param.message" maxlength="500"></textarea>
        </div>
        <div class="check">
          <input type="checkbox" @change="changeCheck"/>
          <a href="privacy" target="_blank">개인정보보호약관</a> 에 동의 합니다.
        </div>
        <div :class="'btn '+(isCheck ? 'ok' : '')" @click="saveInfo">제출</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { postAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Consulting',
    components: {
    },
    data() {
      return {
        param:{name:'',companyName:'',email:'',title:'',message:''},
        isCheck:false,
        formLoading:false,
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      changeCheck(e){
        this.isCheck = e.target.checked;
      },
      saveInfo(){
        const that = this;
        var re = new RegExp(this.emailValidate);
        if(that.isCheck){
          //验证
          if(that.param.name == ''){
            that.$message.error('성함을 입력하여 주세요');
          }else if(that.param.companyName == ''){
            that.$message.error('회사명을 입력하여 주세요');
          }else if(that.param.email == ''){
            that.$message.error('이메일을 입력하세요');
          }else if(!re.test(this.param.email)){
            that.$message.error('이메일 양식 오류 : 정확한 이메일을 입력하세요');
          }else if(that.param.title == ''){
            that.$message.error('문의 컨설팅 신청 제목 주세요');
          }else if(that.param.message == ''){
            that.$message.error('문의 내용을 입력하여 주세요');
          }else{
            that.formLoading = true;
            postAction('/user/userInformation/request_consulting_service',that.param).then(res => {
              if (res.success) {
                that.param = {name:'',companyName:'',email:'',title:'',message:''}
                that.$router.push({path:'/contactUsResult'});
              } else {
                that.$message.error(res.message);
              }
            }).catch(err => {
              that.$message.warning(err.message);
            }).finally(() => {
              that.formLoading = false;
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-img{width:100%;background-image: url(../assets/imgs/consult_bg.jpg);background-position: center;background-size: cover;}

  .top-img .zz div{display: flex;align-items: center;flex-direction: row;padding:0 !important;color:#01388B;line-height:2;font-weight: bold;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .content .title{font-size: 26px;letter-spacing: -1px;color: #01388B;margin-bottom:20px;font-weight: bold;}
  .content .remark{font-family: 'SF UI Display';color: #4A4A4A;font-size:18px;font-weight: bold;}
  .content .info{width:600px;padding:40px 80px;box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.15);border-radius: 10px;margin-top:30px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;}
  .content .info .item span{font-size:14px;margin-bottom:6px;}
  .content .info .item input{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:38px;padding:0 10px;}
  .content .info .item textarea{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:100px;padding:10px;}
  .content .info .check{display: flex;align-items: center;font-size:14px;}
  .content .info .check a{color: #007AFF;}
  .content .info .check input{width:20px;height:20px;margin-right:10px;}
  .content .info .btn{width: 140px;height: 40px;margin:auto;line-height:1;margin-top:40px;color:#ffffff;background: #797979;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  .content .info .ok{background: #01388B;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .top-img .zz{background: rgba(255,255,255,0.2);}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;box-shadow: 0px 7px 25px rgba(0, 0, 0, 0);}
    .top-img{background-image: url(../assets/imgs/consult_bg_m.jpg);}
    .top-img .zz div{height:46vw;font-size:14px;width:80vw;margin:auto;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .top-img .zz div{height:300px;font-size:20px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .top-img .zz div{height:460px;font-size:26px;}
  }
</style>
