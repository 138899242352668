import { getAction } from '@/network/manage'

const contracts = {
  state: {
    whiteListsAddress: '',
    purchaseAddress: '',
    whiteListsAbi: '',
    purchaseAbi: ''
  },

  mutations: {
    SET_CONTRACTS: (state, contractsInfo) => {
      state.whiteListsAddress = contractsInfo.whiteListsContract
      state.purchaseAddress = contractsInfo.purchaseContract
      state.whiteListsAbi = contractsInfo.whiteListsContractAbi
      state.purchaseAbi = contractsInfo.purchaseContractAbi
    }
  },

  actions: {
    GetContracts({ commit }) {
      return new Promise((resolve, reject) => {
        getAction('/smart_contract/')
        .then(res => {
          if (res.code === 200) {
            if (res.contents.length > 0) {
              commit('SET_CONTRACTS', res.contents[0])
              resolve({message: 'The smart contract is not configured'})
            } else {
              reject(res)
            }
          } else {
            reject(res)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    }
  }
}

export default contracts
