<template>
  <div class="content">
    <div class="main">
      <div class="title">
        <span>{{total_progress}}%</span>
      </div>

      <div style="width:120px;height:30px;border:1px solid #000000;text-align: center;cursor: pointer;" @click="showImage">show Image</div>
      <audio ref="audio" :src="mp3_src" controls="controls" controlsList="nodownload"></audio>
      <video width="320" height="240" controls  controlsList="nodownload">
          <source src="img/1.MP4" type="video/mp4">
      </video>
    </div>
  </div>
</template>
<script>
  import { api as viewerApi } from 'v-viewer'

  import { putActionApi,getActionApi,postActionApi,postDownloadActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',
    components: {

    },
    data() {
      return {
        total_progress: 0,
        url: {

        },
        photo: [],
        mp3_src: 'https://img.xacom.cn/yyy.mp3'
      }
    },
    computed: {

    },
    created(){
      // this.saveInfo()
    },
    methods:{
      showImage () {
        let fileUrl = "https://img.xacom.cn/001.png";
        const x = new window.XMLHttpRequest()
        x.open('GET', fileUrl, true)
        x.responseType = 'blob'
        x.onload = () => {
          this.photo = []
          const url = window.URL.createObjectURL(x.response)
          this.photo.push({"data-source": url})
          const $viewer = viewerApi({
            options: {
              title:false,
              toolbar: true,
              scalable: false,
              fullscreen: false,
              url: 'data-source',
              initialViewIndex: 0,
            },
            images: this.photo,
          })
        }
        x.send()
      },
      async saveInfo(){
        let fileBlob = []
        const tasks = [];
        const that = this;
        //总片数
        let chunks = 0;
        //当前下载数量
        let downloaded = 0;
        let obj = await postActionApi('/hash-generator/download/initial-create',{"contents_file_upload_uuid": "33cad93f-96d6-442e-8594-17a92936a69b"})
        //获得总片数
        chunks = obj.data.contents.piece_information.length
        if(obj.code == 200) {
          let file_array = obj.data.contents.piece_information
          for (let index = 0; index < file_array.length; index++) {
            tasks.push(
              new Promise((resolve, reject) => {
                let downData = {download_order: file_array[index].download_order}
                const params = {"download_piece_uuid": file_array[index].contents_file_download_piece_information}
                this.downLoadbyPiece(params).then(res => {
                  downData.data = res
                  fileBlob.push(downData)
                  //已下载数量+1
                  downloaded++;
                  //计算总进度
                  this.total_progress = Math.floor((downloaded / chunks) * 100);
                  resolve()
                }).catch(err => {
                  reject(err)
                })
              })
            )
          }
          await Promise.all(tasks);
          let parts = fileBlob.sort((a, b) => a.download_order - b.download_order)
          // console.log(parts);
          let fileBlobDatas = []
          for (let index = 0; index < parts.length; index++) {
            fileBlobDatas.push(parts[index].data)
          }
          // console.log(fileBlobDatas);
          const blob = new Blob(fileBlobDatas, {
             type:'application/pdf',
          })
          //下载
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = "download Solution.png"
          link.click()
          window.URL.revokeObjectURL(link.href)
        }
      },
      downLoadbyPiece(params) {
        return postDownloadActionApi('/hash-generator/download/piece-download',params);
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .main{display: flex;flex-direction: column;align-items: center;}
  .main .title{width:400px;border-bottom:1px solid #000000;text-align: center;padding:10px 0;margin-bottom:20px;}
  .main .title span{font-size:24px;}
  .main .flex{display: flex;margin:30px 0 50px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex2{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex3{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex4{display: flex;align-items: flex-start;justify-content: center;width:80%;flex-direction: column;height:200px;}
  .main .flex img{flex-shrink: 0;}
  .main .flex p{width:100%;line-height:1.6;font-size:18px;}
  .main .remark{width:60%;margin:30px 0 50px 0;font-size:18px;}
  .main .btn{display: flex;justify-content: flex-end;width:100%;}
  .main .btn a{position: absolute;margin-top:-70px;border:1px solid #01388B;padding:6px 40px 6px 10px;background-image: url(../assets/img/point_right_act.png);background-position: center right 10px;background-repeat: no-repeat;}
  .main .btn a:hover{background-color: #01388B;color:#ffffff;background-image: url(../assets/img/point_right.png);}
  .main .table{background: rgba(136, 136, 136, 0.1);display: flex;flex-direction: column;width:100%;margin:0 10px;padding-bottom:30px;align-items: center;}
  .main .table p{background: rgba(1, 56, 139, 0.7);margin-bottom:20px;border-radius: 10px 10px 0 0;width:100%;display: flex;align-items: center;justify-content: center;color:#ffffff;padding:10px 0;letter-spacing: 20px;}
  .main .table span{display: flex;align-items: center;justify-content: center;margin:10px 0;width:100%;}
  .main .table span a{color: #0020C9;border-bottom:1px solid #0020C9;}
  .main .flex6{display: flex;width:100%;margin:30px 0;}
  .main .flex2{display: flex;flex-direction: column;width:100%;border-radius: 10px; overflow: hidden;}
  .main .flex2 span{display: flex;width:100%;}
  .main .flex2 span:nth-child(1) label{background-color: #4D74AE !important;color:#ffffff;}
  .main .flex2 span:nth-child(2n+1) label{background-color:#F3F3F3;}
  .main .flex2 span label:nth-child(1){width:200px;flex-shrink: 0;margin-right:5px;}
  .main .flex2 span label:nth-child(2){width:100%;}
  .main .flex2 span label{padding:10px 0;padding-left:40px;}
  .main h3{font-size:20px;margin-bottom:10px;}
  .main .flex3 span{width:120px;height:60px;flex-shrink: 0;font-size:30px;font-weight: bold;display: flex;align-items: center;justify-content: center;}
  .main .flex3 p{width:100%;font-size:18px;}
  .main .flex4 span{justify-content: flex-start;width:100%;}
  .remark1{width:90%;background:linear-gradient(rgba(26, 63, 192, 0.31),rgba(60, 94, 214, 0.31),rgba(255, 255, 255, 0.12));border-radius: 10px;padding:20px 30px;font-size: 20px;line-height:2;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{margin:0 20px;padding:10px 0 !important;}
    .img{width:100%;}
    .main .title{width:180px;margin-bottom:10px;}
    .main .title span{font-size:18px;}
    .main .flex p{line-height:1.6;font-size:14px !important;}
    .main .remark{width:100%;margin:20px 0;font-size:14px !important;}
    .main .flex2 span label:nth-child(1){width:100px;margin-right:2px;}
    .main .flex2 span label{padding-left:10px;font-size:12px;}
    .main .flex3{margin:20px 0;width:100%;}
    .main .flex4{margin:0 0;flex-direction: column;width:80%;height:auto;}
    .main .flex6{flex-direction: column;}
    .main .table{width:100%;margin:0 !important;margin-bottom:20px !important;}
    .main .table span{font-size:14px;}
    .main h3{font-size:12px;margin-bottom:10px;letter-spacing:-1px;}
    .main .flex3 span{width:20px;height:20px;font-size:18px;margin-right: 20px;}
    .main .flex3 p{width:100%;font-size:13px;}
    .bg{position: absolute;z-index: -1;width:100vw;left:0;opacity: 0.2;display: flex;align-items: center;justify-content: center;}
    .remark1{width:100%;font-size:16px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .remark1{width:100%;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
