<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content" v-if="userId == ''">
      <div class="title">아이디 찾기</div>
      <div class="info">
        <div class="item">
          <span class="pc">가입시 등록하신 휴대폰번호 / 이메일을 입력하세요</span>
          <span class="mobile">휴대폰 번호 / 이메일</span>
          <div class="box-item" :class="emailDisabled ? 'disabled' : ''">
            <input v-model="emailMobile" :disabled="emailDisabled" placeholder="휴대폰 번호 / 이메일을 입력하세요"/>
            <a v-if="!emailDisabled && time == 120" style="color:#0020C9 !important;" @click="sendCode" v-loading="loading">인증번호 받기</a>
          </div>
        </div>
        <div class="item">
          <span class="pc">인증 코드</span>
          <div class="box-item" :class="emailDisabled ? 'disabled' : ''">
            <input v-model="code" maxlength="6" :disabled="emailDisabled" @input="validateEmailCode" placeholder="6자리 코드를 입력하세요"/>
            <a style="color:#0020C9 !important;" v-if="!emailDisabled && time != 120">{{time}}s resend</a>
            <a v-if="emailDisabled" style="color:#00C999 !important;cursor: default !important;">인증완료</a>
          </div>
        </div>
        <div v-if="msg != ''" style="color:#C90000;font-size: 14px;text-align: left;">{{msg}}</div>
        <div class="btn" @click="findId">다음</div>
      </div>
    </div>
    <div class="content" v-if="userId != ''">
      <div class="info">
        <div class="title">등록되신 아이디는 {{userId}} 입니다.</div>
        <div class="btn" style="border-radius: 2px;background: #00C999;" @click="goPage('/login')">로그인</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getActionApi,postActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'FindId',
    components: {
    },
    data() {
      return {
        emailMobile:'',
        userId:'',
        code:'',
        time:120,
        emailDisabled:false,
        msg:'',
        timer:null,
        loading:false,
        formLoading:false,
        codeId:'',
        findParam:{},
        validateParam:{},
        validateUrl:'',
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        url: {
          emailCode:'verification/request-email-verification-code-id-found',
          mobileCode:'verification/request-sms-verification-code-id-found',
          emailValidate:'verification/email-verification',
          mobileValidate:'verification/sms-verification',
          findId:'login-management/find-id-email'
        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      validateEmailCode(){
        const that = this;
        that.msg = '';

        if(that.code.length == 6 && that.emailMobile.length != 0){
          if(that.validateUrl == ''){
            that.msg = '인증번호 보내주세요.';
            return;
          }
          const param = {
            email:that.emailMobile,
            phone_number:that.emailMobile,
            codeId:that.codeId,
            verification_code:parseInt(that.code)
          }
          postActionApi(that.validateUrl, param).then((res) => {
            if (res.code == 200) {
              that.emailDisabled = true;
            } else {
              that.msg = res.message;
            }
          }).catch(res => {
            that.msg = res.message;
          })
        }
      },
      findId(){
        const that = this;
        that.msg = '';
        //验证
        this.emailMobile = this.emailMobile.trim();
        this.code = this.code.trim();
        var re = new RegExp(this.emailValidate);
        if(that.validateUrl == ''){
          that.msg = '인증번호 보내주세요.';
          return;
        }
        if(this.emailMobile == ''){
          that.msg = '이메일 또는 모바일이 요구됩니다.'
        }else if(!(this.emailMobile.length == 11 || re.test(this.emailMobile))){
          that.msg = '입력 포맷 오류 발생 : 정확한 값을 입력하세요'
        }else if(this.code.length != 6){
          that.msg = '6자리 인증코드를 입력하세요'
        }else{
          this.loading = true;
          //验证
          that.validateParam.verification_code = parseInt(that.code);
          getActionApi(this.url.findId,that.findParam).then((res) => {
            if (res.code == 200) {
              that.userId = res.data.user_id;
            } else {
              that.msg = res.message;
              this.loading = false;
            }
          }).catch(res => {
            that.msg = res.message;
            this.loading = false;
          })
        }
      },
      sendCode(){
        const that = this;
        this.emailMobile = this.emailMobile.trim();
        var re = new RegExp(this.emailValidate);
        that.msg = '';
        if(this.emailMobile == ''){
          that.msg = '이메일 또는 모바일이 요구됩니다.';
        }else if(re.test(this.emailMobile)){
          //email
          this.loading = true;
          this.url.findId = 'login-management/find-id-email'
          postActionApi(this.url.emailCode, {email:this.emailMobile}).then((res) => {
            if (res.code == 200) {
              that.findParam = {
                email:that.emailMobile,
                email_verification:res.data.codeId,
                codeId:res.data.codeId
              }
              that.validateUrl = that.url.emailValidate
              that.validateParam = that.findParam
              that.codeId = res.data.codeId
              //daojishi
              that.timer = setInterval(() =>{
                  that.time = that.time - 1;
                  this.loading = false;
                  if(that.time < 0){
                    clearInterval(that.timer);
                    that.time = 120;
                  }
              },1000)
            } else {
              that.msg = res.message;
              this.loading = false;
            }
          }).catch(res => {
            that.msg = res.message;
            this.loading = false;
          })
        }else if(this.emailMobile.length == 11){
          //mobile
          this.loading = true;
          this.url.findId = 'login-management/find-id-mobile'
          postActionApi(this.url.mobileCode, {phone_number:this.emailMobile}).then((res) => {
            if (res.code == 200) {
              that.findParam = {
                phone_number:that.emailMobile,
                mobile:that.emailMobile,
                mobile_verification:res.data.codeId,
                codeId:res.data.codeId
              }
              that.validateUrl = that.url.mobileValidate
              that.validateParam = that.findParam
              that.codeId = res.data.codeId
              //daojishi
              that.timer = setInterval(() =>{
                  that.time = that.time - 1;
                  this.loading = false;
                  if(that.time < 0){
                    clearInterval(that.timer);
                    that.time = 120;
                  }
              },1000)
            } else {
              that.msg = res.message;
              this.loading = false;
            }
          }).catch(res => {
            that.msg = res.message;
            this.loading = false;
          })
        }else{
          that.msg = '입력 포맷 오류 발생 : 정확한 값을 입력하세요';
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:30px 0;font-family: 'Pretendard';font-weight: bold;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'SF UI Display';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;padding:0 10px 0 0;display: flex;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:46px !important;}
  .box-item a{flex-shrink: 0;color:#0020C9;font-family: 'Pretendard';font-size:15px;cursor: pointer;font-weight: bold;}
  .link{font-family: 'Pretendard';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:10px;font-family: 'Pretendard';}
  .disabled{background-color:rgba(217, 217, 217, 0.20) !important;}
  .content .info .item input:disabled{background-color:transparent !important;}
  .content .info .item input{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;padding:0 10px;font-size:13px;}
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;cursor: pointer;height: 48px;line-height:1;margin:60px 0 10px 0;color:#ffffff;background: #01388B;border-radius: 10px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
