<template>
  <div class="content">

  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Applycert',
    components: {
    },
    data() {
      return {
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{height:100vh;margin:0 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
