<template>
  <div class="bottom">
    <div class="content pc">
      <div class="info">
        <div>회사명 : 온누리국제영업비밀보호센터</div>
        <div>사업자 등록번호 : 244-86-02669</div>
        <div>대표자 : 최윤경</div>
        <div>연락처 : 031-911-0740</div>
        <div style="display: flex;color:#ffffff;align-items: center;margin-top:6px;font-size:13px;">
          <a href="terms" style="color:#0020C9;text-decoration: underline;cursor: pointer;font-family:'Pretendard';">이용약관</a>
          <span style="margin:0 10px;">|</span>
          <a href="privacy" style="color:#0020C9;text-decoration: underline;cursor: pointer;font-family:'Pretendard';">개인정보보호약관</a>
        </div>
      </div>
      <div style="flex-shrink: 0;">
        <img src="../assets/imgs/igc.png" class="igc"/>
      </div>
      <div class="link">
        <div class="top">
          <div style="display: flex;flex-direction: column;">
            <h2 style="color:#ffffff;font-size:13px;margin-bottom:6px;">기관소개</h2>
            <a class="ahover" href="about1">인사말</a>
            <a class="ahover" href="about2">국제영업비밀보호</a>
            <a class="ahover" href="about3">관련 법령</a>
            <a class="ahover" href="about4">주요 침해행위</a>
          </div>
          <div style="display: flex;flex-direction: column;margin:0 60px;">
            <h2 style="color:#ffffff;font-size:13px;margin-bottom:6px;">서비스 이용</h2>
            <a class="ahover" href="service">서비스 소개</a>
            <a class="ahover" href="faq?showType=1">이용절차</a>
            <a class="ahover" href="upload">비밀 보관 의뢰</a>
            <a class="ahover" href="myServiceCert">증명서 발급</a>
          </div>
          <div style="display: flex;flex-direction: column;">
            <h2 style="color:#ffffff;font-size:13px;margin-bottom:6px;">기타</h2>
            <a class="ahover" href="notice">기관 소식</a>
            <a class="ahover" href="contactUs">문의하기</a>
            <a class="ahover" href="faq">자주하는 질문</a>
            <a class="ahover" href="consulting">컨설팅</a>
          </div>
        </div>
      </div>
      <div class="img">
        <div class="img">
           <!-- <a target="_blank" href="https://www.kipo.go.kr/ko/MainApp.do" style="margin-right:10px;"><img src="../assets/img/logo2_pc.png"/></a> -->
           <a href="/"><img src="../assets/img/logo3.png"/></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAction } from '@/network/manage'
  export default {
    name: 'Bottom',
    data() {
      return {
        year:1000
      }
    },
    created(){
      this.year = new Date().getFullYear();
    },
    methods:{

    }
  }
</script>

<style scoped>
  .bottom{background-color:#686868;}
  .content{margin:auto;display: flex;justify-content: space-between;align-items: center;height:126px;align-items: center;}
  .content .info{flex-shrink: 0;}
  .ahover{color:#CFCFCF !important;font-size:12px;cursor: pointer;line-height: 1.4;}
  .ahover:hover{color: #0020C9 !important;text-decoration: underline;}
  .content .info div{font-family: 'SF UI Display';font-size: 12px;color: #ffffff;line-height:1.5;}
  .content .link{width: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .content .link .top{display: flex;}
  .content .link .top a{font-family: 'Noto Sans CJK JP';color: #ffffff;font-size: 13px;}
  .content .link .top span{color:#ffffff;margin:0 30px;font-size:12px;}
  .content .link .bottom{display: flex;margin-top:5px;}
  .content .link .bottom a{color: #0020C9;font-family: 'SF UI Display';font-size: 13px;margin-right:20px;}
  .content .img{display: flex;}
  .content img{flex-shrink: 0;height:54px;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .content .link .top span{margin:0 18px;}
    .content .link .bottom a{margin-right:10px;}
    .igc{height:60px !important;margin-left:10px;}
  }
  @media(min-width: 1200px){
    .content{width:1200px;}
  	.mobile{display: none !important;}
    .igc{height:90px !important;margin-left:20px;}
  }
</style>
