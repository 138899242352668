<template>
  <div class="content">
    <div class="bg mobile"></div>
    <div class="title">개인정보보호약관</div>
    <div style="width: 360px;height: 1px;background-color: #000000;margin-bottom: 20px;"></div>
    <div class="desc">
      <h1>[개인정보의 수집 및 이용 목적]</h1>
      <br/>
      <p>온누리 국제영업비밀보호센터는 개인정보를 다음의 목적을 위해 수집합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.</p>
      <br/>
      <h2>1. 홈페이지 회원가입 및 관리</h2>
      <p>회원가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의/인증 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존, 신규 서비스 개발 및 맞춤 서비스 제공, 서비스의 유효성 확인, 회원관리에 필요하다고 판단되는 경우 등</p>
      <br/>
      <h2>2. 민원사무 처리</h2>
      <p>민원인의 본인 확인, 질의응답 사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등</p>
      <br/>
      <h2>3. 영업비밀 보호 서비스 제공</h2>
      <p>원본증명/신청 접수 창구 제공, 각종 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제/정산 등</p>
      <br/>
      <br/>
      <h1>[수집하는 개인정보 항목]</h1>
      <p>온누리 국제영업비밀보호센터는 서비스 제공을 위해 필요한 최소한의 범위 내에서 다음과 같은 개인정보를 수집하고 있습니다.</p>
      <br/>
      <h2>1. 홈페이지 회원가입 및 관리</h2>
      <p>사용자ID, 성명, 이메일, 핸드폰번호, 공동인증서 식별정보</p>
      <br/>
      <h2>2. 영업비밀보호센터의 부가적인 서비스 신청시에 필요한 추가적인 개인정보는 별도의 동의를 통해 수집</h2>
      <br/>
      <h2>3. 인터넷 서비스 이용과정에서 개인정보 항목이 자동 생성되어 수집될 수 있습니다.</h2>
      <p>접속 정보(브라우저 종류, IP주소, 접속시간), 쿠키, 방문기록, 서비스 이용기록, 결제 기록 등</p>
      <br/>
      <br/>
      <h1>[개인정보의 처리 및 보유 기간]</h1>
      <br/>
      <h2>1. 개인정보파일의 명칭 : 온누리국제영업비밀보호센터 회원정보</h2>
      <br/>
      <h2>2. 운영근거 : 개인정보보호법</h2>
      <br/>
      <h2>3. 보유기간 : 개인정보가 불필요해지는 시점에 즉시 삭제</h2>
      <br/>
      <p>단, 관련 법령에 의해 허여되는 경우 일정기간 동안 회원정보를 추가 보관할 수 있습니다.</p>
      <br/>
      <p>귀하는 개인정보 수집이용 동의를 거부할 권리가 있으며, 필수항목에 대한 동의 거부시 홈페이지 회원가입에 제한이 있을 수 있습니다.</p>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Privacy',
    components: {
    },
    data() {
      return {
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .content .title{font-family: 'Pretendard';font-size: 26px;letter-spacing: -1px;margin:30px 0 10px 0;}
  .content .button{align-self: flex-end;margin:10px 0;color:#01388B;cursor: pointer;border: 1px solid #01388B;height: 36px;width: 150px;border-radius: 3px;display: flex;justify-content: center;align-items: center;}
  .content .button:hover{color:#ffffff;background-color: #ffffff;border: 1px solid #01388B;background: #01388B;}
  .content .button span{margin-left:10px;width:22px;height:8px;background-image: url(../assets/img/point_right_act.png);}
  .content .button:hover span{background-image: url(../assets/img/point_right.png);}
  .content .desc{display: flex;flex-direction: column;}
  .content .desc h1{font-size:24px;font-weight:bold;}
  .content .desc h2{font-size:20px;font-weight:bold;color: #0020C9;}
  .content .desc p{font-size: 18px;line-height:1.5;}
  .content .desc h3{font-size:18px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{height:100vh;margin:0 20px;}
    .bg{position: fixed;z-index: -1;width:100vw;height:40vw;left:0;top:0;background:linear-gradient(180deg, rgba(217, 217, 217, 0.58) 0%, rgba(217, 217, 217, 0) 100%);}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
