<template>
  <div class="body">
    <div class="content">
      <div class="info">
        <div class="title">
          문의가 정상 접수 되었습니다.<br/>
          접수하신 메일로 48시간 내 회신 드리겠습니다.<br/>
          감사합니다.
        </div>
        <div class="btn" @click="goPage('/')">확인</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction,postAction,postTossAction } from '@/network/manage'
  import { putActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'contactUsResult',
    components: {
    },
    data() {
      return {

      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:60px 0 30px 0;font-family: 'Pretendard';font-weight: bold;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .btn{width: 200px;cursor: pointer;height: 40px;line-height:1;margin:60px auto 10px auto;color:#ffffff;background: #01388B;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
