<template>
  <div class="content" v-loading="formLoading">
    <div style="display: none;">
      <div id="payment-method1"></div>
      <div id="payment-method2"></div>
    </div>
    <div class="pc window-confirm" v-if="certIsShow">
      <div class="window">
        <div class="close"><img @click="certIsShow = false" src="../assets/img/x_2.png"/></div>
        <img :src="imgQZ + '/sys/common/view/' + certImg"/>
      </div>
    </div>
    <div class="pc window-confirm" v-if="yqConfirm">
      <div class="window">
        <div class="close"><img @click="yqConfirm = false" src="../assets/img/x_2.png"/></div>
        <p class="til">전자지문 보관 연장기간을 선택하세요</p>
        <p class="remark">파일 보관 기간</p>
        <div class="radio-div">
          <label v-for="(item,i) in yearList" :key="i" @click="changeYear(item)">
            <input type="radio" name="year" :checked="currentYear == item.serviceLength"/>{{item.serviceLength}}년
          </label>
        </div>
        <p class="price">결제 예정 금액 :　<a>{{toThousands(currentPrice)}}원</a> (부가세포함)</p>
        <div class="confirm">
          <input type="checkbox" @change="submitCheck"/>
          <a>이용약관</a> 확인
        </div>
        <div :class="'btn ' + (isChecked ? 'ok' : '')" @click="submitToss">결제하기</div>
        <p class="note">NOTE : 계좌 이체 세금계산서 발행이 필요하신 경우 문의를 남겨 주세요</p>
        <p class="a" @click="goRequestBank">바로이동</p>
      </div>
    </div>
    <div class="pc window-confirm" v-if="deleteLog != null">
      <div class="window">
        <div class="close"><img @click="deleteLog = null" src="../assets/img/x_2.png"/></div>
        <div class="yz"><img @click="deleteLog = null" src="../assets/img/icon.png"/></div>
        <div class="til">폐기 확인서</div>
        <div class="list">
          <div>발급번호 :  {{deleteLog.contentUuid}}</div>
          <div>최초 저장 일시 :  {{deleteLog.createdDatetime}}</div>
          <div>파일명 :  {{deleteLog.fileName}}</div>
          <div>폐기일자 :  {{deleteLog.deleteDatetime}}</div>
        </div>
        <div class="pro-remark">
          상기 항목과 관련된 입증정보는 폐기되었음을 확인합니다.
        </div>
        <div class="btn ok" @click="downLoadLog">다운로드</div>
      </div>
    </div>
    <div class="title">My page</div>
    <div class="main">
      <div class="menu pc">
        <div class="menu pc">
          <a @click="goPage('/myInformation')">회원 정보 관리</a>
          <a>공동인증서 관리</a>
          <a>비밀 보관 현황</a>
          <a>증명서 신청내역</a>
          <a>결제/환불</a>
        </div>
      </div>
      <div class="list-div">
        <div class="til">
          <span>이용 내역</span>
          <p>* 증명서 신청은 "유료"서비스 입니다. (50,000원 / 1개 증명서)</p>
        </div>
        <div class="list" v-loading="hashServiceUsageLoading">
          <div class="header">
            <span class="pc">등록일</span>
            <span class="pc">파일명</span>
            <span class="pc">서비스 종료 기한</span>
            <span class="pc">상태</span>
            <span class="pc">기능</span>
            <span class="mobile" style="width:30%;">등록일</span>
            <span class="mobile" style="width:30%;">파일명</span>
            <span class="mobile" style="width:20%;">이용 기한</span>
            <span class="mobile" style="width:20%;">상태</span>
          </div>
          <div class="item" v-for="(item,index) in hashServiceUsageList" :key="index">
            <span class="pc">{{item.createdDatetime}}</span>
            <span class="pc">{{item.fileName}}</span>
            <span class="pc">{{item.serviceExpiredDate}}</span>
            <span class="pc">
              <span v-if="item.hashedServiceStatus == 1" style="color:#0020C9;">이용 중</span>
              <span v-if="item.hashedServiceStatus == 0" style="color:#F46969;">만료</span>
              <span v-if="item.fromTable == 'view_hashed_contents_failed'" style="color:#F46969;">실패</span>
              <span v-if="item.fromTable == 'view_hash_delete_log'" style="color:#F46969;">폐기</span>
            </span>
            <span class="pc options">
              <!-- 删除 -->
              <a @click="deleteFile(item)" v-if="item.fromTable == 'view_hashed_contents' && (item.hashedServiceStatus == 1 || item.hashedServiceStatus == 0)">폐기하기</a>
              <!-- 重新申请 -->
              <a v-if="item.fromTable == 'view_hashed_contents_failed'" @click="reUpload(item)">다시신청</a>
              <!-- 删除 -->
              <a v-if="item.fromTable == 'view_hashed_contents_failed'" style="color:#F46969;" @click="deleteContent(item)">삭제</a>
              <!-- 延期 -->
              <a style="color:#0020C9;" v-if="item.serviceExpiredDate != null" @click="yqFunction(item)">연장하기</a>
              <!-- 申请证书 -->
              <a v-if="item.serviceExpiredDate != null && item.diffDay > 0 && item.releaseStatus == null" @click="viewCert(item.contentUuid)">증명서 신청</a>
              <!-- 查看删除日志 -->
              <a v-if="item.fromTable == 'view_hash_delete_log'" @click="viewHashDeleteLog(item.contentUuid)">폐기확인</a>
            </span>
            <span class="mobile" style="width:30%;">{{item.createdDatetime}}</span>
            <span class="mobile" style="width:30%;">{{item.fileName}}</span>
            <span class="mobile" style="width:20%;">{{item.serviceExpiredDate}}</span>
            <span class="mobile" style="width:20%;">
              <span v-if="item.hashedServiceStatus == 1" style="color:#0020C9;">이용 중</span>
              <span v-if="item.hashedServiceStatus == 0" style="color:#F46969;">만료</span>
              <span v-if="item.fromTable == 'view_hashed_contents_failed'" style="color:#F46969;">실패</span>
              <span v-if="item.fromTable == 'view_hash_delete_log'" style="color:#F46969;">폐기</span>
            </span>
          </div>
          <div class="load-more"><a v-if="hashServiceUsageIsMore" @click="loadMoreHashServiceUsageList()">+ 더보기</a></div>
        </div>


        <div class="til">
          <span>원본증명서 신청 내역</span>
        </div>
        <div class="list" v-loading="hashCertificateRequestsLoading">
          <div class="header">
            <span class="pc">신청날짜</span>
            <span class="pc">파일명</span>
            <span class="pc">상태</span>
            <span class="pc">다운로드 가능 기한</span>
            <span class="pc">추가기능</span>
            <span class="mobile" style="width:30%;">신청일</span>
            <span class="mobile" style="width:30%;">파일명</span>
            <span class="mobile" style="width:20%;">다운로드 가능 기한</span>
            <span class="mobile" style="width:20%;">기능</span>
          </div>
          <div class="item" v-for="(item,index) in hashCertificateRequestsList" :key="index">
            <span class="pc">{{item.requestedDatetime}}</span>
            <span class="pc">{{item.fileName}}</span>
            <span class="pc">
              <span style="color:#0020C9;" v-if="item.releaseStatus == 1">발급완료</span>
              <span style="color:#F46969;" v-if="item.releaseStatus > 0 && item.diffDay < 0">만료</span>
              <span style="color:#F46969;" v-if="item.releaseStatus == 0 || item.releaseStatus == null">실패</span>
            </span>
            <span class="pc">{{item.releaseStatus > 0 ? item.downloadTime : ''}}</span>
            <span class="pc">
              <!-- 下载 -->
              <a v-if="item.releaseStatus > 0 && item.diffDay > 0" @click="down_load_file_pdf(item)">다운로드</a>
              <!-- 重新申请 -->
              <a @click="reCertFunction(item.contentsOriginalityRequestUuid)" v-if="item.releaseStatus == 0 || item.releaseStatus == null">다시신청</a>
            </span>

            <span class="mobile" style="width:30%;">{{item.requestedDatetime}}</span>
            <span class="mobile" style="width:30%;">{{item.fileName}}</span>
            <span class="mobile" style="width:20%;">{{item.releaseStatus > 0 ? item.downloadTime : ''}}</span>
            <span class="mobile" style="width:20%;">
              <span style="color:#0020C9;" v-if="item.releaseStatus == 1">발급완료</span>
              <span style="color:#F46969;" v-if="item.releaseStatus > 0 && item.diffDay < 0">만료</span>
              <span style="color:#F46969;" v-if="item.releaseStatus == 0 || item.releaseStatus == null">실패</span>
            </span>
          </div>
          <div class="load-more" v-if="hashCertificateRequestsIsMore"><a @click="loadMoreCertificateRequestsList">+ 더보기</a></div>
        </div>



        <div class="til">
          <span>결제 내역</span>
          <p>* 구매 후 1년 내  환불 가능 (미사용 서비스 限)</p>
        </div>
        <div class="list" v-loading="hashPurchaseStatusLoading">
          <div class="header">
            <span class="pc">결제일시</span>
            <span class="pc">결제 상품</span>
            <span class="pc">사용현황</span>
            <span class="pc">기능</span>
            <span class="mobile" style="width: 40%;">결제일시</span>
            <span class="mobile" style="width: 60%;">결제 서비스</span>
          </div>
          <div class="item" v-for="(item,index) in hashPurchaseStatusList" :key="index">
            <span class="pc">{{item.purchaseDatetime}}</span>
            <span class="pc">
              <span v-if="item.purchaseServiceType == 1">원본증명서 신청</span>
              <span v-if="item.purchaseServiceType == 0 || item.purchaseServiceType == 2">전자지문 보관 서비스 {{item.purchaseAmount}}개 {{item.purchaseLength}}년</span>
            </span>
            <span class="pc">
              <span v-if="item.purchaseServiceType == 0 && item.amounts == item.purchaseAmount">미사용</span>
              <span v-if="item.purchaseServiceType == 0 && item.amounts != item.purchaseAmount">{{item.purchaseAmount-item.amounts}}개 사용</span>
              <span v-if="item.purchaseServiceType == 1 && item.certificateTicketUsed == 0">미사용</span>
              <span v-if="item.purchaseServiceType == 1 && item.certificateTicketUsed == 1">사용</span>
              <span v-if="item.purchaseServiceType == 2">사용</span>
            </span>
            <span class="pc">
              <!-- 申请退款 -->
              <a @click="tkApp(item)" v-if="item.timeStamp == 0 && item.refundStatus == null && item.purchaseServiceType == 0 && item.amounts == item.purchaseAmount" style="color:#0020C9;">환불 신청</a>
              <a @click="tkApp(item)" v-if="item.timeStamp == 0 && item.refundStatus == null && item.purchaseServiceType == 1 && item.certificateTicketUsed == 0" style="color:#0020C9;">환불 신청</a>
              <!-- 退款中 -->
              <span v-if="item.refundStatus == 0" style="color:#0020C9;">환불 처리 중</span>
              <!-- 退款完成 -->
              <span v-if="item.refundStatus == 1">환불 완료</span>
            </span>
            <span class="mobile" style="width: 40%;">{{item.purchaseDatetime}}</span>
            <span class="mobile" style="width: 60%;">
              <span v-if="item.purchaseServiceType == 0 && item.amounts == item.purchaseAmount">미사용</span>
              <span v-if="item.purchaseServiceType == 0 && item.amounts != item.purchaseAmount">{{item.purchaseAmount-item.amounts}}개 사용</span>
              <span v-if="item.purchaseServiceType == 1 && item.certificateTicketUsed == 0">미사용</span>
              <span v-if="item.purchaseServiceType == 1 && item.certificateTicketUsed == 1">사용</span>
              <span v-if="item.purchaseServiceType == 2">사용</span>
            </span>
          </div>
          <div class="load-more" v-if="hashPurchaseStatusIsMore"><a @click="loadMorePurchaseStatusList">+ 더보기</a></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { getAction,postAction,putAction,downFile,deleteAction } from '@/network/manage'
  import { deleteActionApi, postActionApi,putActionApi,getActionApi } from '@/network/manageApi'
  import { Collapse } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { CERT_LOGIN } from '@/store/mutation-types'
  import '@/assets/js/jsencryptKey'
  export default {
    name: 'MyService',
    components: {
    },
    data() {
      return {
        certImg:null,
        certIsShow:false,
        formLoading:false,
        deleteLog:null,
        yqConfirm:false,
        isChecked:false,
        certLogin:false,
        currentPrice:0,
        currentYear:3,
        currentContentId:null,
        paymentWidget:null,
        user:null,
        customerInfo:null,
        hashServiceUsageLoading:false,
        hashServiceUsagePage:1,
        hashServiceUsageList:[],
        hashServiceUsageIsMore:false,
        yearList:[],
        hashPurchaseStatusLoading:false,
        hashPurchaseStatusPage:1,
        hashPurchaseStatusList:[],
        hashPurchaseStatusIsMore:false,
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        hashCertificateRequestsLoading:false,
        hashCertificateRequestsPage:1,
        hashCertificateRequestsList:[],
        hashCertificateRequestsIsMore:false,
        url: {
          view_hashed_service_usage:'/user/userInformation/view_hashed_service_usage',
          view_certificate_requests:'/user/userInformation/view_certificate_requests',
          view_purchase_status:'/user/userInformation/purchase_log_list',
          view_hash_delete_log:'/user/userInformation/view_hash_delete_log',
          sign_failed:'/get-timestamp/sign-failed'
        }
      }
    },
    computed: {

    },
    created(){
      this.loadHashServiceUsage();
      this.loadPurchaseStatus();
      this.loadCertificateRequests();
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      const that = this;
      that.formLoading = true;
      getActionApi('/login-management/user').then((res) => {
        if (res.code == 200) {
          that.user = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/userInformation/my_info').then(res => {
        if (res.success) {
          that.customerInfo = res.result.userinfo
          that.paymentWidget = PaymentWidget('live_ck_YZ1aOwX7K8mzvBWmy6A3yQxzvNPG', that.customerInfo.id);
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
      getAction('/user/servicePlans/plansListYQ').then(res => {
        if(res.success){
          that.yearList = res.result.plans
          that.currentPrice = that.yearList[0].promotionPrice/10;
        }else{
          that.$message.warning(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    methods:{
      toThousands(num) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      },
      down_load_file_pdf(item){
        let fileName = "cert"
        let params = {id: item.contentsOriginalityRequestUuid}
        downFile("/user/userInformation/show_cert_view",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
      },
      changeYear(item){
        this.currentPrice = item.promotionPrice/10
        this.currentYear = item.serviceLength
      },
      downLoadLog(){
        let fileName = "폐기 확인서"
        let params = {id: this.deleteLog.contentUuid}
        downFile("/user/userInformation/show_del_log_view",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
      },
      tkApp(item){
        this.$messageBox('해당 결제 금액의 환불 처리를 진행하시겠습니까?', '알림', {
          confirmButtonText: '확인',
          type: 'warning'
        }).then(() => {
          const that = this;
          that.formLoading = true;
          putAction('/user/userInformation/refund_app/'+item.purchaseLogUuid).then(res => {
            if(res.success){
              that.$router.go(0);
            }else{
              that.$message.warning(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        })

      },
      reCertFunction(uuid){
        this.$router.push({name:'myServiceCert',query:{uuid:uuid}});
      },
      viewCert(uuid){
        const that = this;
        //创建单据
        that.formLoading = true;
        that.paymentWidget.renderPaymentMethods('#payment-method1', 50000);
        putAction('/user/userInformation/create_cert_purchase/'+uuid).then(res => {
          if(res.success){
            const orderId = res.result.purchaseLogUuid;
            that.formLoading = false;
            that.paymentWidget.requestPayment({
              orderId: orderId,
              orderName: 'Procurement Services',
              successUrl: 'https://www.onnuriip.com/paySuccess',
              failUrl: 'https://www.onnuriip.com/payFail',
              customerEmail: that.user.email,
              customerName: that.customerInfo.userId
            })
            //this.$router.push({name:'paySuccess',query:{orderId:res.result.purchaseLogUuid}});
          }else{
            that.$message.warning(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.formLoading = false;
        })
        //this.$router.push({name:'myServiceCert',query:{uuid:uuid}})
      },
      submitCheck(e){
        this.isChecked = e.target.checked;
      },
      yqFunction(item){
        this.isChecked = false;
        this.yqConfirm = true;
        this.currentContentId = item.contentUuid
      },
      submitToss(){
        const that = this;
        if(this.isChecked){
          let param = {
            purchaseServiceType:2,
            paymentMethod:0,
            purchaseAmount:1,
            purchasePrice:this.currentPrice,
            purchaseLength:this.currentYear,
            hashedServiceContentsUuid:this.currentContentId
          }
          that.formLoading = true;
          that.paymentWidget.renderPaymentMethods('#payment-method2', that.currentPrice);
          postAction('/user/servicePlans/savePurchaseLog', param).then(res => {
            if (res.success) {
              const orderId = res.result.purchaseLogUuid;
              that.formLoading = false;
              that.paymentWidget.requestPayment({
                orderId: orderId,
                orderName: 'Extension of Term',
                successUrl: 'https://www.onnuriip.com/paySuccess',
                failUrl: 'https://www.onnuriip.com/payFail',
                customerEmail: that.user.email,
                customerName: that.customerInfo.userId
              })
              //this.$router.push({name:'paySuccess',query:{orderId:res.result.purchaseLogUuid}});
            } else {
              that.$message.error(res.message);
              that.formLoading = false;
            }
          }).catch(err => {
            that.$message.warning(err.message);
            that.formLoading = false;
          })
        }
      },
      goRequestBank(){
        this.$router.push({path:'/serviceBankMessage'});
      },
      async deleteContent(item){
        const that = this;
        this.$messageBox('해당 보관 항목을 폐기 처리하시겠습니까?', 'DELETE', {
          confirmButtonText: '확인',
          type: 'warning'
        }).then(() => {
          //删除
          that.formLoading = true;
          deleteActionApi(that.url.sign_failed,{contents_fail_uuid:item.contentUuid}).then(res => {
            if (res.code == 200) {
              that.$router.go(0);
            } else {
              that.$message.error(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
            that.formLoading = false
          })
        })
      },
      async reUpload(item){
        //const that = this;
        window.location.href='/upload?nowid='+item.contentUuid;
        /* that.formLoading = true;
        //查询planId
        let res = await getAction('/user/servicePlans/getOneUuid');
        if(res.success){
          const planId = res.result.planInfo.servicePlanOwnUuid
          //查询hashContent
          res = await getAction('/user/userInformation/view_hashed_contents_failed',{contentUuid:item.contentUuid});
          if(res.success){
            //重新上传
            res = await postActionApi('/get-timestamp/sign',{
              service_plan_own_uuid:planId,
              file_name:res.result.fileName,
              hashed_file:res.result.electricFingerPrint,
              verification_signature:that.$getCode(res.result.electricFingerPrint)
            })
            if(res.code != 200){
              that.$message.error(res.message);
              that.removeFileInfo(res.data.error_uuid,false);
              that.formLoading = false
            }else{
              that.removeFileInfo(item.contentUuid,true);
            }
          }
        }else{
          that.$message.error(res.message);
          that.formLoading = false;
        } */
      },
      removeFileInfo(uuid,isLoad){
        const that = this;
        deleteActionApi(that.url.sign_failed,{contents_fail_uuid:uuid}).then(res => {
          if (res.code == 200) {
            if(isLoad)
              that.$router.go(0);
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
          that.formLoading = false
        })
      },
      viewHashDeleteLog(uuid){
        const that = this;
        that.deleteLog = null;
        getAction(that.url.view_hash_delete_log,{contentUuid:uuid}).then(res => {
          if (res.success) {
            that.deleteLog = res.result;
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        })
      },
      deleteFile(item){
        const that = this;
        if(!this.certLogin){
          this.$messageBox('인증서 로그인이 요구됩니다', 'Warning', {
            confirmButtonText: 'register',
            type: 'warning'
          }).then(() => {
            that.$router.push({name:'login',query:{loginType:1}})
          })
          return;
        }
        this.$messageBox('해당 보관 항목을 폐기 처리하시겠습니까?* 폐기 처리 시 복구 불가', 'DELETE', {
          confirmButtonText: '확인',
          type: 'warning'
        }).then(() => {
          //删除
          that.formLoading = true;
          deleteActionApi('/get-timestamp/delete-hashed-contents',{contents_uuid:item.contentUuid}).then((res) => {
            if (res.code == 200) {
              that.$message.success('delete success!');
              that.$router.go(0);
            } else {
              that.$message.error(res.message);
              that.formLoading = false;
            }
          }).catch(res => {
            that.$message.warning(res.message);
            that.formLoading = false;
          })
        })
      },
      loadMoreHashServiceUsageList(){
        this.hashServiceUsagePage = this.hashServiceUsagePage + 1;
        this.loadHashServiceUsage();
      },
      loadMorePurchaseStatusList(){
        this.hashPurchaseStatusPage = this.hashPurchaseStatusPage + 1;
        this.loadPurchaseStatus();
      },
      loadMoreCertificateRequestsList(){
        this.hashCertificateRequestsPage = this.hashCertificateRequestsPage + 1;
        this.loadCertificateRequests();
      },
      loadHashServiceUsage(){
        const that = this;
        that.hashServiceUsageLoading = true;
        getAction(that.url.view_hashed_service_usage,{pageNo:that.hashServiceUsagePage,pageSize:5}).then(res => {
          if (res.success) {
            if(res.result.current == res.result.pages || res.result.pages == 0)
              that.hashServiceUsageIsMore = false
            else
              that.hashServiceUsageIsMore = true
            that.hashServiceUsageList = that.hashServiceUsageList.concat(res.result.records)
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.hashServiceUsageLoading = false;
        })
      },
      loadPurchaseStatus(){
        const that = this;
        that.hashPurchaseStatusLoading = true;
        getAction(that.url.view_purchase_status,{pageNo:that.hashPurchaseStatusPage,pageSize:5}).then(res => {
          if (res.success) {
            if(res.result.current == res.result.pages || res.result.pages == 0)
              that.hashPurchaseStatusIsMore = false
            else
              that.hashPurchaseStatusIsMore = true
            that.hashPurchaseStatusList = that.hashPurchaseStatusList.concat(res.result.records)
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.hashPurchaseStatusLoading = false;
        })
      },
      loadCertificateRequests(){
        const that = this;
        that.hashCertificateRequestsLoading = true;
        getAction(that.url.view_certificate_requests,{pageNo:that.hashCertificateRequestsPage,pageSize:5}).then(res => {
          if (res.success) {
            if(res.result.current == res.result.pages || res.result.pages == 0)
              that.hashCertificateRequestsIsMore = false
            else
              that.hashCertificateRequestsIsMore = true
            that.hashCertificateRequestsList = that.hashCertificateRequestsList.concat(res.result.records)
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.hashCertificateRequestsLoading = false;
        })
      },
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .window-confirm{position: fixed;z-index: 1001;width:100vw;height:100vh;left:0;top:0;background-color: rgba(255,255,255,0.8);display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;border: 2px solid #888888;border-radius: 15px;width: 760px;padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .yz{position: fixed;align-self: flex-end;margin-top:270px;margin-right:50px;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .btn{background: #797979;border-radius: 10px;margin-top:30px;height: 40px;width: 80px;cursor:no-drop;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .list{width:500px;line-height:40px;padding:50px 0;}
  .window-confirm .window .radio-div{display: flex;width:220px;justify-content: space-between;}
  .window-confirm .window .radio-div label{display: flex;align-items: center;line-height: 1;font-size:13px;cursor: pointer;}
  .window-confirm .window .radio-div input{margin-right:10px;width:18px;height:18px;}
  .window-confirm .window .remark{font-size: 18px;list-style:1;margin:30px 0;}
  .window-confirm .window .price{font-size: 16px;line-height:1;margin:30px 0;}
  .window-confirm .window .price a{font-size: 16px;color: #007AFF;line-height:1;}
  .window-confirm .window .confirm{font-size: 16px;letter-spacing: -0.6px;line-height:1;color:#4A4A4A;display: flex;align-items: center;}
  .window-confirm .window .confirm input{width:20px;height:20px;margin-right:6px;}
  .window-confirm .window .confirm a{color: #1296DB;cursor: pointer;}
  .window-confirm .window .note{font-size: 16px;line-height: 2;margin-top:50px;}
  .window-confirm .window .a{cursor: pointer;font-size: 16px;line-height: 1;color:#1296DB;margin-bottom:20px;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main{display: flex;margin-top:20px;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;color: #888888;margin:10px 0;cursor: pointer;font-weight: bold;letter-spacing: -1px;}
  .main .menu a:hover{color: #00C999;}
  .main .menu .act{color: #00C999;}
  .main .list-div{width:100%;}
  .main .list-div .til{display: flex;justify-content: space-between;align-items: center;font-family: 'Pretendard';margin-top:10px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div .list{display: flex;flex-direction: column;margin-bottom:30px;}
  .main .list-div .list .header{background-color: rgba(0, 91, 227, 0.1);display: flex;align-items: center;margin-top:10px;}
  .main .list-div .list .header span{display: flex;justify-content: center;align-items: center;width:100%;height:50px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item{border-bottom:1px solid #C4C4C4;display: flex;align-items: center;}
  .main .list-div .list .item span{display: flex;justify-content: center;word-break: break-all;align-items: center;width:100%;height:50px;flex-shrink: 1;flex-direction: column;text-align: center;}
  .main .list-div .list .remark{color:#0020C9;margin-top:10px;}
  .main .list-div .list .load-more{text-align: center;margin-top:10px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .main .list-div .list{margin: 12px;}
    .main .list-div .list span{font-size:12px !important;}
    .main .list-div .til{flex-direction: column;}
    .main .list-div .til p{color: #3B5998;font-size: 12px;margin-top:10px;margin-bottom: 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .title{font-size:20px;}
    .main .menu a{font-family: 'Pretendard';font-size: 16px;color: #888888;margin:10px 0;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
