<template>
  <div class="con" v-loading="formLoading">
    <div class="windform" v-if="formwin3">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin3 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">환불 신청 하시겠습니까?</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>환불 시 모든 파일은 폐기 되며, 복구가 불가합니다.</a>
        <div @click="refundFunction" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #B03E3E;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          환불하기
        </div>
      </div>
    </div>
    <div class="top-img pc">
      <div class="div">
        <a>마이페이지</a>
        <p>회원 정보 관리, 공동인증서 관리</p>
        <p>비밀 보관 현황, 원본증명서 신청, 결제내역, 환불처리</p>
      </div>
    </div>
    <div class="content">
      <div class="main">
        <div class="menu pc">
          <div class="menu pc">
            <a @click="goPage('/myInformation')">회원 정보 관리</a>
            <a @click="goPage('/myInformationCert')">공동인증서 관리</a>
            <a @click="goPage('/myFile')">파일 보관 현황</a>
            <a @click="goPage('/myCert')">증명서 신청내역</a>
            <a class="act">결제/환불</a>
          </div>
        </div>
        <div class="list-div">

          <div class="til pc" style="font-size: 15px;">
            * 서비스 결제 후 7일 내 환불 가능하며 시간 경과 후에는 환불이 불가합니다.(증명서 발급 완료 시 증명서 비용은 환불이 불가합니다.)<br/>
            * 계좌 이체 및 사이트 보유 현금을 통해 결제 하신 고객께서는 온라인 환불 처리가 불가하오니 자사로 연락 바랍니다.
          </div>
          <div class="mobile" style="color:#3B5998;font-size: 12px;">* 결제/환불 신청은 PC에서 이용이 가능합니다. </div>
          <div class="list" v-loading="hashCertificateRequestsLoading">
            <div class="header">
              <span>결제 일시</span>
              <span>서비스명</span>
              <span>파일명</span>
              <span>파일관리코드</span>
              <span>결제 금액</span>
              <span class="pc">상태</span>
            </div>
            <div class="item" v-for="(item,index) in hashCertificateRequestsList" :key="index">
              <span>{{item.purchasedDatetime}}</span>
              <span>
                <span v-if="item.serviceType == 1">비밀 보관 의뢰</span>
                <span v-if="item.serviceType == 2">원본증명서 신청</span>
                <span v-if="item.serviceType == 3">기간연장</span>
              </span>
              <span>{{item.fileNames}}</span>
              <span>{{item.fileNum}}</span>
              <span style="display: flex;align-items: center;justify-content: center;">{{formatPrice(item.totalPay)}} 원<span v-if="item.timeDiff > 0 && item.refundStatus == null && item.transactionType == 0" @click="confirmRefund(item)" style="cursor: pointer;width:83px;color:#01388B;height:29px;border-radius: 20px;background: rgba(1, 56, 139, 0.05);display: flex;align-items: center;justify-content: center;margin-left:10px;">환불 신청</span></span>
              <span class="pc">
                <span v-if="item.refundStatus == null">결제완료</span>
                <span style="color:#F46969;" v-if="item.refundStatus == 1">환불 대기 중</span>
                <span v-if="item.refundStatus == 2">환불 완료</span>
              </span>
            </div>
            <div class="load-more" v-if="total > 10">
              <!-- <a v-if="hashServiceUsageIsMore" @click="loadMoreHashServiceUsageList()">+ 더보기</a> -->
              <pagination
                layout="prev, pager, next"
                :page-size="pageSize"
                @current-change="change_page"
                :total="total">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import { getAction,postAction,putAction,downFile,deleteAction } from '@/network/manage'
  import { deleteActionApi, postActionApi,putActionApi,getActionApi } from '@/network/manageApi'
  import { Collapse,Pagination } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { CERT_LOGIN } from '@/store/mutation-types'
  import '@/assets/js/jsencryptKey'
  export default {
    name: 'MyService',
    components: {
      Pagination
    },
    data() {
      return {
        certImg:null,
        certIsShow:false,
        formLoading:false,
        total:0,
        pageSize:10,
        isChecked:false,
        certLogin:false,
        transactionUuid:null,
        paymentWidget:null,
        formwin3:false,
        user:null,
        infoForm:true,
        info:'',
        customerInfo:null,
        yearList:[],
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        hashCertificateRequestsLoading:false,
        hashCertificateRequestsPage:1,
        hashCertificateRequestsList:[],
        hashCertificateRequestsIsMore:false,
        url: {
          view_certificate_requests:'/user/userInformation/transaction_list',
          refund:'/user/userInformation/transaction_refund'
        }
      }
    },
    computed: {

    },
    created(){
      this.loadCertificateRequests();
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      const that = this;
      that.formLoading = true;
      getActionApi('/login-management/user').then((res) => {
        if (res.code == 200) {
          that.user = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/userInformation/my_info').then(res => {
        if (res.success) {
          if(res.result.userinfo.userName == null && res.result.userinfo.residentNumber == null && res.result.userinfo.businessName == null && res.result.userinfo.businessNumber == null)
            window.location.href = '/registerIDCert'
          that.customerInfo = res.result.userinfo
          that.paymentWidget = PaymentWidget('live_ck_YZ1aOwX7K8mzvBWmy6A3yQxzvNPG', that.customerInfo.id);
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    methods:{
      formatPrice(value){
        return new Intl.NumberFormat('en-US').format(value);
      },
      confirmRefund(item){
        this.transactionUuid = item.transactionUuid;
        this.formwin3 = true;
      },
      async refundFunction(){
        this.hashCertificateRequestsLoading = true;
        this.formwin3 = false;
        const res = await putAction(this.url.refund + "?uuid=" + this.transactionUuid);
        if(res.success){
          this.$router.go(0);
        }else{
          this.$message.warning(res.message);
        }
      },
      searchInfo(){
        this.hashCertificateRequestsPage = 1;
        this.infoForm = false;
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },
      clearInfo(){
        this.hashCertificateRequestsPage = 1;
        this.infoForm = true;
        this.info = '';
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },

      loadMoreCertificateRequestsList(){
        this.hashCertificateRequestsPage = this.hashCertificateRequestsPage + 1;
        this.loadCertificateRequests();
      },
      change_page(e){
        this.hashCertificateRequestsPage = e;
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },
      loadCertificateRequests(){
        const that = this;
        that.hashCertificateRequestsLoading = true;
        getAction(that.url.view_certificate_requests,{pageNo:that.hashCertificateRequestsPage,pageSize:10,info:this.info.trim()}).then(res => {
          if (res.success) {
            if(res.result.current == res.result.pages || res.result.pages == 0)
              that.hashCertificateRequestsIsMore = false
            else
              that.hashCertificateRequestsIsMore = true
            that.hashCertificateRequestsList = that.hashCertificateRequestsList.concat(res.result.records)
            that.total = res.result.total;
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.hashCertificateRequestsLoading = false;
        })
      },
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/mypage_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-img .div p:nth-child(3){margin-top:5px !important;}
  .window-confirm{position: fixed;z-index: 1001;width:100vw;height:100vh;left:0;top:0;background-color: rgba(255,255,255,0.8);display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;border: 2px solid #888888;border-radius: 15px;width: 760px;padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .yz{position: fixed;align-self: flex-end;margin-top:270px;margin-right:50px;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .btn{background: #797979;border-radius: 10px;margin-top:30px;height: 40px;width: 80px;cursor:no-drop;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .list{width:500px;line-height:40px;padding:50px 0;}
  .window-confirm .window .radio-div{display: flex;width:220px;justify-content: space-between;}
  .window-confirm .window .radio-div label{display: flex;align-items: center;line-height: 1;font-size:13px;cursor: pointer;}
  .window-confirm .window .radio-div input{margin-right:10px;width:18px;height:18px;}
  .window-confirm .window .remark{font-size: 18px;list-style:1;margin:30px 0;}
  .window-confirm .window .price{font-size: 16px;line-height:1;margin:30px 0;}
  .window-confirm .window .price a{font-size: 16px;color: #007AFF;line-height:1;}
  .window-confirm .window .confirm{font-size: 16px;letter-spacing: -0.6px;line-height:1;color:#4A4A4A;display: flex;align-items: center;}
  .window-confirm .window .confirm input{width:20px;height:20px;margin-right:6px;}
  .window-confirm .window .confirm a{color: #1296DB;cursor: pointer;}
  .window-confirm .window .note{font-size: 16px;line-height: 2;margin-top:50px;}
  .window-confirm .window .a{cursor: pointer;font-size: 16px;line-height: 1;color:#1296DB;margin-bottom:20px;}
  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius:30px;width:530px;height:270px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px 0;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;margin-right:14px;}
  .windform .conts p{font-size: 24px;font-weight: bold;margin:80px 0 50px 0;}
  .windform .conts .main{width:100%;flex-direction: column;padding:0 30px;font-size: 15px;align-items:center;}
  .windform .conts .main .list{height:260px;overflow-y: auto;width:100%;}
  .windform .conts .main .ok{background-color: #01388B !important;cursor: pointer;}
  .windform .conts .main .btn{width:300px;height: 40px;font-size:18px;border-radius:10px;margin-top:20px;background-color: #C4C4C4;color:#ffffff;display: flex;justify-content: center;align-items: center;}
  .windform .conts .row{display: flex;align-items: center;width:100%;}
  .windform .conts .row span{display: flex;justify-content:center;align-items: center;padding:5px 0;}
  .windform .conts .row span a{color:#00C999;}
  .windform .conts .row span:nth-child(1){width:50%;}
  .windform .conts .row span:nth-child(2){width:25%;}
  .windform .conts .row span:nth-child(3){width:25%;}
  .windform .conts .row span img{width:22px;height:22px;cursor: pointer;}
  .windform .conts .header{padding:13px 20px 13px 0;color:#5F5F5F;font-weight: bold;border-bottom:1px solid rgba(72, 83, 100, 0.46);margin-bottom:10px;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;padding:20px 0;color: #888888;cursor: pointer;font-weight: bold;letter-spacing: -1px;border-bottom:1px solid #000000;}
  .main .menu a:hover{color: #01388B;}
  .main .menu a:last-child{border-bottom-width: 0;}
  .main .menu .act{color: #01388B;}
  .main .list-div{width:100%;padding:30px;}
  .main .list-div .til{display: flex;justify-content: space-between;align-items: center;font-family: 'Pretendard';margin-top:10px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div .list{display: flex;flex-direction: column;margin-bottom:30px;}
  .main .list-div .list .header{background-color: rgba(0, 91, 227, 0.1);display: flex;align-items: center;margin-top:10px;}
  .main .list-div .list .header span{display: flex;justify-content: center;align-items: center;width:220%;height:34px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item{border-bottom:1px solid #C4C4C4;display: flex;align-items: center;}
  .main .list-div .list .item span{display: flex;justify-content: center;word-break: break-all;align-items: center;width:220%;height:60px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item span a{margin:0 6px;}
  .main .list-div .list .remark{color:#0020C9;margin-top:10px;}
  .main .list-div .list .load-more{text-align: center;margin-top:10px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .main .list-div .list{margin: 12px 0;}
    .main .list-div .list span{font-size:12px !important;}
    .main .list-div .til{flex-direction: column;}
    .main .list-div .til p{color: #3B5998;font-size: 12px;margin-top:10px;margin-bottom: 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .title{font-size:20px;}
    .con{min-height:calc(100vh - 206px);}
    .main .menu a{font-family: 'Pretendard';font-size: 16px;color: #888888;margin:10px 0;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .con{min-height:calc(100vh - 206px);}
  }
</style>
