<template>
  <div v-loading="formLoading">
    <div style="display: none;">
      <div id="payment-method"></div>
    </div>
    <div class="windform" v-if="formwin5">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin5 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">해당문서는 열람이 불가합니다.</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>다운로드해서 보시고 추가진행을 원하실 경우 문의바랍니다.</a>
        <div @click="formwin5 = false" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #01388B;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          확인
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin4">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin4 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:30px 0 20px 0;">열람 실패</p>
        <a style="display: flex;align-items: center;text-align:center;color:#01388B;font-size:15px;line-height: 2;">해당 문서는 열람이 불가합니다.<br/>다운로드 시도 후 추가진행을 원하시는 경우 문의바랍니다.</a>
        <div @click="formwin4 = false" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #01388B;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          확인
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin3">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin3 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">해당 파일을 폐기 처리하시겠습니까?</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>폐기 처리 시 복구가 불가합니다.</a>
        <div @click="deleteFileFunction" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #B03E3E;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          폐기하기
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin2">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin2 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">해당 파일을 폐기 처리하시겠습니까?</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>폐기 처리 시 복구가 불가합니다.</a>
        <div @click="deleteContentFunction" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #B03E3E;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          폐기하기
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin1">
      <div class="conts" style="height:460px;width:660px;">
        <div style="display: flex;width:100%;justify-content: space-between;padding:0px 20px 0 30px;align-items: center;font-size: 24px;font-weight: bold;">
          보관 기간연장
          <img style="width:40px;cursor: pointer;" @click="formwin1 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <div class="main" v-if="extentionState == 0">
          <div class="header row">
            <span>파일명</span>
            <span>파일관리코드</span>
            <span>잔여 기간</span>
            <span>연장 선택</span>
          </div>
          <div class="list" v-loading="fileLoading">
            <div class="row" v-for="(item,index) in myFileList" :key="index">
              <span>{{item.fileName}}</span>
              <span>{{item.fileNum}}</span>
              <span><a v-if="item.overDay > 0">{{item.overDay}}일</a><a style="color:#F46969;" v-if="item.overDay <= 0">만료</a></span>
              <span>
                <img v-if="item.checked" @click="changeExtention(index, false)" src="../assets/img2/check_act.png"/>
                <img v-if="!item.checked" @click="changeExtention(index, true)" src="../assets/img2/check.png"/>
              </span>
            </div>
          </div>
          <div class="btn ok" v-if="isExtentionBtn" @click="extentionSelectYears">
            다음
          </div>
          <div class="btn" v-if="!isExtentionBtn">
            다음
          </div>
        </div>
        <div class="main" v-if="extentionState == 1">
          <div class="list" style="height:300px;margin-top:20px;">
            <div class="lrMain" v-for="(item,index) in extentionList" :key="index">
              <div class="lrFlex">
                <span>이용중 서비스명</span>
                <span>비밀 보관 의뢰</span>
              </div>
              <div class="lrFlex">
                <span>파일명</span>
                <span>{{item.fileName}}</span>
              </div>
              <div class="lrFlex">
                <span>보관 연장 기간 선택  </span>
                <span>
                  <label @click="changeExtentionYear(index, 3)"><b :class="item.years == 3 ? 'act' : ''"></b>3년</label>
                  <label @click="changeExtentionYear(index, 7)"><b :class="item.years == 7 ? 'act' : ''"></b>7년</label>
                  <label @click="changeExtentionYear(index, 10)"><b :class="item.years == 10 ? 'act' : ''"></b>10년</label>
                </span>
              </div>
              <div class="lrFlex">
                <span>선택 금액</span>
                <span style="font-weight: normal;">{{extentionUnitMoney(index)}} 원</span>
              </div>
            </div>
          </div>
          <div class="btn ok" @click="extentionState = 2">
            다음
          </div>
        </div>
        <div class="main" v-if="extentionState == 2" v-loading="formLoading">
          <div class="list" style="height:210px;margin-top:20px;border-bottom: 1px solid #000000;overflow: hidden;">
            <div class="lrMain">
              <div style="display: flex;justify-content: space-between;padding:10px 0;">
                <span style="color:#4A4A4A;font-size:18px;font-weight: bold;">서비스 선택 금액</span>
                <span style="color: #0020C9;font-size: 18px;">{{extentionMoneyAll}} 원</span>
              </div>
              <div style="display: flex;justify-content: space-between;padding:10px 0;">
                <span style="display: flex;flex-direction: column;color:#4A4A4A;font-size:18px;font-weight: bold;">프로모션 금액<a style="font-size: 14px;font-weight: normal;">* 결제 금액에 따른 할인 금액 입니다.</a></span>
                <span style="color: #0020C9;font-size: 18px;">{{extentionDiscountMoneyAll}} 원</span>
              </div>
              <div style="display: flex;justify-content: space-between;padding:10px 0;">
                <span style="display: flex;flex-direction: column;color:#4A4A4A;font-size:18px;font-weight: bold;">보유 금액<a style="font-size: 14px;font-weight: normal;">* 센터 내 충전된 현금 금액 입니다.</a></span>
                <span style="color: #00C999;font-size: 18px;">{{myCount}} 원</span>
              </div>
            </div>
          </div>
          <div style="width:300px;display: flex;align-items: center;justify-content: space-between;margin-top:10px;">
            <span style="font-size: 22px;font-weight: bold;">결제 금액 :</span>
            <span style="color: #0020C9;font-size:22px;">{{payMoney}} 원</span>
          </div>
          <div class="btn ok" @click="initExtentionOrder">
            다음
          </div>
          <div style="text-align: center;font-size: 16px;letter-spacing: -0.6px;padding:8px;">
            안내사항 :  온라인 결제는 카드 결제 및 무통장입금만 지원 됩니다. <br/>
            계좌이체가 필요하신 고객분께서는 <a @click="goRequestBank" style="color: #0020C9;font-family:'Pretendard';text-decoration: underline;cursor: pointer;">계좌이체 요청</a>을 진행하여 주세요
          </div>
        </div>
      </div>
    </div>
    <div class="pc window-confirm" v-if="certIsShow">
      <div class="window">
        <div class="close"><img @click="certIsShow = false" src="../assets/img/x_2.png"/></div>
        <img :src="imgQZ + '/sys/common/view/' + certImg"/>
      </div>
    </div>
    <div class="pc window-confirm" v-if="deleteLog != null">
      <div class="window">
        <div class="close"><img @click="deleteLog = null" src="../assets/img/x_2.png"/></div>
        <div class="yz"><img @click="deleteLog = null" src="../assets/img/icon.png"/></div>
        <div class="til">폐기 확인서</div>
        <div class="list">
          <div>발급번호 :  {{deleteLog.contentUuid}}</div>
          <div>최초 저장 일시 :  {{deleteLog.createdDatetime}}</div>
          <div>파일명 :  {{deleteLog.fileName}}</div>
          <div>폐기일자 :  {{deleteLog.deleteDatetime}}</div>
        </div>
        <div class="pro-remark">
          상기 항목과 관련된 입증정보는 폐기되었음을 확인합니다.
        </div>
        <div class="btn ok" @click="downLoadLog">다운로드</div>
      </div>
    </div>
    <div class="con">
      <div class="top-img pc">
        <div class="div">
          <a>마이페이지</a>
          <p>회원 정보 관리, 공동인증서 관리</p>
          <p>비밀 보관 현황, 원본증명서 신청, 결제내역, 환불처리</p>
        </div>
      </div>
      <div class="content">
        <div class="main">
          <div class="menu pc">
            <div class="menu pc">
              <a @click="goPage('/myInformation')">회원 정보 관리</a>
              <a @click="goPage('/myInformationCert')">공동인증서 관리</a>
              <a class="act">파일 보관 현황</a>
              <a @click="goPage('/myCert')">증명서 신청내역</a>
              <a @click="goPage('/myTransaction')">결제/환불</a>
            </div>
          </div>
          <div class="list-div">
            <div class="til">
              <span v-if="plans.length > 0">* 원본 증명서 신청은 최초 1회 무료입니다.</span>
              <div style="width:200px;border-bottom:1px solid #000000;display: flex;align-items: center;">
                <input v-if="infoForm" style="border: none;width:100%;height:36px;text-align: center;" v-model="info" placeholder="파일명 검색"/>
                <span v-if="!infoForm" style="width:100%;display: flex;align-items: center;justify-content: center;line-height:36px;">{{info}} <img style="width: 26px;height:26px;cursor: pointer;" @click="clearInfo" src="../assets/img2/cancel.png"/></span>
                <img style="width: 24px;height:24px;flex-shrink: 0;cursor: pointer;" @click="searchInfo" src="../assets/img2/search.png"/>
              </div>
            </div>
            <div class="list" v-loading="hashServiceUsageLoading">
              <div class="header">
                <span style="width:105%;">등록일</span>
                <span style="width:100%;">파일명</span>
                <span style="width:100%;">파일관리코드</span>
                <span style="width:100%;">상태</span>

                <span class="pc">기능</span>
              </div>
              <div class="item" v-for="(item,index) in hashServiceUsageList" :key="index">
                <span style="width:105%;">{{item.createdDatetime}}</span>
                <span style="width:100%;">{{item.fileName}}</span>
                <span style="width:100%;">{{item.fileNum}}</span>
                <span style="width:100%;">
                  <div v-if="item.diffDay > 0" style="flex-direction: column;display: flex;align-items: center;">
                    <a style="color:#00C999;">전자 지문 보관중</a>
                    {{item.serviceExpiredDate}} 만료
                  </div>
                  <div v-else-if="item.fromTable == 'view_hashed_contents_failed'">
                    <a style="color:#F46969;">실패</a>
                  </div>
                  <div v-else-if="item.fromTable == 'view_hashed_contents' && item.diffDay <= 0">
                    <a style="color:#F46969;">만료</a>
                  </div>
                  <div v-else-if="item.fromTable == 'view_hash_delete_log'">
                    <a style="color:#F46969;">폐기 완료</a>
                  </div>
                </span>
                <span class="pc options">
                  <a v-if="item.uploadedFileUuid != null" @click="down_load(item.uploadedFileUuid, item.fileName)">다운로드</a>
                  <a v-if="item.uploadedFileUuid != null" @click="pre_view(item.uploadedFileUuid, item.fileName)">열람하기</a>
                  <!-- 删除 -->
                  <a @click="deleteFile(item)" v-if="item.fromTable == 'view_hashed_contents' && (item.hashedServiceStatus == 1 || item.hashedServiceStatus == 0)">폐기하기</a>
                  <!-- 重新申请 -->
                  <a v-if="item.fromTable == 'view_hashed_contents_failed'" @click="reUpload(item)">다시신청</a>
                  <!-- 删除 -->
                  <a v-if="item.fromTable == 'view_hashed_contents_failed'" @click="deleteContent(item)">삭제</a>
                  <!-- 延期 -->
                  <a style="color:#0020C9;" v-if="item.serviceExpiredDate != null" @click="yqFunction(item)">연장하기</a>
                  <!-- 申请证书 -->
                  <a v-if="item.serviceExpiredDate != null && item.diffDay > 0" @click="reCertFunction(item)">증명서 신청</a>
                  <!-- 查看删除日志 -->
                  <a v-if="item.fromTable == 'view_hash_delete_log'" @click="viewHashDeleteLog(item.contentUuid)">폐기확인</a>
                </span>
              </div>
              <div class="load-more" v-if="total > 10">
                <!-- <a v-if="hashServiceUsageIsMore" @click="loadMoreHashServiceUsageList()">+ 더보기</a> -->
                <pagination
                  layout="prev, pager, next"
                  :page-size="pageSize"
                  @current-change="change_page"
                  :total="total">
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose">
      <div style="display: flex;align-items: center;justify-content: center;">
          <el-progress type="line" :percentage="total_progress" style="width:100%;" :stroke-width="20"></el-progress>
      </div>
    </el-dialog>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisibleMp3"
      width="25%"
      :before-close="handleCloseMp3">
      <div style="display: flex;align-items: center;justify-content: center;">
        <audio id="audio" :src="media_resources" controls="controls" controlsList="nodownload"></audio>
      </div>
    </el-dialog>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisibleMp4"
      width="550px"
      :before-close="handleCloseMp4">
      <div style="display: flex;align-items: center;justify-content: center;">
        <video id="video" width="470" controls  controlsList="nodownload">
            <source :src="media_resources" type="video/mp4">
        </video>
      </div>
    </el-dialog>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisibleTxt"
      :before-close="handleCloseTxt">
      <pre style="word-break:break-all;overflow-x: hidden;height:600px;overflow-y: auto;">{{txtContent}}</pre>
    </el-dialog>

    <el-dialog
      top="10vh"
      :visible.sync="dialogVisiblePdf"
      :before-close="handleCloseTxtPdf">
      <vue-office-pdf :src="pdf_src" style="height:600px;"/>
    </el-dialog>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisibleExcel"
      :before-close="handleCloseExcel">
      <vue-office-excel :src="excel_src" style="height:600px;"/>
    </el-dialog>
    <el-dialog
      top="10vh"
      :visible.sync="dialogVisibleDocx"
      :before-close="handleCloseDocx">
      <vue-office-docx :src="docx_src" style="height:600px;"/>
    </el-dialog>
    <el-dialog
      top="10vh"
      custom-class="bgw"
      :visible.sync="dialogVisibleHwp"
      :before-close="handleCloseHwp">
      <div id="hwpcontent" style="height:600px;"></div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'

  import { api as viewerApi } from 'v-viewer'
  import { v4 as uuidv4 } from 'uuid';
  import Tiff from 'tiff.js'
  import PSD from 'psd.js'
  import VueOfficePdf from '@vue-office/pdf'
  import VueOfficeExcel from '@vue-office/excel'
  import '@vue-office/excel/lib/index.css'
  import VueOfficeDocx from '@vue-office/docx'
  import '@vue-office/docx/lib/index.css'
  import { getAction,postAction,putAction,downFile,deleteAction } from '@/network/manage'
  import { deleteActionApi, postActionApi,putActionApi,getActionApi,postDownloadActionApi } from '@/network/manageApi'
  import { Collapse,Pagination } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { CERT_LOGIN } from '@/store/mutation-types'
  import '@/assets/js/jsencryptKey'
  export default {
    name: 'MyService',
    components: {
      VueOfficePdf,
      Pagination,
      VueOfficeExcel,
      VueOfficeDocx
    },
    data() {
      return {
        certImg:null,
        certIsShow:false,
        formLoading:false,
        deleteLog:null,
        total:0,
        pageSize:10,
        paymentWidget:null,
        myFileList:[],
        extentionState:0,
        myCount:0,
        extentionList:[],
        plans:[],
        plansDiscount:[],
        info:'',
        fileLoading:false,
        infoForm:true,
        formwin5:false,
        formwin4:false,
        formwin3:false,
        formwin2:false,
        formwin1:false,
        isChecked:false,
        certLogin:false,
        currentPrice:0,
        currentYear:3,
        contents_uuid:null,
        contents_uuid_file:null,
        currentContentId:null,
        user:null,
        customerInfo:null,
        hashServiceUsageLoading:false,
        hashServiceUsagePage:1,
        hashServiceUsageList:[],
        hashServiceUsageIsMore:false,
        yearList:[],
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        url: {
          view_hashed_service_usage:'/user/userInformation/view_hashed_service_usage',
          view_certificate_requests:'/user/userInformation/view_certificate_requests',
          view_purchase_status:'/user/userInformation/purchase_log_list',
          view_hash_delete_log:'/user/userInformation/view_hash_delete_log',
          sign_failed:'/get-timestamp/sign-failed',
          file_list:'/user/userInformation/file_list'
        },
        total_progress: 0,
        photo: [],
        now_uuid_file: '',
        dialogVisible: false,
        dialogVisibleMp3: false,
        dialogVisibleMp4: false,
        dialogVisibleTxt: false,
        dialogVisiblePdf: false,
        dialogVisibleExcel: false,
        dialogVisibleDocx: false,
        dialogVisibleHwp: false,
        media_resources:'',
        txtContent: '',
        pdf_src: '',
        excel_src: '',
        docx_src: '',
        excelHtml: ''
      }
    },
    computed: {
      isExtentionBtn(){
        let checked = false;
        for(let i = 0; i < this.myFileList.length; i++){
          if(this.myFileList[i].checked)
            checked = true;
        }
        return checked;
      },
      extentionMoneyAll(){
        let moneyAll = 0;
        for(let i = 0;i<this.extentionList.length;i++){
          moneyAll += this.extentionUnitMoney(i);
        }
        return moneyAll
      },
      extentionDiscountMoneyAll(){
        let disCount = 0;
        for(let i = 0; i < this.plansDiscount.length; i++){
          if(this.extentionMoneyAll >= this.plansDiscount[i].priceMinimum)
            disCount = this.plansDiscount[i].discountedPrice
        }
        return disCount
      },
      payMoney(){
        const moneyAll = this.extentionMoneyAll - this.extentionDiscountMoneyAll;
        if(this.myCount >= moneyAll)
          return 0;
        else if(this.myCount <= 0)
          return moneyAll;
        else
          return moneyAll - this.myCount
      }
    },
    created(){
      this.loadHashServiceUsage();
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      const that = this;
      that.formLoading = true;
      that.fileLoading = true;
      getAction(that.url.file_list).then((res) => {
        if (res.success) {
          that.myFileList = res.result
        } else {
          that.$message.error(res.message);
        }
        that.fileLoading = false;
      }).catch(res => {
        that.$message.warning(res.message);
        that.fileLoading = false;
      })
      getActionApi('/login-management/user').then((res) => {
        if (res.code == 200) {
          that.user = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/servicePlans/plansList').then((res) => {
        if (res.success) {
          that.plans = res.result.plans;
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/servicePlans/plansListDiscount').then((res) => {
        if (res.success) {
          that.plansDiscount = res.result;
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/userInformation/my_info').then(res => {
        if (res.success) {
          if(res.result.userinfo.userName == null && res.result.userinfo.residentNumber == null && res.result.userinfo.businessName == null && res.result.userinfo.businessNumber == null)
            window.location.href = '/registerIDCert'
          that.customerInfo = res.result.userinfo
          that.myCount = res.result.userinfo.userPoints
          that.paymentWidget = PaymentWidget('live_ck_YZ1aOwX7K8mzvBWmy6A3yQxzvNPG', that.customerInfo.id);
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    methods:{
      change_page(e){
        this.hashServiceUsagePage = e;
        this.hashServiceUsageList = [];
        this.loadHashServiceUsage();
      },
      stop_audio() {
        let myAudio = document.getElementById("audio")
        if(myAudio) {
          myAudio.pause();
        }
      },
      stop_video() {
        let myVideo = document.getElementById("video")
        if(myVideo) {
          myVideo.pause();
        }
      },
      handleClose(done) {
        this.$confirm('로드를 중단하시겠습니까?')
        .then(_ => {
          this.now_uuid_file = 0
          this.total_progress = 0
          done();
        })
        .catch(_ => {});
      },
      handleCloseMp3(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        this.media_resources = ''
        this.stop_audio()
        done();
      },
      handleCloseMp4(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        this.media_resources = ''
        this.stop_video()
        done();
      },
      handleCloseTxt(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        done();
      },
      handleCloseTxtPdf(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        this.pdf_src = ''
        done();
      },
      handleCloseExcel(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        this.excel_src = ''
        done();
      },
      handleCloseDocx(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        this.docx_src = ''
        done();
      },
      handleCloseHwp(done) {
        this.now_uuid_file = 0
        this.total_progress = 0
        document.getElementById("hwpcontent").innerHTML = ""
        done();
      },
      showImage (blob) {
        this.photo = []
        this.photo.push({"data-source": blob})
        const $viewer = viewerApi({
          options: {
            title:false,
            toolbar: true,
            scalable: false,
            fullscreen: false,
            url: 'data-source',
            initialViewIndex: 0,
          },
          images: this.photo,
        })
      },
      async pre_view(uuid, fileName) {
        let that = this
        try {
           let type = this.get_type(fileName)
           if(type == -1) {
             this.formwin4 = true
             return;
           }
           this.total_progress = Math.floor(Math.random()*10)
           this.dialogVisible = true
           let uuid_new = uuidv4()
           this.now_uuid_file = uuid_new
           let blob = await this.load_resources(uuid)
           if(this.now_uuid_file == uuid_new) {
             const url = window.URL.createObjectURL(blob)
             this.dialogVisible = false
             if(type == 1) {
               this.showImage(url)
             } else if(type == 2) {
               this.dialogVisibleMp3 = true
               this.media_resources = url
               try{
                 document.getElementById("audio").load()
               }catch(e){}
             } else if(type == 3) {
               this.dialogVisibleMp4 = true
               this.media_resources = url
               try{
                 document.getElementById("video").load()
               }catch(e){}
             } else if(type == 4) {
               PSD.fromURL(url).then(psd => {
                 try{
                    that.showImage(psd.image.toBase64())
                 } catch(e) {
                   that.formwin4 = true
                 }
               })
             } else if(type == 5) {
               var reader = new FileReader();
               reader.readAsArrayBuffer(blob);
               reader.onload = function() {
                 try{
                    const tiff = new Tiff({ buffer: this.result })
                    that.showImage(tiff.toDataURL())
                 } catch(e) {
                   that.formwin4 = true
                 }
               }
             } else if(type == 6) {
               blob.text().then(data =>{
                 this.txtContent = data
                 this.dialogVisibleTxt = true
               })
             } else if(type == 7) {
               this.pdf_src = url
               this.dialogVisiblePdf = true
             } else if(type == 8) {
               this.excel_src = url
               this.dialogVisibleExcel = true
             } else if(type == 9) {
               this.docx_src = url
               this.dialogVisibleDocx = true
             } else if(type == 10) {
                //hwp
                that.dialogVisibleHwp = true
                var reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = function() {
                  try {
                    new HWP.Viewer(document.getElementById("hwpcontent"),new Uint8Array(this.result), { type: 'array' })
                  } catch(e) {
                    that.dialogVisibleHwp = false
                    that.formwin5 = true
                  }
                }
             }
           }
        } catch (e) {
          that.formwin4 = true
        }
      },
      async down_load(uuid, fileName) {
        this.total_progress = 0
        this.dialogVisible = true
        let uuid_new = uuidv4()
        this.now_uuid_file = uuid_new
        let type = this.get_type(fileName)
        let blob = await this.load_resources(uuid)
        if(this.now_uuid_file == uuid_new) {
          this.dialogVisible = false
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(link.href)
        }
      },
      get_type(fileName) {
        let suffix_name = this.get_file_type(fileName).toLowerCase();
        //其他类型
        let type = -1;
        if(suffix_name == 'mp4'
        || suffix_name == 'mkv'
        || suffix_name == 'mov') {
          //视频
          type = 3
        } else if(suffix_name == 'mp3' || suffix_name == 'flac'
        || suffix_name == 'wav') {
          //音频
          type = 2
        } else if(suffix_name == 'jpg' || suffix_name == 'jpeg'
        || suffix_name == 'png' || suffix_name == 'gif'){
          //图片
          type = 1
        } else if(suffix_name == 'psd') {
          //psd文件
          type = 4
        } else if(suffix_name == 'tif') {
          //tif图像
          type = 5
        } else if(suffix_name == 'txt') {
          type = 6
        } else if(suffix_name == 'pdf') {
          type = 7
        } else if(suffix_name == 'xlsx') {
          type = 8
        } else if(suffix_name == 'docx') {
          type = 9
        } else if(suffix_name == 'hwp') {
          type = 10
        }
        return type;
      },
      get_file_type(fileName) {
        let num = fileName.split(".").length - 1
        return fileName.split(".")[num];
      },
      async load_resources(uuid){
        let fileBlob = []
        const tasks = [];
        const that = this;
        //总片数
        let chunks = 0;
        //当前下载数量
        let downloaded = 0;
        let obj = await postActionApi('/hash-generator/download/initial-create',{"contents_file_upload_uuid": uuid})
        //获得总片数
        chunks = obj.data.contents.piece_information.length
        if(obj.code == 200) {
          let file_array = obj.data.contents.piece_information
          for (let index = 0; index < file_array.length; index++) {
            tasks.push(
              new Promise((resolve, reject) => {
                let downData = {download_order: file_array[index].download_order}
                const params = {"download_piece_uuid": file_array[index].contents_file_download_piece_information}
                this.downLoadbyPiece(params).then(res => {
                  downData.data = res
                  fileBlob.push(downData)
                  //已下载数量+1
                  downloaded++;
                  //计算总进度
                  this.total_progress = Math.floor((downloaded / chunks) * 100);
                  resolve()
                }).catch(err => {
                  reject(err)
                })
              })
            )
          }
          await Promise.all(tasks);
          let parts = fileBlob.sort((a, b) => a.download_order - b.download_order)
          let fileBlobDatas = []
          for (let index = 0; index < parts.length; index++) {
            fileBlobDatas.push(parts[index].data)
          }
          const blob = new Blob(fileBlobDatas)
          return blob;
        }
      },
      downLoadbyPiece(params) {
        return postDownloadActionApi('/hash-generator/download/piece-download',params);
      },
      searchInfo(){
        this.hashServiceUsagePage = 1;
        this.infoForm = false;
        this.hashServiceUsageList = [];
        this.loadHashServiceUsage();
      },
      clearInfo(){
        this.hashServiceUsagePage = 1;
        this.infoForm = true;
        this.info = '';
        this.hashServiceUsageList = [];
        this.loadHashServiceUsage();
      },
      toThousands(num) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      },
      down_load_file_pdf(item){
        let fileName = "cert"
        let params = {id: item.contentsOriginalityRequestUuid}
        downFile("/user/userInformation/show_cert_view",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
      },
      changeYear(item){
        this.currentPrice = item.promotionPrice/10
        this.currentYear = item.serviceLength
      },
      downLoadLog(){
        let fileName = "폐기 확인서"
        let params = {id: this.deleteLog.contentUuid}
        downFile("/user/userInformation/show_del_log_view",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
      },
      tkApp(item){
        this.$messageBox('해당 결제 금액의 환불 처리를 진행하시겠습니까?', '알림', {
          confirmButtonText: '확인',
          type: 'warning'
        }).then(() => {
          const that = this;
          that.formLoading = true;
          putAction('/user/userInformation/refund_app/'+item.purchaseLogUuid).then(res => {
            if(res.success){
              that.$router.go(0);
            }else{
              that.$message.warning(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        })

      },
      reCertFunction(item){
        this.$router.push({name:'myServiceCert',query:{cuuid:item.contentUuid}});
      },

      submitCheck(e){
        this.isChecked = e.target.checked;
      },
      yqFunction(item){
        this.currentContentId = item.contentUuid
        this.extentionState = 0
        for(let i = 0; i < this.myFileList.length; i++)
          this.$set(this.myFileList[i],'checked',this.myFileList[i].contentUuid == this.currentContentId)
        this.formwin1 = true
      },

      goRequestBank(){
        this.$router.push({path:'/serviceBankMessage'});
      },
      deleteContent(item){
        this.formwin2 = true;
        this.contents_uuid = item.contentUuid;
      },
      deleteContentFunction(){
        const that = this;
        that.formwin2 = false;
        that.formLoading = true;
        deleteActionApi(that.url.sign_failed,{contents_fail_uuid:this.contents_uuid}).then(res => {
          if (res.code == 200) {
            that.$router.go(0);
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
          that.formLoading = false
        })
      },
      async reUpload(item){
        window.location.href='/reUpload?nowid='+item.contentUuid;
      },

      viewHashDeleteLog(uuid){
        const that = this;
        that.deleteLog = null;
        getAction(that.url.view_hash_delete_log,{contentUuid:uuid}).then(res => {
          if (res.success) {
            that.deleteLog = res.result;
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        })
      },
      deleteFile(item){
        const that = this;
        if(!this.certLogin){
          this.$messageBox('인증서 로그인이 요구됩니다', 'Warning', {
            confirmButtonText: 'register',
            type: 'warning'
          }).then(() => {
            that.$router.push({name:'login',query:{loginType:1}})
          })
          return;
        }
        this.formwin3 = true;
        if(item.isFileContains == 1)
          this.contents_uuid_file = item.uploadedFileUuid;
        else
          this.contents_uuid_file = null;
        this.contents_uuid = item.contentUuid;
      },
      dfl(){
        const that = this;
        deleteActionApi('/get-timestamp/delete-hashed-contents',{contents_uuid:that.contents_uuid}).then((res) => {
          if (res.code == 200) {
            that.$message.success('delete success!');
            that.$router.go(0);
          } else {
            that.$message.error(res.message);
            that.formLoading = false;
          }
        }).catch(res => {
          that.$message.warning(res.message);
          that.formLoading = false;
        })
      },
      deleteFileFunction(){
        const that = this;
        that.formwin3 = false;
        that.formLoading = true;
        if(that.contents_uuid_file != null){
          deleteActionApi('/hash-generator/my-upload',{contents_file_upload_uuid:that.contents_uuid_file}).then((res) => {
            if(res.code == 200){
              that.dfl();
            }else {
              that.$message.error(res.message);
            }
          }).catch(res => {
            that.$message.warning(res.message);
          })
        }else{
          that.dfl();
        }
      },
      loadMoreHashServiceUsageList(){
        this.hashServiceUsagePage = this.hashServiceUsagePage + 1;
        this.loadHashServiceUsage();
      },
      changeExtentionYear(index,years){
        this.$set(this.extentionList[index],'years',years);
      },
      async yqFss(){
        //调用延期方法
        for(let i = 0; i < this.serviceManagement.length;i++){
          const res = await putActionApi('/get-timestamp/date-extention',{contents_uuid:this.extentionList[i].contentUuid,service_management_uuid:this.serviceManagement[i].serviceManagementUuid})
          if(res.code != 200){
            this.$message.error(res.message);
            return;
          }
        }
        this.$router.go(0);
      },
      async initExtentionOrder(){
        this.formLoading = true;
        const that = this;
        let res = await postAction('/user/userInformation/init_extention_order',this.extentionList)
        if(res.success){
          this.serviceManagement = res.result.serviceManagementList
          if(res.result.tossPayment == 0){
            that.sendMsg(res.result.paymentAll,res.result.transactionType);
            that.yqFss();
          }else{
            //调用支付
            that.paymentWidget.renderPaymentMethods('#payment-method', res.result.tossPayment);
            that.paymentWidget.requestPayment({
              orderId: res.result.transactionUuid,
              orderName: 'Extension of Term',
              //successUrl: 'https://www.onnuriip.com/paySuccess',
              //failUrl: 'https://www.onnuriip.com/payFail',
              customerEmail: that.user.email,
              customerName: that.customerInfo.userId
            }).then(function (data) {
              // 성공 처리: 결제 승인 API를 호출하세요
              const param = {
                orderId:data.orderId,
                paymentKey:data.paymentKey,
                amount:data.amount
              }
              putAction('/user/userInformation/company_transaction',param).then((ress) => {
                if (ress.success) {
                  that.sendMsg(res.result.paymentAll,res.result.transactionType);
                  that.yqFss();
                } else {
                  that.$message.warning('결제 실패');
                }
              }).catch(res => {
                that.$message.warning('결제 실패');
              })
            }).catch(function (error) {
              that.$message.warning('결제 실패');
              putAction('/user/userInformation/cancel_transaction/'+res.result.transactionUuid).then((ress) => {
              }).catch(res => {
              })
            })
          }
        }else{
          this.$message.error(res.message);
        }
        this.formLoading = false;
      },
      sendMsg(moneyAll,payType){
        const that = this;
        //发送邮件
        let fileNamesEmail = that.extentionList[0].fileName;
        if(that.extentionList.length > 1)
          fileNamesEmail += '등 ' + that.extentionList.length + '건';
        getAction('/user/userInformation/sendEmailToss',{fileName:fileNamesEmail,serviceName:'기간연장',email:that.user.email}).then((ress) => {

        }).catch(res => {
        })

        if(that.user.phone_number != null){
          getAction('/user/userInformation/sendMessageToss',{phone:that.user.phone_number,money:moneyAll,payType:payType}).then((res) => {
          }).catch(res => {
          })
        }
      },
      extentionSelectYears(){
        let extentionList = [];
        for(let i = 0; i < this.myFileList.length; i++){
          if(this.myFileList[i].checked){
            this.$set(this.myFileList[i],'years',3);
            extentionList.push(this.myFileList[i]);
          }
        }
        this.extentionList = extentionList;
        this.extentionState = 1;
      },
      extentionUnitMoney(index){
        const years = this.extentionList[index].years;
        const plansIdHash = this.extentionList[index].plansIdHash;
        const plansIdFile = this.extentionList[index].plansIdFile;
        let money = 0;
        if(years == 3)
          money += this.plans[18].servicePrice
        else if(years == 7)
          money += this.plans[19].servicePrice
        else if(years == 10)
          money += this.plans[20].servicePrice
        if(plansIdFile != null){
          for(let i = 0; i < this.plans.length; i++){
            if(this.plans[i].servicePlanId == plansIdFile){
              for(let k = 0; k < this.plans.length; k++){
                if(this.plans[k].serviceLength == years && this.plans[k].fileSize == this.plans[i].fileSize)
                  money += this.plans[k].servicePrice
              }
            }
          }
        }
        return money;
      },
      changeExtention(index,state){
        this.$set(this.myFileList[index],'checked',state);
      },
      async loadHashServiceUsage(){
        const that = this;
        that.hashServiceUsageLoading = true;
        let res = await getAction(that.url.view_hashed_service_usage,{pageNo:that.hashServiceUsagePage,pageSize:10,info:this.info.trim()});
        if (res.success) {
          if(res.result.current == res.result.pages || res.result.pages == 0)
            that.hashServiceUsageIsMore = false
          else
            that.hashServiceUsageIsMore = true
          that.hashServiceUsageList = that.hashServiceUsageList.concat(res.result.records)
          that.total = res.result.total;
        } else {
          that.$message.error(res.message);
        }
        that.hashServiceUsageLoading = false;
      },
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>
<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/mypage_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-img .div p:nth-child(3){margin-top:5px !important;}
  .window-confirm{position: fixed;z-index: 1001;width:100vw;height:100vh;left:0;top:0;background-color: rgba(255,255,255,0.8);display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;border: 2px solid #888888;border-radius: 15px;width: 760px;padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .yz{position: fixed;align-self: flex-end;margin-top:270px;margin-right:50px;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .btn{background: #797979;border-radius: 10px;margin-top:30px;height: 40px;width: 80px;cursor:no-drop;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .list{width:500px;line-height:40px;padding:50px 0;}
  .window-confirm .window .radio-div{display: flex;width:220px;justify-content: space-between;}
  .window-confirm .window .radio-div label{display: flex;align-items: center;line-height: 1;font-size:13px;cursor: pointer;}
  .window-confirm .window .radio-div input{margin-right:10px;width:18px;height:18px;}
  .window-confirm .window .remark{font-size: 18px;list-style:1;margin:30px 0;}
  .window-confirm .window .price{font-size: 16px;line-height:1;margin:30px 0;}
  .window-confirm .window .price a{font-size: 16px;color: #007AFF;line-height:1;}
  .window-confirm .window .confirm{font-size: 16px;letter-spacing: -0.6px;line-height:1;color:#4A4A4A;display: flex;align-items: center;}
  .window-confirm .window .confirm input{width:20px;height:20px;margin-right:6px;}
  .window-confirm .window .confirm a{color: #1296DB;cursor: pointer;}
  .window-confirm .window .note{font-size: 16px;line-height: 2;margin-top:50px;}
  .window-confirm .window .a{cursor: pointer;font-size: 16px;line-height: 1;color:#1296DB;margin-bottom:20px;}
  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius:30px;width:530px;height:270px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px 0;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;margin-right:14px;}
  .windform .conts p{font-size: 24px;font-weight: bold;margin:80px 0 50px 0;}
  .windform .conts .main{width:100%;flex-direction: column;padding:0 30px;font-size: 15px;align-items:center;}
  .windform .conts .main .list{height:260px;overflow-y: auto;width:100%;}
  .windform .conts .main .ok{background-color: #01388B !important;cursor: pointer;}
  .windform .conts .main .btn{width:300px;height: 40px;font-size:18px;border-radius:10px;margin-top:20px;background-color: #C4C4C4;color:#ffffff;display: flex;justify-content: center;align-items: center;}
  .windform .conts .row{display: flex;align-items: center;width:100%;}
  .windform .conts .row span{display: flex;justify-content:center;align-items: center;padding:5px 5px;word-break: break-all;text-align: center;}
  .windform .conts .row span a{color:#00C999;}
  .windform .conts .row span:nth-child(1){width:40%;}
  .windform .conts .row span:nth-child(2){width:30%;}
  .windform .conts .row span:nth-child(3){width:20%;}
  .windform .conts .row span:nth-child(4){width:20%;}
  .windform .conts .row span img{width:22px;height:22px;cursor: pointer;}
  .windform .conts .header{padding:13px 20px 13px 0;color:#5F5F5F;font-weight: bold;border-bottom:1px solid rgba(72, 83, 100, 0.46);margin-bottom:10px;}
  .lrMain{padding:10px;border-bottom: 1px solid rgba(72, 83, 100, 0.46);margin-right:10px;}
  .lrMain:last-child{border-bottom-width: 0;}
  .lrFlex{display: flex;align-items: center;font-weight: bold;font-size:15px;padding:6px 0;}
  .lrFlex span{width:50%;color:#5F5F5F;display: flex;align-items: center;}
  .lrFlex span:nth-child(2){color:#000000;}
  .lrFlex label{flex-shrink: 0;display: flex;align-items: center;margin-right:10px;cursor: pointer;}
  .lrFlex label b{width: 24px;height: 24px;margin-right: 5px;border-radius: 50%;border: 1px solid #D0D0D0;flex-shrink: 0;}
  .lrFlex label .act{border: 7px solid #000000;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;padding:20px 0;color: #888888;cursor: pointer;font-weight: bold;letter-spacing: -1px;border-bottom:1px solid #000000;}
  .main .menu a:hover{color: #01388B;}
  .main .menu a:last-child{border-bottom-width: 0;}
  .main .menu .act{color: #01388B;}
  .main .list-div{width:100%;padding:30px;}
  .main .list-div .til{display: flex;justify-content: space-between;align-items: center;font-family: 'Pretendard';margin-top:10px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div .list{display: flex;flex-direction: column;margin-bottom:30px;}
  .main .list-div .list .header{background-color: rgba(0, 91, 227, 0.1);display: flex;align-items: center;margin-top:10px;}
  .main .list-div .list .header span{display: flex;justify-content: center;align-items: center;width:230%;height:34px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item{border-bottom:1px solid #C4C4C4;display: flex;align-items: center;}
  .main .list-div .list .item span{display: flex;justify-content: center;word-break: break-all;align-items: center;width:240%;height:60px;flex-shrink: 1;text-align: center;padding:0 5px;}
  .main .list-div .list .item span a{margin:0 3px;}
  .main .list-div .list .remark{color:#0020C9;margin-top:10px;}
  .main .list-div .list .load-more{text-align: center;margin-top:10px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .main .list-div .list{margin: 12px;}
    .main .list-div .list span{font-size:12px !important;}
    .main .list-div .til{flex-direction: column;}
    .main .list-div .til p{color: #3B5998;font-size: 12px;margin-top:10px;margin-bottom: 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .title{font-size:20px;}
    .main .list-div .til span{font-size: 12px;}
    .main .list-div .list .item *{font-size:12px;}
    .main .menu a{font-family: 'Pretendard';font-size: 16px;color: #888888;margin:10px 0;}
    .con{min-height:calc(100vh - 206px);}
     .main .list-div .list .header span{width:230%;}
     .main .list-div .list .item span{width:230%;}
     .main .list-div .list .item span a{margin:0 2px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .con{min-height:calc(100vh - 206px);}
  }
</style>
