import Vue from 'vue'
import Storage from 'vue-ls'
import App from './App.vue'
import router from './router'
import 'viewerjs/dist/viewer.css'
import '@/permission'
import { Message, MessageBox, Notification, Loading, Icon,Dialog,Progress } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ko'
import locale from 'element-ui/lib/locale'


Vue.config.productionTip = false

const storageOptions = {
  namespace: 'hash_',
  name: 'ls',
  storage: 'local',
}
Vue.use(Storage, storageOptions)

locale.use(lang)
Vue.use(Loading)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Progress)

Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$notification = Notification
Vue.prototype.$loading = Loading
Vue.prototype.$confirm = MessageBox.confirm

import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
if(document.cookie.indexOf('hash_login=') == -1){
  Vue.ls.remove(ACCESS_TOKEN);
  Vue.ls.remove(CERT_LOGIN);
  document.cookie = "hash_login=1"
}
import store from './store'
new Vue({
  render: h => h(App),
	router,
  store
}).$mount('#app')

