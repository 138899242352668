<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content">
      <div class="title">Help center</div>
      <div class="title2">a.m. 09:00 to p.m. 6:00</div>
      <div class="info">
        <div class="item">
          <span>문의하실 내용을 입력 하여 주세요요</span>
          <div class="bord">
            <input placeholder="제목" v-model="param.title" maxlength="50"/>
            <textarea placeholder="내용" v-model="param.description" maxlength="500"></textarea>
          </div>
        </div>
        <div class="item">
          <span>연락받으실 E-mail</span>
          <input v-model="param.email" maxlength="50"/>
        </div>
        <div class="btn ok" @click="saveInfo">제출</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { postAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'HelpCenter',
    components: {
    },
    data() {
      return {
        param:{title:'',description:'',email:''},
        formLoading:false,
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      changeCheck(e){
        this.isCheck = e.target.checked;
      },
      saveInfo(){
        const that = this;
        var re = new RegExp(this.emailValidate);
        //验证
        if(that.param.title == ''){
          that.$message.error('문의 제목을 입력하여 주세요');
        }else if(that.param.description == ''){
          that.$message.error('문의 내용을 입력하여 주세요');
        }else if(that.param.email == ''){
          that.$message.error('이메일을 입력하세요');
        }else if(!re.test(this.param.email)){
          that.$message.error('이메일 양식 오류 : 정확한 이메일을 입력하세요');
        }else{
          that.formLoading = true;
          postAction('/user/userInformation/help_center',that.param).then(res => {
            if (res.success) {
              that.$message.success('success!');
              that.param = {title:'',description:'',email:''}
            } else {
              that.$message.error(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:20px 0 6px 0;font-family: 'Pretendard';color: #01388B;font-weight: bold;}
  .title2{margin-bottom:20px;font-family: 'Pretendard';letter-spacing: -0.6px;color: #888888;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;font-family: 'Pretendard';font-weight: 600;}
  .content .info .item input{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:38px;padding:0 10px;}
  .content .info .item textarea{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 140px;height: 40px;margin:auto;line-height:1;margin-top:40px;color:#ffffff;background: #797979;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  .content .info .ok{background: #01388B;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .bg{background-image: url(../assets/img/help-center-bg.png);background-size: cover;z-index: -1;width: 100vw;height:100vh;top:0;left:0;position: fixed;}
  	.pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{align-self: center;margin-bottom:10px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
