<template>
  <div class="content">
    <div class="main">
      <div class="title">
        <span>인사말</span>
      </div>
      <img class="img about1" :class="showDH1 ? 'slide-in' : ''" src="../assets/img/about1.png"/>
      <div class="bg mobile">
        <img src="../assets/img/about2.png" style="width:90%;margin-top:140px;"/>
      </div>
      <div class="flex about2" :class="showDH2 ? 'slide-in' : ''">
        <img src="../assets/img/about2.png" class="pc" style="width:30%;margin-right:20px;"/>
        <p>
            안녕하세요.<br/><br/>
            온누리 국제영업비밀보호센터에 오신 것을 환영합니다.<br/><br/>
            온누리국제영업비밀보호센터는 우리나라 수출기업의 해외지재권 분쟁에 대비하여 영업비밀을 객관적으로 증명하는 데에 최적화된 기관입니다. 영업비밀은 특허나 저작권/디자인 등의 잘 알려진 지식재산권과 상호보완적인 관계로, 지금 이 시대에 추가적으로 강화해야 하는 신지식재산권이라고 말씀드릴 수 있습니다.<br/><br/>
            영업비밀을 보호하는 법과 체제는 업무와 관련된 정보를 책임감 있게 다루어야 한다는 인식을 더욱 강화하고, 기업의 소중한 지적 자산을 보호하고 발전시키며, 기업의 신뢰성과 안전성을 높이는 데에 중요한 역할을 하게 됩니다.<br/><br/>
            온누리국제영업비밀보호센터는 영업비밀 등록관리를 시작으로 전세계 영업비밀보호센터의 허브로 도약하고, 언젠가는 법적으로 보호할 가치가 있는 모든 창조적 가치를 보호할 수 있을 때까지 나아갈 것입니다. 온누리는 영어의 ON (살아있는), 우리말의 누리 (세상)를 합쳐서 만든 말로, 살아 움직이는 모든 창조적 가치, 법적으로 보호할 가치가 있는 모든 지식재산권 (ONNURI Intellectual Property: All the Creative Value Deserving Legal Protection)을 의미하는 새로운 용어입니다.<br/><br/>
            여러분의 소중한 영업비밀은 기업분쟁 전문가인 미국변호사(NY)의 철저한 관리를 통하여 온누리국제영업비밀보호센터에서 전력을 다하여 안전하게 보호하겠습니다. 앞으로 많은 관심과 성원을 부탁드립니다. 감사합니다.<br/><br/>
            온누리국제영업비밀보호센터장 최윤경.
        </p>
      </div>
      <!-- <div class="remark1 about3" :class="showDH3 ? 'slide-in' : ''">
       온누리국제영업비밀보호센터는 부정경쟁방지와 영업비밀보호에 관한 법률 제9조의 3에 의거, 특허청에서 지정하는 원본증명서비스를 시행하고 있습니다.
        특허청 지정범위는 전자지문보관을 통한 원본증명입니다.  또한 최대5억원까지 배상하는 책임보험에 자체적으로 가입하여 의뢰받은 영업비밀보호에 만전을 기하였습니다.
      </div> -->
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',
    components: {
    },
    data() {
      return {
        showDH1:false,
        showDH2:false,
        showDH3:false,
        url: {

        }
      }
    },
    computed: {

    },
    created(){
      const that = this;
      setTimeout(function(){
        that.showDH1 = true;
        that.showDH2 = true;
      },100)

    },
    mounted() {
      window.addEventListener("scroll", this.scrolling);
    },
    methods:{
      scrolling() {
        let current = document.documentElement.scrollTop || document.body.scrollTop;
        if(current >= 400) {
          this.showDH3 = true
        }
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .about1{position:relative;top: -50px;transition: all 1s ease;opacity: 0;}
  .about2{position:relative;left: -50px;transition: all 1s ease;opacity: 0;}
  .about3{position:relative;top: 50px;transition: all 1s ease;opacity: 0;}
  .slide-in {top: 0;left: 0;opacity: 1;}
  .main{display: flex;flex-direction: column;align-items: center;}
  .main .title{width:400px;border-bottom:1px solid #000000;text-align: center;padding:10px 0;margin-bottom:20px;}
  .main .title span{font-size:24px;}
  .main .flex{display: flex;margin:30px 0 50px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex2{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex3{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex4{display: flex;align-items: flex-start;justify-content: center;width:80%;flex-direction: column;height:200px;}
  .main .flex img{flex-shrink: 0;}
  .main .flex p{width:100%;line-height:1.6;font-size:18px;}
  .main .remark{width:60%;margin:30px 0 50px 0;font-size:18px;}
  .main .btn{display: flex;justify-content: flex-end;width:100%;}
  .main .btn a{position: absolute;margin-top:-70px;border:1px solid #01388B;padding:6px 40px 6px 10px;background-image: url(../assets/img/point_right_act.png);background-position: center right 10px;background-repeat: no-repeat;}
  .main .btn a:hover{background-color: #01388B;color:#ffffff;background-image: url(../assets/img/point_right.png);}
  .main .table{background: rgba(136, 136, 136, 0.1);display: flex;flex-direction: column;width:100%;margin:0 10px;padding-bottom:30px;align-items: center;}
  .main .table p{background: rgba(1, 56, 139, 0.7);margin-bottom:20px;border-radius: 10px 10px 0 0;width:100%;display: flex;align-items: center;justify-content: center;color:#ffffff;padding:10px 0;letter-spacing: 20px;}
  .main .table span{display: flex;align-items: center;justify-content: center;margin:10px 0;width:100%;}
  .main .table span a{color: #0020C9;border-bottom:1px solid #0020C9;}
  .main .flex6{display: flex;width:100%;margin:30px 0;}
  .main .flex2{display: flex;flex-direction: column;width:100%;border-radius: 10px; overflow: hidden;}
  .main .flex2 span{display: flex;width:100%;}
  .main .flex2 span:nth-child(1) label{background-color: #4D74AE !important;color:#ffffff;}
  .main .flex2 span:nth-child(2n+1) label{background-color:#F3F3F3;}
  .main .flex2 span label:nth-child(1){width:200px;flex-shrink: 0;margin-right:5px;}
  .main .flex2 span label:nth-child(2){width:100%;}
  .main .flex2 span label{padding:10px 0;padding-left:40px;}
  .main h3{font-size:20px;margin-bottom:10px;}
  .main .flex3 span{width:120px;height:60px;flex-shrink: 0;font-size:30px;font-weight: bold;display: flex;align-items: center;justify-content: center;}
  .main .flex3 p{width:100%;font-size:18px;}
  .main .flex4 span{justify-content: flex-start;width:100%;}
  .remark1{width:90%;background:linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, #FEF0C7 105.97%);border-radius: 10px;padding:20px 30px;font-size: 20px;line-height:2;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{margin:0 20px;padding:10px 0 !important;}
    .img{width:100%;}
    .main .title{width:180px;margin-bottom:10px;}
    .main .title span{font-size:18px;}
    .main .flex p{line-height:1.6;font-size:14px !important;}
    .main .remark{width:100%;margin:20px 0;font-size:14px !important;}
    .main .flex2 span label:nth-child(1){width:100px;margin-right:2px;}
    .main .flex2 span label{padding-left:10px;font-size:12px;}
    .main .flex3{margin:20px 0;width:100%;}
    .main .flex4{margin:0 0;flex-direction: column;width:80%;height:auto;}
    .main .flex6{flex-direction: column;}
    .main .table{width:100%;margin:0 !important;margin-bottom:20px !important;}
    .main .table span{font-size:14px;}
    .main h3{font-size:12px;margin-bottom:10px;letter-spacing:-1px;}
    .main .flex3 span{width:20px;height:20px;font-size:18px;margin-right: 20px;}
    .main .flex3 p{width:100%;font-size:13px;}
    .bg{position: absolute;z-index: -1;width:100vw;left:0;opacity: 0.2;display: flex;align-items: center;justify-content: center;}
    .remark1{width:100%;font-size:16px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .remark1{width:100%;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
