import Vue from 'vue'
import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  baseURL: 'https://api.cloudmersive.com',
  timeout: 5 * 60 * 1000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    Notification.error({title: '온누리국제영업비밀보호센터 알림', message: error.message })
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    config.headers['Apikey'] = '8e36bab0-dbe2-47d3-8be4-7c3884d40ef2'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
