<template>
  <div class="content">
    <div class="main">

      <div class="title">
        <span>영업비밀의 정의</span>
      </div>
      <div class="btn pc" v-if="false">
        <a href="/upload">신청 바로가기</a>
      </div>

      <div class="flex about1" :class="showDH1 ? 'slide-in' : ''">
        <div class="bg mobile">
          <img src="../assets/img/about3.png" style="width:60%;margin-top:100px;"/>
        </div>
        <p>
          2022년 한 국가기관의 조사에 의하면 지난 5년간 우리나라 기업체가 해외기술유출로 입은 피해가 22조에 달한다고 합니다. 또한 전국경제인연합회에서 의뢰한 전문가 집단에서는 한 해의 영업비밀유출로 인한 피해액이 국내외 합쳐 50-60조에 달할 것이라고 추정하였습니다.<br/><br/>
          이에 대응하기 위한 작은 노력의 일환으로, 온누리국제영업비밀보호센터에서는 영업비밀의 국제적이고 객관적인 증명에 만전을 기하고 있습니다.<br/><br/>
          영업비밀보호에 관한 법률과
           <!--특허청 지침에서 요구하는 원본증명서비스기관이 갖추어야 할 시스템 혹은 그 이상의 시스템을 갖추었으며,-->
            특히 해외에서 영업비밀 유출에 대한 분쟁이 생겼을 경우 법원에 증빙서류로 제출하기에 부족함이 없도록 미국변호사의 감독하에 철저한 관리절차를 구축하였다고 자부합니다. 영업비밀은 의뢰인의 컴퓨터에서 바로 암호화되어 송부되며, 온누리국제영업비밀보호센터에서 그 내용은 전혀 볼 수 없게 체계화되어 있습니다.<br/><br/>

          보호가 필요한 주요 영업비밀에 대해 말씀드리겠습니다.
        </p>
        <img src="../assets/img/about3.png" class="pc" style="width:27%;margin-left:30px;"/>
      </div>
      <img src="../assets/img2/cert_bf.png" class="mobile" style="width:50%;"/>
      <div class="flex about2" :class="showDH2 ? 'slide-in' : ''">
        <img src="../assets/img2/cert_bf.png" class="pc" style="width:30%;margin-right:30px;"/>
        <p>
            세계 각국은 자국시장에 제품을 판매하는 기업들에게 자국에서 제품을 생산하도록 요구하는 추세이며, 이에 따라 인건비 등 제반 비용을 고려하여 그간 본국에 생산기지를 배치하던 방식에서 제품을 판매하는 나라, 특히 세계최대시장인 미국시장에 생산기지를 배치하는 현상이 생기고 있어, 해외 현지에 생산공장을 건설할 경우 '생산공정' 관련기밀 유출 대응이 더욱 중요해지고 있습니다. 생산공정은 그 자체가 영업비밀이며, 특허화하거나 공개해서 보호하기 쉽지 않은 특징을 가지고 있습니다.<br/><br/>
            또한 해외에서 공장을 건설할 경우 현지 직원에게 모든 노하우를 전수하여야 하는데, 대부분의 해외시장은 이직이 대단히 자유롭고 빈번한 편입니다. 따라서 해외공장 건설 전에 우리나라에서 이미 이러한 공정노하우를 가지고 있음을 법적으로 인정받을 수 있는 방법을 준비해놓는 것이 대단히 중요해졌습니다. 가장 최근의 국내 L사와 S사의 미국영업비밀분쟁에서도 관건은 공정기술이었다는 점을 다시 한 번 말씀드리고 싶습니다.<br/><br/>
            경영정보, 인사정보 등 일반적인 지식재산권으로 보호받기 어려운 모든 무형재산권은 오로지 영업비밀로 보호할 수 있습니다.<br/><br/>
            모든 종류의 선사용/선사업 증거, 컨설팅 노하우, 고객정보, 영업전략 등은 객관적으로 증명하기 어려운 까닭에 제대로 보호받지 못했던 영업비밀들입니다. 이러한 내용을 미리 비밀을 유지하면서 소유권을 등록해 놓을 경우, 직원 이직이나 고객의 영업비밀 유출 등 다른 이유로 인해 생기는 분쟁시에 훨씬 더 효율적으로 신뢰받는 증거를 제시할 수 있을 것입니다. 특히 국내영업비밀분쟁의 경우, 특허청이 승인하는 원본증명기관에서 증빙할 경우 우선적으로 영업비밀로 인정하는 '추정효'를 획득하게 되니 참고하셨으면 합니다.
        </p>
      </div>

      <div class="flex about3" :class="showDH3 ? 'slide-in' : ''">
        <div class="bg mobile">
          <img src="../assets/img/about5.png" style="width:60%;"/>
        </div>
        <p>
            자체적으로 개발한 면접 방법, 면접 질문, 사내 평가, 세금/보험/보안 등 각종 인사 총무 자료들도 이제 적극적으로 보호할 때입니다. 외부로 유출되었을시 위험한 모든 종류의 기밀들은 객관적인 소유권 증빙으로 분쟁시에 효과적으로 대비할 수 있습니다. <br/><br/>
            30년 보관이 원칙인 연구노트를 안전하게 정리해놓을 곳을 찾고 계셨다면, 암호화된 영업비밀로 등록해놓는 것이 좋다는 점을 말씀드리지 않을 수가 없네요. 세계최대시장인 미국에서 아직도 2012.3.16. 이전 출원은 선발명주의를 채택하고 있고, 우리나라에서도 선사용의 강력한 증빙이 될 수 있는 것이 연구노트이니까요.<br/><br/>
            4차산업의 가장 주요한 결과물인 소프트웨어프로그램의 저작권으로 온전히 보호받지 못하는 부분, 특히 알고리즘은 영업비밀로 관리하여 보호하는 것이 좋습니다. 저작권이나 특허로 보호받을 수 있는 부분도, 영업비밀이라는 추가 보호수단이 생긴다는 것은 마음 든든한 일이라 할 수 있겠지요<br/><br/>
            디자인, 설계, 상표 등의 후보안들도 중요 영업비밀 관리 대상입니다. 수많은 국가에 많은 후보안들을 모두 디자인, 저작권, 상표 등으로 각각 등록하는 것은 현실적으로 어려운 반면, 영업비밀은 한꺼번에 등록해놓음으로써 내부인력자가 세계 어느 경쟁사로 이직해서 후보안들을 사용하더라고 제재할 방안이 생기게 됩니다.<br/><br/>
            따라서 해외수출기업은 소중한 영업비밀을 해외에 이송하기 전에 반드시 미리 철저한 관리를 통해 해외 법적 분쟁에 대비하시기 바랍니다. 그 첫걸음이 영업비밀의 객관적 소유권에 대한 국제적 증빙을 준비하는 것입니다. 온누리국제영업비밀보호센터가 함께하겠습니다.<br/><br/>
            감사합니다.
        </p>
        <img src="../assets/img/about5.png" class="pc" style="width:28%;margin-left:30px;"/>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',
    components: {
    },
    data() {
      return {
        showDH1:false,
        showDH2:false,
        showDH3:false,
        url: {

        }
      }
    },
    computed: {

    },
    created(){
      const that = this;
      setTimeout(function(){
        that.showDH1 = true;
        that.showDH2 = true;
      },100)
    },
    mounted() {
      window.addEventListener("scroll", this.scrolling);
    },
    methods:{
      scrolling() {
        let current = document.documentElement.scrollTop || document.body.scrollTop;
        if(current >= 600) {
          this.showDH3 = true
        }
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}

  .about1{position:relative;left: 50px;transition: all 1s ease;opacity: 0;}
  .about2{position:relative;left: -50px;transition: all 1s ease;opacity: 0;}
  .about3{position:relative;left: 50px;transition: all 1s ease;opacity: 0;}
  .slide-in {left: 0;opacity: 1;}

  .main{display: flex;flex-direction: column;align-items: center;}
  .main .title{width:400px;border-bottom:1px solid #000000;text-align: center;padding:10px 0;margin-bottom:20px;}
  .main .title span{font-size:24px;}
  .main .flex{display: flex;margin:30px 0 50px 0;align-items: center;justify-content: space-between;width:100%;}
  .main .flex2{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex3{display: flex;margin:20px 0;align-items: center;justify-content: space-between;width:80%;}
  .main .flex4{display: flex;align-items: flex-start;justify-content: center;width:80%;flex-direction: column;height:200px;}
  .main .flex img{flex-shrink: 0;}
  .main .flex p{width:100%;line-height:1.6;font-size:18px;}
  .main .remark{width:60%;margin:30px 0 50px 0;font-size:18px;}
  .main .btn{display: flex;justify-content: flex-end;width:100%;}
  .main .btn a{position: absolute;margin-top:-70px;border:1px solid #01388B;padding:6px 40px 6px 10px;background-image: url(../assets/img/point_right_act.png);background-position: center right 10px;background-repeat: no-repeat;}
  .main .btn a:hover{background-color: #01388B;color:#ffffff;background-image: url(../assets/img/point_right.png);}
  .main .table{background: rgba(136, 136, 136, 0.1);display: flex;flex-direction: column;width:100%;margin:0 10px;padding-bottom:30px;align-items: center;}
  .main .table p{background: rgba(1, 56, 139, 0.7);margin-bottom:20px;border-radius: 10px 10px 0 0;width:100%;display: flex;align-items: center;justify-content: center;color:#ffffff;padding:10px 0;letter-spacing: 20px;}
  .main .table span{display: flex;align-items: center;justify-content: center;margin:10px 0;width:100%;}
  .main .table span a{color: #0020C9;border-bottom:1px solid #0020C9;}
  .main .flex6{display: flex;width:100%;margin:30px 0;}
  .main .flex2{display: flex;flex-direction: column;width:100%;border-radius: 10px; overflow: hidden;}
  .main .flex2 span{display: flex;width:100%;}
  .main .flex2 span:nth-child(1) label{background-color: #4D74AE !important;color:#ffffff;}
  .main .flex2 span:nth-child(2n+1) label{background-color:#F3F3F3;}
  .main .flex2 span label:nth-child(1){width:200px;flex-shrink: 0;margin-right:5px;}
  .main .flex2 span label:nth-child(2){width:100%;}
  .main .flex2 span label{padding:10px 0;padding-left:40px;}
  .main h3{font-size:20px;margin-bottom:10px;}
  .main .flex3 span{width:120px;height:60px;flex-shrink: 0;font-size:30px;font-weight: bold;display: flex;align-items: center;justify-content: center;}
  .main .flex3 p{width:100%;font-size:18px;}
  .main .flex4 span{justify-content: flex-start;width:100%;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{margin:0 20px;padding:10px 0 !important;}
    .img{width:100%;}
    .main .title{width:180px;margin-bottom:10px;}
    .main .title span{font-size:18px;}
    .main .flex p{line-height:1.6;font-size:14px !important;}
    .main .remark{width:100%;margin:20px 0;font-size:14px !important;}
    .main .flex2 span label:nth-child(1){width:100px;margin-right:2px;}
    .main .flex2 span label{padding-left:10px;font-size:12px;}
    .main .flex3{margin:20px 0;width:100%;}
    .main .flex4{margin:0 0;flex-direction: column;width:80%;height:auto;}
    .main .flex6{flex-direction: column;}
    .main .table{width:100%;margin:0 !important;margin-bottom:20px !important;}
    .main .table span{font-size:14px;}
    .main h3{font-size:12px;margin-bottom:10px;letter-spacing:-1px;}
    .main .flex3 span{width:20px;height:20px;font-size:18px;margin-right: 20px;}
    .main .flex3 p{width:100%;font-size:13px;}
    .bg{position: absolute;z-index: -1;width:100vw;left:0;opacity: 0.2;display: flex;align-items: center;justify-content: center;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
