import Vue from 'vue'
import Router from 'vue-router'
import Home from 'views/Home'
import Service from 'views/Service'
import Upload from 'views/Upload'
import ReUpload from 'views/ReUpload'
import About1 from 'views/About1'
import About2 from 'views/About2'
import About3 from 'views/About3'
import About4 from 'views/About4'
import AboutTest from 'views/AboutTest'
import Consulting from 'views/Consulting'
import ContactUs from 'views/ContactUs'
import Faq from 'views/Faq'
import HelpCenter from 'views/HelpCenter'
import Login from 'views/Login'
import Register from 'views/Register'
import FindId from 'views/FindId'
import FindPwd from 'views/FindPwd'
import UpdatePwd from 'views/UpdatePwd'
import ModifyEmail from 'views/ModifyEmail'
import ModifyMobile from 'views/ModifyMobile'
import RegisterIDCert from 'views/RegisterIDCert'
import MyService from 'views/MyService'
import MyFile from 'views/MyFile'
import MyCert from 'views/MyCert'
import MyTransaction from 'views/MyTransaction'
import Privacy from 'views/Privacy'
import TermsConditions from 'views/TermsConditions'
import Terms from 'views/Terms'
import ServiceBankMessage from 'views/ServiceBankMessage'
import MyInformation from 'views/MyInformation'
import MyInformationCert from 'views/MyInformationCert'
import MyServiceCert from 'views/MyServiceCert'
import PaySuccess from 'views/PaySuccess'
import PayFail from 'views/PayFail'
import Applycert from 'views/Applycert'
import ContactUsResult from 'views/ContactUsResult'
import Notice from 'views/Notice'
import NoticeDetail from 'views/NoticeDetail'


Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/service',
    component: Service
  },
  {
    path: '/upload',
    component: Upload
  },
  {
    path: '/reUpload',
    component: ReUpload
  },
  {
    path: '/about1',
    component: About1
  },
  {
    path: '/about2',
    component: About2
  },
  {
    path: '/about3',
    component: About3
  },
  {
    path: '/about4',
    component: About4
  },
  {
    path: '/aboutTest',
    component: AboutTest
  },
  {
    path: '/notice',
    name: 'notice',
    component: Notice
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: NoticeDetail
  },
  {
    path: '/consulting',
    component: Consulting
  },
  {
    path: '/contactUs',
    component: ContactUs
  },
  {
    path: '/contactUsResult',
    component: ContactUsResult
  },
  {
    path: '/faq',
    component: Faq
  },
  {
    path: '/helpCenter',
    component: HelpCenter
  },
  {
    path: '/login',
    name:'login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/findId',
    component: FindId
  },
  {
    path: '/findPwd',
    component: FindPwd
  },
  {
    path: '/updatePwd',
    component: UpdatePwd
  },
  {
    path: '/modifyEmail',
    component: ModifyEmail
  },
  {
    path: '/modifyMobile',
    component: ModifyMobile
  },
  {
    path: '/registerIDCert',
    component: RegisterIDCert
  },
  {
    path: '/myService',
    component: MyService
  },
  {
    path: '/myFile',
    component: MyFile
  },
  {
    path: '/myCert',
    component: MyCert
  },
  {
    path: '/myTransaction',
    component: MyTransaction
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/termsConditions',
    component: TermsConditions
  },
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/applycert',
    component: Applycert
  },
  {
    path: '/serviceBankMessage',
    name:'serviceBankMessage',
    component: ServiceBankMessage
  },
  {
    path: '/myInformation',
    name:'myInformation',
    component: MyInformation
  },
  {
    path: '/myInformationCert',
    name:'myInformationCert',
    component: MyInformationCert
  },
  {
    path: '/myServiceCert',
    name:'myServiceCert',
    component: MyServiceCert
  },
  {
    path: '/paySuccess',
    name:'paySuccess',
    component: PaySuccess
  },
  {
    path: '/payFail',
    name:'payFail',
    component: PayFail
  },
  {
    path: '/404',
    component: () => import('views/exception/404')
  },
  {
    path: '*',
    redirect: '/404',
  }
]
const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
})
export default router
