<template>
  <div class="home">
    <!-- <el-upload
      class="btn"
      ref="upload"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="changeFileList"
      :multiple="true"
      :show-file-list="false"
      :auto-upload="false">
      <div style="width:180px;display: block;line-height:40px;">Upload</div>
    </el-upload> -->
    <div class="annImgcontent" v-if="annShow && banners1.length > 0 && !userLogin">
      <div class="annImg">
        <swiper class="annSwiper" :options="swiperOptions2">
          <swiper-slide v-for="(item,index) in banners1" :key="index">
            <a :href="item.linkUrl" style="display: flex;"><img :src="imgQZ + '/sys/common/test/view/' + item.image"/></a>
          </swiper-slide>
          <div class="topfu swiper-button-prev swiper-button-white" slot="button-prev"></div>
          <div class="topfu swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
        <div style="height:50px;font-size: 20px;width:100%;padding:0 14px;background-color: #333435;display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;align-items: center;color:#ffffff;font-size: 21px;">
            <input type="checkbox" v-model="annChe" style="width:23px;height:23px;margin-right:6px;"/>
            오늘 하루 보지 않기
          </div>
          <a style="cursor: pointer;" @click="closeAnn">닫기</a>
        </div>
      </div>
    </div>
    <div class="annImgcontent" v-if="annShow && banners2.length > 0 && userLogin">
      <div class="annImg">
        <swiper class="annSwiper" :options="swiperOptions2">
          <swiper-slide v-for="(item,index) in banners2" :key="index">
            <a :href="item.linkUrl" style="display: flex;"><img :src="imgQZ + '/sys/common/test/view/' + item.image"/></a>
          </swiper-slide>
          <div class="topfu swiper-button-prev swiper-button-white" slot="button-prev"></div>
          <div class="topfu swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
        <div style="height:50px;font-size: 20px;width:100%;padding:0 14px;background-color: #333435;display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;align-items: center;color:#ffffff;font-size: 21px;">
            <input type="checkbox" v-model="annChe" style="width:23px;height:23px;margin-right:6px;"/>
            오늘 하루 보지 않기
          </div>
          <a style="cursor: pointer;" @click="closeAnn">닫기</a>
        </div>
      </div>
    </div>
    <div style="z-index:100;">
      <swiper :options="swiperOptions">
        <swiper-slide class="swiper">
          <img src="../assets/imgs/logo_large.png"/>
          <div :class="bshow1?'banner1_cj show':'banner1_cj'">
            <div class="pc">
              모든 기밀의 객관적 소유권 증명<br/>
              <!-- <br/>
              온누리국제영업비밀보호센터는 '부정경쟁방지 및 영업비밀보호에 관한 법률' 제9조의 3에 의거,<br/>
              원본증명기관으로 특허청장이 지정한 기관입니다. <br/>
              <br/> -->
              <br/>
              모든 종류의 기밀, 특히 수출기업 영업비밀의 객관적인 증빙으로 분쟁을 대비하기 위해 최적화되어있습니다.
            </div>
            <div class="mobile">
              모든 기밀의 객관적 소유권 증명<br/>
              <!-- <br/>
              온누리국제영업비밀보호센터는 '부정경쟁방지 및 영업비밀보호에 <br/>
              관한 법률' 제9조의 3에 의거, <br/>
              원본증명기관으로 특허청장이 지정한 기관입니다. <br/> -->
              <br/>
              모든 종류의 기밀, 특히 수출기업 영업비밀의 객관적인 증빙으로 분쟁을 대비하기 위해 최적화되어있습니다.
            </div>
            <span>서비스 소개</span>
            <a class="btn" @click="golink('about1')">바로가기</a>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="bannerleft">
      <div :class="bshow2?'banner2_cj show2':'banner2_cj'">
        <div class="content">
          <div>원본 보관 서비스</div>
          <p class="pc">
            온누리국제영업비밀보호센터에서는 영업비밀과 모든 종류의 기밀자료를 암호화하고<br/>
            보관시점 Time Stamping하여 보관/증빙해드리는 서비스를 제공하고 있습니다.<br/>
            우리나라 수출업체에서 매우 시급하게 필요한 서비스입니다.
          </p>
          <p class="mobile">
            온누리국제영업비밀보호센터에서는 영업비밀과<br/>
            모든 종류의 기밀자료를 암호화하고<br/>
            보관 시점 Time Stamping하여<br/>
            보관/증빙 서비스를 제공하고 있습니다.<br/>
            우리나라 수출업체에서 매우 시급하게 필요한 서비스입니다.
          </p>
          <a class="btn" @click="golinkUser('service')">바로가기</a>
        </div>
      </div>
    </div>
    <div class="bannerRight">
      <div :class="bshow3?'banner3_cj show3':'banner3_cj'">
        <div class="content">
          <div>원본 증명서 발급 </div>
          <p class="pc">
            기밀을 보관의뢰한 업체에서는 추후 분쟁 혹은 다른 이유로 필요시<br/>
            증명서 발급 서비스를 이용하실 수 있습니다.
          </p>
          <p class="mobile">
            기밀을 보관의뢰한 업체에서는<br/>
            추후 분쟁 혹은 다른 이유로 필요시<br/>
            증명서 발급 서비스를 이용하실 수 있습니다.<br/>
          </p>
          <a class="btn" @click="golinkUser('myServiceCert')">바로가기</a>
        </div>
      </div>
    </div>
    <div class="bannerleft2">
      <div :class="bshow4?'banner4_cj show4':'banner4_cj'">
        <div class="content">
          <div>컨설팅 서비스</div>
          <p class="pc">
            어떠한 종류의 기밀관리가 필요한지, 어떠한 사내 기밀을 이런 방식으로 관리해야 하는지,<br/>
            보관의뢰를 위해서는 어떠한 방식으로 파일을 만들어야 하는지,<br/>
             온누리국제영업비밀보호센터 전문가가 맞춤형 컨설팅 하여 드립니다.
          </p>
          <p class="mobile" style="letter-spacing:-1px;">
            어떠한 종류의 기밀관리가 필요한지,<br/>
            어떠한 사내 기밀을 이런 방식으로 관리해야 하는지,<br/>
            보관의뢰를 위해서는 어떠한 방식으로 파일을 만들어야 하는지,<br/>
            온누리국제영업비밀보호센터 전문가가 맞춤형 컨설팅 하여 드립니다.
          </p>
          <a class="btn" @click="golink('consulting')">바로가기</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { swiper, swiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  //const CryptoJS = require('crypto-js');
  import { Upload } from 'element-ui'
  export default {
    name: 'Home',
    components: {
      elUpload:Upload,
      swiper,
      swiperSlide
    },
    data() {
      return {
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        banners1:[],
        banners2:[],
        userLogin:false,
        annShow:false,
        annChe:false,
        swiperOptions: {
          loop: false,
          speed: 1000,
          autoplay: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        },
        swiperOptions2: {
          loop: false,
          delay: 3000,
          speed: 600,
          autoplay: true,
          navigation:{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        },
        bshow1: false,
        bshow2: false,
        bshow3: false,
        bshow4: false
      }
    },
    mounted() {
      window.addEventListener("scroll", this.scrolling);
    },
    computed: {

    },
    created(){
      const that = this;
      getAction('/user/userInformation/bannersList').then(res => {
        if (res.success) {
          const banners = res.result;
          for(let i = 0; i < banners.length; i++){
            if(banners[i].showType == 0)
              that.banners1.push(banners[i])
            else
              that.banners2.push(banners[i])
          }
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      })
      this.userLogin = Vue.ls.get(ACCESS_TOKEN)
      if(Vue.ls.get('annShow1'))
        this.annShow = false
      else
        this.annShow = true
      setTimeout(function(){
        that.bshow1 = true
        // that.bshow2 = true
        // that.bshow3 = true
        // that.bshow4 = true
      },100)
    },
    methods:{
      closeAnn(){
        if(this.annChe)
          Vue.ls.set('annShow1', true, 24 * 60 * 60 * 1000);
        this.annShow = false
      },
      scrolling() {
        let current = document.documentElement.scrollTop || document.body.scrollTop;
        if(current >= 400) {
          this.bshow2 = true
        }
        if(current >= 700) {
          this.bshow3 = true
        }
        if(current >= 1100) {
          this.bshow4 = true
        }
      },
      /* changeFileList(file,fileList){
        console.log(file.raw)
        var reader = new FileReader();
        reader.readAsArrayBuffer(file.raw);
        reader.onload = function(e) {
            var wordArray = CryptoJS.lib.WordArray.create(reader.result);
            var hash = CryptoJS.SHA256(wordArray).toString();
            console.log(hash);
        }
      }, */
      golink(item){
        window.location.href = item;
      },
      golinkUser(item){
        if(Vue.ls.get(ACCESS_TOKEN))
          window.location.href = item;
        else
          window.location.href = 'login';
      }
    }
  }
</script>

<style scoped>
  .annImgcontent{margin:auto;}
  .annImg{position: fixed;z-index:999;top:80px;display: flex;flex-direction: column;}
  .annImg img{height:calc(100vh - 80px - 20vh);width:calc((100vh - 80px - 20vh) * 500 / 650);}
  .annImg a{color:#ffffff;}
  .annImg a:hover{color:#6DA7FF;}
  .annImgcontent input[type=checkbox]:checked {background-color: red;}
  .topfu:hover{--swiper-navigation-color: #6DA7FF;}
  .topfu:hover{--swiper-navigation-color: #6DA7FF;}
  .swiper-button-disabled{display: none;}
  .annSwiper{width:calc((100vh - 80px - 20vh) * 500 / 650);}
  .swiper-slide{display:flex;}
  .topfu{top:calc(50% - 25px)}
  .swiper{background-size:cover;background-image: url(../assets/imgs/banner1.jpg);background-position: center;display: flex;align-items: center;flex-direction: column;justify-content: center;}
  .swiper div{font-size:24px;text-align: center;padding-top:30px;}
  .swiper span{font-size:24px;text-align: center;margin-top:70px;font-weight: bold;}
  .swiper .btn{width: 134px;cursor: pointer;height: 42px;border-radius: 5px;background-color:#01388B;color:#ffffff;display: flex;align-items: center;justify-content: center;font-weight: bold;margin-top:20px;}
  .bannerleft{background-image: url(../assets/imgs/home_img1.jpg);background-size: cover;background-position: center;}
  .bannerRight{background-image: url(../assets/imgs/home_img2.jpg);background-size: cover;background-position: center;}
  .bannerleft2{background-image: url(../assets/imgs/home_img3.jpg);background-size: cover;background-position: center;}
  .bannerleft .content div{font-size: 34px;color:#ffffff;padding-bottom:20px;font-weight: bold;}
  .bannerleft .content p{font-size: 20px;color:#ffffff;line-height:1.6;margin:20px 0;font-weight:bold;}
  .bannerleft .content .btn{width: 134px;height: 42px;cursor: pointer;border-radius: 5px;background-color:#ffffff;color:#01388B;display: flex;align-items: center;justify-content: center;font-weight: bold;}
  .bannerRight .content{align-items: flex-end;}
  .bannerRight .content div{font-size: 34px;color:#01388B;padding-bottom:20px;font-weight: bold;}
  .bannerRight .content p{font-size: 20px;color: #000000;line-height:1.6;margin:20px 0;text-align: right;font-weight:bold;}
  .bannerRight .content .btn{width: 134px;height: 42px;cursor: pointer;border-radius: 5px;background-color:#01388B;color:#ffffff;display: flex;align-items: center;justify-content: center;font-weight: bold;}
  .bannerleft2 .content div{font-size: 34px;color:#ffffff;padding-bottom:20px;font-weight: bold;}
  .bannerleft2 .content p{font-size: 20px;color:#ffffff;line-height:1.6;margin:20px 0;font-weight:bold;}
  .bannerleft2 .content .btn{width: 134px;height: 42px;cursor: pointer;border-radius: 5px;background-color:#ffffff;color:#01388B;display: flex;align-items: center;justify-content: center;font-weight: bold;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;justify-content: center;}
  a{text-decoration: none;color:inherit;}

  @media (max-width: 800px) {
    .annImg{top:60px;left:10vw;}
    .annImg img{height:calc(80vw * 650px / 500px);width:80vw;}
    .annSwiper{width:80vw;}
    .topfu{top:calc(50% - 25px)}
  	.pc{display: none !important;}
    .swiper{height:100vh;background-image: url(../assets/imgs/banner1_m.jpg) !important;}
    .swiper img{height:100px;}
    .swiper div{font-size: 14px;line-height: 1.8;margin-bottom:20px;}
    .swiper span{font-size:16px;}
    .swiper .btn{margin-top:10px;font-size:14px;width:110px;height:30px;}
    .content{height:90vh;align-items: center !important;}
    .content div{font-size:20px !important;}
    .content p{text-align: center !important;font-size:14px !important;margin:20px 0 100px 0 !important;line-height: 2 !important;}
    .bannerleft{background-image: url(../assets/imgs/img3_m.jpg);}
    .bannerRight{background-image: url(../assets/imgs/img2_m.jpg);}
    .bannerleft2{background-image: url(../assets/imgs/img1_m.jpg);}

    .banner1_cj {display: flex;align-items: center;justify-content: center;flex-direction: column;opacity: 0;transition: all 1s ease;position: relative;}
    .show {opacity: 1;margin-top:0;}
    .banner2_cj {opacity: 0;transition: all 1s ease;}
    .show2 {opacity: 1;margin-left:0;}
    .banner3_cj {opacity: 0;transition: all 1s ease;}
    .show3 {opacity: 1;margin-left:0;}
    .banner4_cj {opacity: 0;transition: all 1s ease;}
    .show4 {opacity: 1;margin-left:0;}

  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .annImgcontent{width:800px;}
    .content{width:800px;margin: auto;height:360px;}
    .swiper{height:calc(100vh - 80px);}
    .swiper img{height:150px;}
    .swiper div{font-size:20px !important;padding-top:30px;font-weight: bold;}
    .swiper span{font-size:20px !important;margin-top:70px;font-weight: bold;}
    .content div{font-size:24px !important;}
    .content p{font-size:17px !important;}
    .banner1_cj {display: flex;align-items: center;justify-content: center;flex-direction: column;opacity: 0;margin-top:200px;transition: all 1s ease;position: relative;}
    .show {opacity: 1;margin-top:0;}
    .banner2_cj {opacity: 0;transition: all 1s ease;margin-left:-150px;}
    .show2 {opacity: 1;margin-left:0;}
    .banner3_cj {opacity: 0;transition: all 1s ease;margin-left:150px;}
    .show3 {opacity: 1;margin-left:0;}
    .banner4_cj {opacity: 0;transition: all 1s ease;margin-left:-150px;}
    .show4 {opacity: 1;margin-left:0;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;margin: auto;height:460px;}
    .annImgcontent{width:1200px;}
    .swiper{height:calc(100vh - 80px);}
    .banner1_cj {display: flex;align-items: center;justify-content: center;flex-direction: column;opacity: 0;margin-top:200px;transition: all 1s ease;position: relative;}
    .show {opacity: 1;margin-top:0;}
    .banner2_cj {opacity: 0;transition: all 1s ease;margin-left:-200px;}
    .show2 {opacity: 1;margin-left:0;}
    .banner3_cj {opacity: 0;transition: all 1s ease;margin-left:200px;}
    .show3 {opacity: 1;margin-left:0;}
    .banner4_cj {opacity: 0;transition: all 1s ease;margin-left:-200px;}
    .show4 {opacity: 1;margin-left:0;}
  }
</style>
