<template>
  <div>
    <div class="top-imgad">
      <div class="div">
        <a>기관소식</a>
        <p>온누리가 여러분과 함께하고 싶은 소식 모음 입니다.</p>
        <p>공지, 관련기사, 홍보물 등을 게재합니다.</p>
      </div>
    </div>
    <div class="main content" v-loading="loading">
      <div class="zd-title">
        <div class="searchb">
          <div class="serachp">
              <input placeholder="검색어를 입력하세요" v-model="keyword" @keyup.enter="initDatasSearch">
              <img src="../assets/img2/search.png" @click="initDatasSearch"/>
          </div>
        </div>
      </div>
      <div class="list-m">
        <div>
          <div :class="list2.length == 0?'list pc borderd':'list pc'" v-for="(item,index) in list1" :key="'2'+item.noticeBoardId">
            <div class="icon">
              <img src="../assets/img2/campaign.png"/>공지
            </div>
            <div class="title" @click="goDetailPage(item.noticeBoardId)">
              {{item.title}}
            </div>
            <div class="time">
              {{item.createdDatetime}}
            </div>
            <div class="down" v-if="item.fileNumber > 0">
              <div></div>
              <img src="../assets/img2/file_icon.png" @click="goDetailPage(item.noticeBoardId)"/>
            </div>
            <div class="down" v-else>
            </div>
          </div>
          <div :class="list2.length == 0?'list mobile borderd':'list mobile'" v-for="(item,index) in list1" :key="'3'+item.noticeBoardId" @click="goDetailPage(item.noticeBoardId)">
            <div class="row">
                <div class="icon">
                  <img src="../assets/img2/campaign.png"/>공지
                </div>
                <div class="time">
                  {{item.createdDatetime}}
                </div>
            </div>
            <div class="row">
              <div class="title">
                {{item.title}}
              </div>
              <div class="down">
                <img src="../assets/img2/file_icon.png" v-if="item.fileNumber > 0"/>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="list-whitem mobile" v-for="(item,index) in list2" :key="'0'+item.noticeBoardId"  @click="goDetailPage(item.noticeBoardId)">
            <div class="row">
                <div class="icon">
                  <img src="../assets/img2/news_icon.png"/>소식
                </div>
                <div class="time">
                  {{item.createdDatetime}}
                </div>
            </div>
            <div class="row">
              <div class="title">
                {{item.title}}
              </div>
              <div class="down">
                <img src="../assets/img2/file_icon.png" v-if="item.fileNumber > 0"/>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="list-white pc"  v-for="(item,index) in list2" :key="'1'+item.noticeBoardId">
            <div class="icon">
              <img src="../assets/img2/news_icon.png"/>소식
            </div>
            <div class="title" @click="goDetailPage(item.noticeBoardId)">
              {{item.title}}
            </div>
            <div class="time">
              {{item.createdDatetime}}
            </div>
            <div class="down" v-if="item.fileNumber > 0">
              <div></div>
              <img src="../assets/img2/file_icon.png" @click="goDetailPage(item.noticeBoardId)"/>
            </div>
            <div class="down" v-else>
            </div>
          </div>
        </div>
        <div class="load-more" v-if="total > 10">
          <!-- <a v-if="hashServiceUsageIsMore" @click="loadMoreHashServiceUsageList()">+ 더보기</a> -->
          <pagination
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="change_page"
            :total="total">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getAction } from '@/network/manage'
  import { Pagination } from 'element-ui'
  export default {
    name: 'Notice',
    components: {
      Pagination
    },
    data() {
      return {
        loading: false,
        list1:[],
        list2:[],
        total:0,
        pageSize:10,
        page:1,
        hasmore:false,
        keyword: '',
        url: {
          list: "/notice/list"
        }
      }
    },
    watch: {
    　　
    },
    computed: {

    },
    created(){
      this.initDatas()
    },
    methods:{
      initDatasSearch(){
        this.list2 = [];
        this.page = 1;
        this.loadList2();
      },
      initDatas() {
        let param = {type:0, keywords: this.keyword,page:1,size:5}
        this.loading = true
        getAction(this.url.list, param).then(res => {
          if (res.success) {
            this.list1 = res.result.records;
          }
          this.loading = false
        })
        this.loadList2();
      },
      change_page(e){
        this.page = e;
        this.list2 = [];
        this.loadList2();
      },
      loadList2(){
        const that = this;
        let param = {type:1, keywords: this.keyword,page:this.page,size:10}
        getAction(this.url.list, param).then(res => {
          if (res.success) {
            that.list2 = that.list2.concat(res.result.records);
            that.total = res.result.total;
          }
        })
      },
      nextPage(){
        this.page = this.page + 1;
        this.loadList2();
      },
      goDetailPage(id){
        this.$router.push({
            name:'noticeDetail',
            query:{
              id:id
            }
        })
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{padding:0 0 80px 0;margin:auto;}
  .load-more{display: flex;justify-content: center;margin-top:20px;}
  .main{display: flex;flex-direction: column;}
  a{text-decoration: none;color:inherit;}
  .top-imgad{width:100%;font-size:26px;background-image: url(../assets/img2/notice_bg.png);background-size: cover;background-position: center center;}
  .top-imgad .div{height:450px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.20);}
  .top-imgad .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-imgad .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-imgad .div p:nth-child(3){margin-top:5px !important;}
  @media (max-width: 800px) {
    .top-imgad .div{height:50vw;}
    .top-imgad .div a{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;}
    .top-imgad .div p{font-size: 16px;letter-spacing: -0.6px;font-weight:blod;margin-top:10px;}
    .top-imgad .div p:nth-child(3){margin-top:0px !important;}
  	.pc{display: none !important;}
    .content{width:100%;padding:10px 20px 30px 20px;}
    .main{display: flex;flex-direction: column;margin-top:0;}

    .zd-title {position: sticky;top:60px;background-color: #ffffff;}
    .zd-title .titled {color: #3B5998;font-size: 20px;font-weight: bold;text-align: center;margin-top: 32px;}
    .zd-title .searchb {display: flex;align-items: center;justify-content: flex-end;margin:16px auto 16px auto;width:100%;}
    .zd-title .searchb .serachp {border-bottom: 1px #000000 solid;display: flex;align-items: center;justify-content: flex-end;padding: 0 0 2px 5px;}
    .zd-title .searchb .serachp input {width:120px;height:26px;border:none;font-size: 13px;}
    .zd-title .searchb .serachp img {width:24px;}

    .list-m {margin: 0 auto;width:100%;}

    .list-m .list {width:100%;background:#F5F5F5;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list .row {display: flex;align-items: center;justify-content: space-between;}
    .list-m .list .row .icon {background:#ffffff;display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 14px;border-radius: 20px;flex-shrink: 0;margin: 0 7px;width:64px;height:24px;justify-content:center;align-items: center;}
    .list-m .list .row .icon img {width:18px;margin-right:2px;}
    .list-m .list .row .title {font-weight: bold;font-size: 14px;width:80%;margin:10px 0px 0px 7px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list .row .time {margin: 0 7px;font-size: 12px;font-weight: bold;flex-shrink: 0;}
    .list-m .list .row .down {margin: 10px 7px 0 0;display: flex;align-items: center;flex-shrink: 0;}
    .list-m .list .row .down img {width:20px;}
    .list-m .borderd {border-bottom: 1px #C4C4C4 solid;}

    .list-m .list-whitem {width:100%;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list-whitem .row {display: flex;align-items: center;justify-content: space-between;}
    .list-m .list-whitem .row .icon {display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 14px;border-radius: 20px;flex-shrink: 0;margin: 0 7px;width:64px;height:24px;justify-content:center;align-items: center;}
    .list-m .list-whitem .row .icon img {width:18px;margin-right:2px;}
    .list-m .list-whitem .row .title {font-weight: bold;font-size: 14px;width:80%;margin:10px 0px 0px 7px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list-whitem .row .time {margin: 0 7px;font-size: 12px;font-weight: bold;flex-shrink: 0;}
    .list-m .list-whitem .row .down {margin: 10px 7px 0 0;flex-shrink: 0;}
    .list-m .list-whitem .row .down img {width:20px;}
    .list-m .list-whitem:last-child {border-bottom: 1px #C4C4C4 solid;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
    .zd-title {position: sticky;top:80px;background-color: #ffffff;margin-top:30px;}
    .zd-title .titled {color: #3B5998;font-size: 35px;font-weight: bold;text-align: center;margin-top: 42px;}
    .zd-title .searchb {display: flex;align-items: center;justify-content: flex-end;margin:16px auto 16px auto;width:750px;}
    .zd-title .searchb .serachp {border-bottom: 1px #000000 solid;display: flex;align-items: center;justify-content: flex-end;padding: 0 0 5px 5px;}
    .zd-title .searchb .serachp input {width:150px;height:26px;border:none;font-size: 14px;}
    .zd-title .searchb .serachp img {width:24px;cursor: pointer;}
    .list-m {margin: 0 auto;}
    .list-m .list {display: flex;align-items: center;width:750px;background:#F5F5F5;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list .icon {background:#ffffff;display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 14px;padding: 6px 0;border-radius: 20px;flex-shrink: 0;width:74px;margin: 0 13px;}
    .list-m .list .icon1 {display: flex;align-items: center;justify-content: center;font-size: 14px;padding: 6px 0;border-radius: 20px;flex-shrink: 0;width:74px;margin: 0 13px;}
    .list-m .list .icon img {width:16px;margin-right:2px;}
    .list-m .list .title {font-weight: bold;font-size: 17px;width:480px;margin:0 13px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list .time {width:75px;margin: 0 13px;font-size: 14px;font-weight: bold;flex-shrink: 0;}
    .list-m .list .down {display: flex;align-items: center;justify-content: space-between;color: #C4C4C4;width:35px;flex-shrink: 0;}
    .list-m .list .down div {width:1px;height:14px;background-color: #C4C4C4;}
    .list-m .list .down img {width:24px;cursor: pointer;}
    .list-m .borderd {border-bottom: 1px #C4C4C4 solid;}

    .list-m .list-white {display: flex;align-items: center;width:750px;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list-white .icon {display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 14px;padding: 5px 0;border-radius: 20px;flex-shrink: 0;width:75px;margin: 0 13px;}
    .list-m .list-white .icon1 {display: flex;align-items: center;justify-content: center;font-size: 14px;padding: 6px 0;border-radius: 20px;flex-shrink: 0;width:75px;margin: 0 13px;}
    .list-m .list-white .icon img {width:16px;margin-right:2px;}
    .list-m .list-white .title {font-weight: bold;font-size: 17px;width:480px;margin:0 13px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list-white .time {width:75px;margin: 0 13px;font-size: 14px;font-weight: bold;flex-shrink: 0;}
    .list-m .list-white .down {display: flex;align-items: center;justify-content: space-between;color: #C4C4C4;width:35px;flex-shrink: 0;}
    .list-m .list-white .down div {width:1px;height:14px;background-color: #C4C4C4;}
    .list-m .list-white .down img {width:24px;cursor: pointer;}
    .list-m .list-white:last-child {border-bottom: 1px #C4C4C4 solid;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
    .zd-title {position: sticky;top:80px;background-color: #ffffff;margin-top:30px;}
    .zd-title .titled {color: #3B5998;font-size: 35px;font-weight: bold;text-align: center;margin-top: 42px;}
    .zd-title .searchb {display: flex;align-items: center;justify-content: flex-end;margin:16px auto 16px auto;width:915px;}
    .zd-title .searchb .serachp {border-bottom: 1px #000000 solid;display: flex;align-items: center;justify-content: flex-end;padding: 0 0 5px 5px;}
    .zd-title .searchb .serachp input {width:170px;height:26px;border:none;font-size: 15px;}
    .zd-title .searchb .serachp img {width:24px;cursor: pointer;}
    .list-m {margin: 0 auto;}
    .list-m .list {display: flex;align-items: center;width:915px;background:#F5F5F5;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list .icon {background:#ffffff;display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 16px;border-radius: 20px;flex-shrink: 0;width:90px;height:40px;margin: 0 16px;}
    .list-m .list .icon1 {display: flex;align-items: center;justify-content: center;font-size: 16px;border-radius: 20px;flex-shrink: 0;width:90px;height:40px;margin: 0 16px;}
    .list-m .list .icon img {width:20px;height:16px;margin-right:2px;}
    .list-m .list .title {font-weight: bold;font-size: 19px;width:585px;margin:0 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list .time {width:83px;margin: 0 16px;font-size: 16px;font-weight: bold;flex-shrink: 0;}
    .list-m .list .down {display: flex;align-items: center;justify-content: space-between;color: #C4C4C4;width:35px;flex-shrink: 0;}
    .list-m .list .down div {width:1px;height:14px;background-color: #C4C4C4;}
    .list-m .list .down img {width:24px;cursor: pointer;}
    .list-m .borderd {border-bottom: 1px #C4C4C4 solid;}

    .list-m .list-white {display: flex;align-items: center;width:915px;border-top:1px #C4C4C4 solid;padding: 10px 0;}
    .list-m .list-white .icon {display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 16px;border-radius: 20px;flex-shrink: 0;width:90px;height:40px;margin: 0 16px;}
    .list-m .list-white .icon1 {display: flex;align-items: center;justify-content: center;font-size: 16px;border-radius: 20px;flex-shrink: 0;width:90px;height:40px;margin: 0 16px;}
    .list-m .list-white .icon img {width:20px;height:16px;margin-right:2px;}
    .list-m .list-white .title {font-weight: bold;font-size: 19px;width:585px;margin:0 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;cursor: pointer;}
    .list-m .list-white .time {width:83px;margin: 0 16px;font-size: 16px;font-weight: bold;flex-shrink: 0;}
    .list-m .list-white .down {display: flex;align-items: center;justify-content: space-between;color: #C4C4C4;width:35px;flex-shrink: 0;}
    .list-m .list-white .down div {width:1px;height:14px;background-color: #C4C4C4;}
    .list-m .list-white .down img {width:24px;cursor: pointer;}
    .list-m .list-white:last-child {border-bottom: 1px #C4C4C4 solid;}
  }
</style>
