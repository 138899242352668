<template>
  <div class="body">
    <div style="display: none;">
      <div id="payment-method"></div>
    </div>
    <div class="windform" v-if="formwin1">
      <div class="conts">
        <div class="clos">
          <img @click="formwin1 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <h1>전자지문 보관</h1>
        <p>*  SHA-256 HASH 값 추출</p>
        <div class="div">
          의뢰하신 영업 비밀 파일의 전자 지문 값을 추출,<br/>
          해당 전자 지문을 센터에 보관하는 서비스 입니다.<br/>
          센터에 보관 중인 전자 지문 값과의 대조를 통해,<br/>
          소유하신 파일의 원본의 증명서를 발급합니다.
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin2">
      <div class="conts">
        <div class="clos">
          <img @click="formwin2 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <h1>비밀원본 보관</h1>
        <p>*  SHA-256 HASH 값 추출</p>
        <p>*  3중 보안 서버에 안전 보관</p>
        <div class="div">
          의뢰하신 영업 비밀 파일의 전자 지문 값을 추출,<br/>
          추출된 전자 지문값과 함께 원본 파일의 분실 위험없이<br/>
          온누리 보안 센터에 보관을 의뢰하는 서비스 입니다.<br/>
          보관하신 영업 비밀은 수시로 열람/다운로드가 가능하며,<br/>
          원본 증명서를 발급시 붙임물로써의 활용이 가능합니다.
        </div>
      </div>
    </div>
    <div class="top-img">
      <div class="zz content">
        <h1>온누리국제영업비밀보호센터의 차별화된 서비스를 경험하세요</h1>
        <h2>
          영업비밀의 안전한 보관부터 해외법원 제출용 원본증명서 발급까지<br/>
          가장 필요한 영업비밀 보호서비스를 제공합니다.
        </h2>
        <div class="btn" @click="golink('upload')">보관의뢰 하기</div>
      </div>
    </div>
    <div id="con1"></div>
    <div class="titleLine">영업비밀로 보호 가능한 대표적 정보</div>
    <div class="content" style="display: flex;flex-direction: column;align-items: center;">
      <img class="pc imgCon" style="margin:20px 0;" src="../assets/img2/service_info.png"/>
      <img class="mobile" style="width:94vw;" src="../assets/img2/service_info.png"/>
    </div>
    <div id="con2"></div>
    <div class="titleLine">영업 비밀 보관 이용 단가표</div>
    <div class="content" style="justify-content: center;align-items: center;display: flex;flex-direction: column;" v-if="plansList.length > 0">
      <div style="font-size:16px;">* 1개 파일 기준 센터 보관 및 증명서 발급(1회) 비용입니다.</div>
      <div class="infoList" style="background-color: rgba(217, 217, 217, 0.1);border-radius: 20px;padding:30px 20px;margin:20px 0;">
        <div class="mobile">
          <div style="display: flex;align-items: center;width: 100%;">
            <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:40%;display: flex;justify-content: center;align-items: center;">보관기간</span>
            <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:60%;display: flex;align-items: center;justify-content: center;">전자지문 보관<img @click="formwin1 = true" style="margin-left: 10px;cursor: pointer;" src="../assets/img2/wh.png"/></span>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;margin-top:10px;font-weight: bold;">
            <span style="width:40%;">3년</span>
            <span style="width:60%;">{{formatPrice(plansList[18].servicePrice + plansList[21].servicePrice)}} 원</span>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
            <span style="width:40%;">7년</span>
            <span style="width:60%;">{{formatPrice(plansList[19].servicePrice + plansList[21].servicePrice)}} 원</span>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
            <span style="width:40%;">10년</span>
            <span style="width:60%;">{{formatPrice(plansList[20].servicePrice + plansList[21].servicePrice)}} 원</span>
          </div>

          <div style="display: flex;align-items: center;width: 100%;margin:30px 0 20px 0;">
            <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:15%;display: flex;justify-content: center;align-items: center;">보관기간</span>
            <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:85%;display: flex;flex-direction: column;align-items: center;">
              <div style="display: flex;align-items: center;justify-content: center;">
                비밀원본 보관 (보관 용량)<img @click="formwin2 = true" style="margin-left: 10px;cursor: pointer;" src="../assets/img2/wh.png"/>
              </div>
              <div style="margin-top:5px;width:100%;display: flex;align-items: center;color:#000000;text-align: center;">
                <span style="width:25%;">10MB</span>
                <span style="width:25%;">100MB</span>
                <span style="width:25%;">500MB</span>
                <span style="width:25%;">1GB</span>
              </div>
            </span>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;margin-top:10px;font-weight: bold;">
            <span style="width:15%;">3년</span>
            <div style="display: flex;align-items: center;width:85%;">
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[0].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;letter-spacing:-1px;">{{formatPrice(plansList[1].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[2].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[3].servicePrice)}} 원</span>
            </div>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
            <span style="width:15%;">7년</span>
            <div style="display: flex;align-items: center;width:85%;">
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[6].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[7].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[8].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[9].servicePrice)}} 원</span>
            </div>
          </div>
          <div style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
            <span style="width:15%;">10년</span>
            <div style="display: flex;align-items: center;width:85%;">
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[12].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[13].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[14].servicePrice)}} 원</span>
              <span style="width:25%;letter-spacing:-1px;">{{formatPrice(plansList[15].servicePrice)}} 원</span>
            </div>
          </div>
        </div>
        <div style="display: flex;align-items: center;width: 100%;" class="pc">
          <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:20%;display: flex;justify-content: center;align-items: center;">보관기간</span>
          <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:30%;display: flex;align-items: center;justify-content: center;">전자지문 보관<img @click="formwin1 = true" style="margin-left: 10px;cursor: pointer;" src="../assets/img2/wh.png"/></span>
          <span style="color:rgba(1, 56, 139, 1);font-weight: bold;width:50%;display: flex;flex-direction: column;align-items: center;">
            <div style="display: flex;align-items: center;justify-content: center;">
              비밀원본 보관 (보관 용량)<img @click="formwin2 = true" style="margin-left: 10px;cursor: pointer;" src="../assets/img2/wh.png"/>
            </div>
            <div style="width:100%;display: flex;align-items: center;color:#000000;text-align: center;">
              <span style="width:25%;">10MB</span>
              <span style="width:25%;">100MB</span>
              <span style="width:25%;">500MB</span>
              <span style="width:25%;">1GB</span>
            </div>
          </span>
        </div>
        <div class="pc" style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;margin-top:10px;font-weight: bold;">
          <span style="width:20%;">3년</span>
          <span style="width:30%;">{{formatPrice(plansList[18].servicePrice + plansList[21].servicePrice)}} 원</span>
          <div style="display: flex;align-items: center;width:50%;">
            <span style="width:25%;">{{formatPrice(plansList[0].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[1].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[2].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[3].servicePrice)}} 원</span>
          </div>
        </div>
        <div class="pc" style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
          <span style="width:20%;">7년</span>
          <span style="width:30%;">{{formatPrice(plansList[19].servicePrice + plansList[21].servicePrice)}} 원</span>
          <div style="display: flex;align-items: center;width:50%;">
            <span style="width:25%;">{{formatPrice(plansList[6].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[7].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[8].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[9].servicePrice)}} 원</span>
          </div>
        </div>
        <div class="pc" style="display: flex;align-items: center;width: 100%;text-align: center;padding:10px 0;font-weight: bold;">
          <span style="width:20%;">10년</span>
          <span style="width:30%;">{{formatPrice(plansList[20].servicePrice + plansList[21].servicePrice)}} 원</span>
          <div style="display: flex;align-items: center;width:50%;">
            <span style="width:25%;">{{formatPrice(plansList[12].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[13].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[14].servicePrice)}} 원</span>
            <span style="width:25%;">{{formatPrice(plansList[15].servicePrice)}} 원</span>
          </div>
        </div>
      </div>
      <div id="con3"></div>
      <img class="conImg" src="../assets/img2/planImage1.png"/>
      <img class="conImg" src="../assets/img2/planImage2.png" style="margin:40px 0;"/>
    </div>
  </div>
</template>
<script>
  import { getAction, postAction } from '@/network/manage'
  import { getActionApi} from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  export default {
    name: 'Service',
    components: {

    },
    data() {
      return {
        formLoading:false,
        formwin1:false,
        formwin2:false,
        plansList:[],
        conType:0,
        windowConfirm: false,
        isChecked:false
      }
    },
    computed: {
      ...mapGetters([
        'token',
      ])
    },
    created(){
      const that = this;
      that.formLoading = true;
      getAction('/user/servicePlans/plansList').then(res => {
        if (res.success) {
          const plansList = res.result.plans;
          this.plansList = plansList;
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    mounted() {
      const that = this;
      setTimeout(function(){
        that.conType = that.$route.query.conType
        if(that.conType && that.conType != 0)
          window.scrollTo(0,document.getElementById("con"+that.conType).offsetTop - 100);
      },300);
    },
    methods:{
      golink(item){
        this.$router.push({path:item});
      },
      formatPrice(value){
        return new Intl.NumberFormat('en-US').format(value);
      }
    }
  }
</script>

<style scoped>
  .imgCon{width: 68%;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .top-img{width:100%;background-image: url(../assets/img2/serviceBanner.jpg);background-position: center;background-size: cover;}
  .top-img .zz{display: flex;flex-direction: row;padding:0 !important;color:#01388B;line-height:2;font-weight: bold;flex-direction: column;justify-content: center;}

  .top-img .btn{width:200px;height:40px;border-radius: 5px;color:#01388B;box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);cursor: pointer;background: #ffffff;display: flex;justify-content: center;align-items: center;font-size:18px;}
  .conImg{width:800px;}
  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius: 10px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;}
  .windform .conts h1{font-size:22px;font-weight: normal;margin:10px 0;}
  .windform .conts p{font-size:14px;}
  .windform .conts .div{color:rgba(121, 121, 121, 1);text-decoration: underline;margin:20px 40px;line-height: 2;font-size: 17px;}
  .fles{display: flex;}
  .fles span{line-height: 2;}
  .fles span:nth-child(1){width:140px;flex-shrink: 0;}
  .fles span:nth-child(2){width:100%;}
  .titleLine{color:#000000;font-size:24px;margin:20px auto;font-weight: bold;text-align: center;display: flex;flex-direction: column;align-items: center;}
  .titleLine:after{display: block;width:300px;background-color: #000;height:1px;content:'';margin-top:20px;}
  .flesTitle{color:rgba(1, 56, 139, 1);font-weight: bold;text-align: center;font-size:20px;margin:20px 0;}
  .flesTable{background:rgba(217, 217, 217, 0.1);border-radius: 10px;padding:5px 30px;display: flex;flex-direction: column;width:800px;}
  .content{margin:auto;}
  .head-title{font-family: 'Pretendard';font-size: 32px;letter-spacing: -1px;color: #01388B;text-align: center;margin-top:40px;letter-spacing: -1px;font-weight: bold;}
  .content .plans{width:31.6%;background-image:url(../assets/img/service_bg.png);background-size:cover;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));}
  .content .plans:hover{background-image:url(../assets/img/service_bg_act.png);}
  .content .plans:hover .detail{border-top: 1px solid #063377;color:#ffffff;}
  .content .plans:hover .detail .btn{color:#01388B !important;background-color: #ffffff;}
  .content .plans:hover .detail .remark{color:#ffffff;}
  .content .plans:hover .detail .checkbox{border:1px solid #ffffff !important;}
  .content .plans:hover .detail .act{background-image: url(../assets/img/check_bg_act.png) !important;}
  .content .plans .title{width:98px;height:98px;border-radius: 50%;background-color: #ffffff;margin:auto;box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);display: flex;justify-content: center;align-items: center;flex-direction: column;}
  .content .plans .title .top{color: #01388B;}
  .content .plans .title .top a{font-size: 40px;line-height:1;}
  .content .plans .title p{color: #888888;font-size:12px;margin-bottom:5px;}
  .content .plans .detail{border-top: 1px solid #E1E1E1;margin:50px 6px 6px 6px;}
  .content .plans .detail .tils{text-align: center;font-family: 'SF UI Display';font-size: 20px;font-weight: bold;margin:20px 0;}
  .content .plans .detail .list{width:60%;margin:auto;margin:14px auto;font-size:15px;display: flex;align-items: center;}
  .content .plans .detail .list .checkbox{margin-right:10px;width:20px;height:20px;border:1px solid #01388B;cursor: pointer;}
  .content .plans .detail .list .act{background-image: url(../assets/img/check_bg.png);background-repeat: no-repeat;background-position: center;}
  .content .plans .detail .remark{text-align: center;color: #454545;font-family: 'SF UI Display';font-size: 12px;letter-spacing: 0px;margin-top:20px;}
  .content .plans .detail .btn{width: 140px;cursor: pointer;height: 30px;margin:auto;font-size: 12px;line-height:1;margin-top:20px;color:#ffffff;background: #01388B;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  .window-confirm{position: fixed;z-index: 1001;width:100vw;height:100vh;left:0;top:0;background-color: rgba(255,255,255,0.8);display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;border: 2px solid #888888;border-radius: 15px;width: 760px;height: 600px;padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .remark{font-size: 18px;list-style:1;}
  .window-confirm .window .promotion{font-family: 'SF UI Display';font-size: 12px;color: #1296DB;letter-spacing:0px;}
  .window-confirm .window .radio-div{display: flex;width:220px;justify-content: space-between;}
  .window-confirm .window .radio-div label{display: flex;align-items: center;line-height: 1;font-size:13px;cursor: pointer;}
  .window-confirm .window .radio-div input{margin-right:10px;width:18px;height:18px;}
  .window-confirm .window .price{font-size: 16px;line-height:1;}
  .window-confirm .window .price a{font-size: 16px;color: #01388B;line-height:1;}
  .window-confirm .window .confirm{font-size: 16px;letter-spacing: -0.6px;line-height:1;color:#4A4A4A;display: flex;align-items: center;}
  .window-confirm .window .confirm input{width:20px;height:20px;margin-right:6px;}
  .window-confirm .window .confirm a{color: #1296DB;cursor: pointer;}
  .window-confirm .window .btn{width: 90px;height: 36px;background: #797979;border-radius: 10px;color:#ffffff;display: flex;justify-content: center;align-items: center;font-size: 15px;font-family: 'Pretendard';letter-spacing: 0;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .note{font-size: 16px;line-height: 1;}
  .window-confirm .window .a{cursor: pointer;font-size: 16px;line-height: 1;color:#1296DB;margin-bottom:20px;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .infoList{width:80%;font-size: 16px;}
  a{text-decoration: none;color:inherit;}
  .jtDiv{display: flex;flex-direction: column;align-items: center;color:#01388B;height:130px;font-size:20px;}
  .jtDiv img{width:50px;margin-top:10px;animation: dh 1s ease-out infinite alternate;}
  @keyframes dh
  {
      from {margin-top:10px;}
      to {margin-top:30px;}
  }
  @media (max-width: 800px) {
    .windform .conts{width:96%;}
    .windform .conts .div{margin:20px;font-size: 14px;}
  	.pc{display: none !important;}
    .top-img{width:100%;background-image: url(../assets/imgs/service_bg_m.jpg);}
    .home .banner{height:100vh;}
    .infoList{width:100%;font-size: 12px;}
    .content{width:100%;display: flex;flex-direction: column;align-items: center;padding:20px 0;}
    .head-title{display: flex;flex-direction: column;align-items: center;font-size:16px;}
    .head-title hr{background-color: #01388B;border:none;height:1px;width:30vw;margin-top:10px;}
    .remark-mobile{color: #01388B;font-size: 14px;line-height: 1;}
    .list-mobile{display: flex;margin:20px 0;width:80vw;justify-content: space-between;}
    .list-mobile .item{padding:2.4vw 0;border: 1px solid #C4C4C4;font-size:12px;line-height:1;border-radius: 3px;width:30%;display: flex;align-items: flex-end;justify-content: center;}
    .list-mobile .item a{font-size:20px;font-weight: bold;line-height:1;}
    .list-mobile .act{border: 1px solid #01388B;background-image: url(../assets/img/act_mobile.png);background-position: right bottom;background-repeat: no-repeat;}
    .remark{color: #C90000;margin-top:10px;font-weight: bold;font-size:14px;}
    .p1{font-size: 16px;margin-top:20px;}
    .p2{font-size: 16px;margin-top:10px;}
    .p2 a{color: #01388B;}
    .top-img .zz{height:50vw;font-size:15px;text-align: center;}
    .top-img .btn{width:160px;height:34px;font-size:12px;}
    .top-img h2{color:#ffffff;font-size:13px;}
    .jtDiv{height:90px;}
    .jtDiv img{width:30px;}
    .conImg{width:96%;}
    .fles span{font-size:12px;}
    .fles span:nth-child(1){width:80px;}
    .flesTitle{font-size:16px;margin:20px;}
    .flesTable{border-radius: 10px;padding:5px 10px;width:96vw;}
    .titleLine{font-size:16px;}
    .titleLine:after{width:200px;margin-top:10px;}
    .btn{background: #01388B;border-radius: 10px;color:#ffffff;font-family: 'Pretendard';font-size: 24px;letter-spacing: 0;height:48px;display: flex;justify-content: center;align-items: center;width:90vw;margin-top:30px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
    .imgCon{width: 80%;}
    .infoList{width:100%;font-size: 14px;}
  	.mobile{display: none !important;}
    .content{width:800px;}
    .content .plans{border-radius: 20px;padding:20px 0;width:32%;}
    .content .plans .title{width:80px;height:80px;}
    .content .plans .title .top a{font-size:34px;line-height:1;}
    .content .plans .detail{margin:20px 6px 6px 6px;}
    .content .plans .detail .list{width:84%;margin:auto;margin:10px auto;font-size:15px;}
    .head-title{font-size: 26px;}
    .top-img .topcon div{height:160px;font-size: 20px;}
    .xysImg1{display: none;}
    .top-img .zz{height:300px;font-size:20px;}
    .top-img h2{color:#ffffff;font-size:20px;margin:20px 0;}
    .conImg{width:700px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .content .plans{border-radius: 25px;padding:30px 0}
    .top-img .topcon div{height:230px;font-size:26px;}
    .xysImg2{display: none;}
    .top-img .zz{height:460px;font-size:26px;}
    .top-img h2{color:#ffffff;font-size:20px;margin:30px 0;}
  }
</style>
