<template>
  <div class="con" v-loading="formLoading">
    <div class="top-img">
      <div class="div">
        <a>마이페이지</a>
        <p>회원 정보 관리, 공동인증서 관리</p>
        <p>비밀 보관 현황, 원본증명서 신청, 결제내역, 환불처리</p>
      </div>
    </div>
    <div class="content">
      <div class="main pc">
        <div class="menu pc">
          <a @click="goPage('/myInformation')">회원 정보 관리</a>
          <a class="act">공동인증서 관리</a>
          <a @click="goPage('/myFile')">파일 보관 현황</a>
          <a @click="goPage('/myCert')">증명서 신청내역</a>
          <a @click="goPage('/myTransaction')">결제/환불</a>
        </div>
        <div class="list-div">
          <p style="font-size: 20px;">공동인증서 등록 정보</p>
          <table v-if="userCI != null && userCI.validTo != null && user != null && user.user_type == 1" border="0" style="width:550px;">
            <tr>
              <td style="height:60px;width:200px;"><b>회사명</b></td>
              <td style="color:#5F5F5F">{{userCI.business_name}}</td>
              <td style="width:140px;">&nbsp;</td>
            </tr>
            <tr>
              <td style="height:60px;width:160px;"><b>사업자등록번호</b></td>
              <td style="color:#5F5F5F">{{userCI.business_number | companyFilter}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td style="height:60px;width:160px;"><b>공동인증서 유효기한</b></td>
              <td style="color:#5F5F5F">{{formatDate()}}</td>
              <td style="padding-left:50px;letter-spacing: -0.6px;" v-if="userCI != null" @click="updateCertInfo"><a>인증서 갱신</a></td>
            </tr>
            <tr v-if="isGQ">
              <td colspan="3" style="color:#F46969;height:60px;">
                인증서가 만료 되었습니다 갱신 후 서비스 이용이 가능합니다.
              </td>
            </tr>
          </table>
          <table v-if="userCI != null && userCI.validTo != null && user != null && user.user_type == 0" border="0" style="width:550px;">
            <tr>
              <td style="height:60px;width:200px;"><b>성함</b></td>
              <td style="color:#5F5F5F">{{userCI.user_name}}</td>
              <td style="width:140px;">&nbsp;</td>
            </tr>
            <tr>
              <td style="height:60px;width:160px;"><b>주민등록번호</b></td>
              <td style="color:#5F5F5F">{{userCI.resident_number | personFilter}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td style="height:60px;width:160px;"><b>공동인증서 유효기한</b></td>
              <td style="color:#5F5F5F">{{formatDate()}}</td>
              <td style="padding-left:50px;letter-spacing: -0.6px;" v-if="userCI != null" @click="updateCertInfo"><a>인증서 갱신</a></td>
            </tr>
            <tr v-if="isGQ">
              <td colspan="3" style="color:#F46969;height:60px;">
                인증서가 만료 되었습니다 갱신 후 서비스 이용이 가능합니다.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getAction,postAction } from '@/network/manage'
  import { getActionApi} from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'MyInformation',
    components: {
    },
    data() {
      return {
        formLoading:false,
        user:null,
        userCI:null,
        isGQ:false,
        msg:'',
        validateResult:false,
        url: {
          user:'/login-management/user',
          certificate_information:'/dn-information/certificate-information'
        }
      }
    },
    computed: {

    },
    created(){
      this.loadUserCIInfo()
      this.loadUserInfo()
    },
    filters:{
      personFilter:function(value){
        if(value != null)
          return value.substring(0, value.length - 7) + '*******'
        else
          return ''
      },
      companyFilter:function(value){
        if(value != null)
          return value.substring(0, value.length - 5) + '*****'
        else
          return ''
      }
    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      formatDate(){
        let dateNow = new Date(this.userCI.validTo);
        return dateNow.getFullYear() + '년' + (dateNow.getMonth() + 1) + '월' + dateNow.getDate() + '일 까지'
      },
      loadUserInfo(){
        const that = this;
        getActionApi(this.url.user).then((res) => {
          if (res.code == 200) {
            that.user = res.data.contents[0];
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      loadUserCIInfo(){
        const that = this;
        getActionApi(this.url.certificate_information).then((res) => {
          if (res.code == 200) {
            that.userCI = res.data.contents[0];
            if(new Date().valueOf() > new Date(that.userCI.validTo).valueOf()){
              that.isGQ = true;
            }
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      updateCertInfo(){
        //调起证书
        const that = this;
        let name = this.userCI.user_type == 0 ? this.userCI.user_name : this.userCI.business_name
        let ssn = '';
        if(this.userCI.user_type == 0){
          ssn = this.userCI.resident_number.replaceAll('-','')
        }else{
          ssn = this.userCI.business_number.replaceAll('-','')
        }
        nxTSPKI.signData(name, {ssn:ssn}, function(res) {
          that.sign_complete_callback(res);
        });
      },
      sign_complete_callback(res) {
        this.msg = '';
        this.validateResult = false;
        const that = this;
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;

          //验证
          let signStr = param.subjectDN
          if(signStr.substring('(') != -1 && signStr.substring(')') != -1){
            signStr = signStr.substring(0,signStr.indexOf('(')) + signStr.substring(signStr.indexOf(')') + 1)
          }
          signStr = signStr.substring(3);
          if(this.user.user_type == 0 && signStr.indexOf('personal') == -1){
            this.$message.warning('등록된 정보와 인증서가 불일치 합니다. 확인 후 다시 시도하여 주세요');
            return
          }else if(this.user.user_type == 1 && signStr.indexOf('personal') != -1){
            this.$message.warning('등록된 정보와 인증서가 불일치 합니다. 확인 후 다시 시도하여 주세요');
            return
          }else{
            const nameStr = signStr.substring(0,signStr.indexOf(','));
            if(this.user.user_type == 0){
              if(!(this.userCI.user_name == nameStr || nameStr.indexOf(this.userCI.user_name) == 0)){
                this.$message.warning('등록된 정보와 인증서가 불일치 합니다. 확인 후 다시 시도하여 주세요');
                return
              }
            }else{
              if(!(this.userCI.business_name == nameStr || nameStr.indexOf(this.userCI.business_name) == 0)){
                this.$message.warning('등록된 정보와 인증서가 불일치 합니다. 확인 후 다시 시도하여 주세요');
                return
              }
            }
          }


          //保存签证信息
          that.formLoading = true;
          postAction('/user/userInformation/updateCertInfo',param).then((res) => {
            that.formLoading = false;
            if (res.success) {
              that.$message.success('인증서가 갱신 되었습니다');
              that.$router.go(0);
            } else {
              that.$message.warning(res.message);
            }
          }).catch(res => {
            that.$message.warning(res.message);
            that.formLoading = false;
          })
        }else {
          alert("error code = " + res.code + ", message = " + res.errorMessage);
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/mypage_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-img .div p:nth-child(3){margin-top:5px !important;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:0px 0 60px 0;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;padding:20px 0;color: #888888;cursor: pointer;font-weight: bold;letter-spacing: -1px;border-bottom:1px solid #000000;}
  .main .menu a:hover{color: #01388B;}
  .main .menu a:last-child{border-bottom-width: 0;}
  .main .menu .act{color: #01388B;}
  .main .list-div{width:100%;padding:71px 160px 0 160px;}
  .main .list-div input{border: 1px solid #C4C4C4;border-radius: 8px;width:360px;height:38px;padding:0 10px;font-size:14px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div div{margin:10px 0;}
  .main .list-div .flex{display: flex;margin:0 0;}
  .main .list-div .flex span{width:140px;color:#888888;margin:10px 0;}
  .main .list-div .cont p{font-size:20px;margin:40px 0 30px 0;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .con{min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .con{min-height:calc(100vh - 206px);}
  }
</style>
