<template>
  <div>
    <div class="main content" v-loading="loading">

      <div class="titled mobile">기관소식</div>

      <div class="db pc" >

        <div class="type" v-if="data.noticeBoardType == 0">
          <img src="../assets/img2/campaign.png"/>공지
        </div>
        <div class="type" v-if="data.noticeBoardType == 1">
          <img src="../assets/img2/news_icon.png"/>소식
        </div>
        <div class="title">
          {{data.title}}
        </div>
        <div class="time">
          {{data.createdDatetime}}
        </div>
      </div>


      <div class="db mobile">
        <div class="left">
          <div class="type" v-if="data.noticeBoardType == 0">
            <img src="../assets/img2/campaign.png"/>공지
          </div>
          <div class="type" v-if="data.noticeBoardType == 1">
            <img src="../assets/img2/news_icon.png"/>소식
          </div>
          <div class="time">{{data.createdDatetime}}</div>
        </div>
        <div class="title">{{data.title}}</div>
      </div>

      <div class="notice-content">
        <div v-html="data.contents"></div>
      </div>

      <div class="download">
        <div class="title">첨부파일</div>
        <div class="list" v-if="data.noticeBoardUploadFilesList!= null && data.noticeBoardUploadFilesList.length > 0">
          <div class="file" v-for="(item,index) in data.noticeBoardUploadFilesList" :key="index" @click="download(item.uploadFileLocation)">
            <img src="../assets/img2/file_icon.png"/>
            {{item.fileName}}
          </div>
        </div>
        <div class="list" style="width: 1px;" v-else>
        </div>
      </div>
      <div class="btn" @click="goPage">목록</div>
    </div>
  </div>
</template>
<script>
  import { getAction } from '@/network/manage'
  export default {
    name: 'NoticeDetail',
    components: {

    },
    data() {
      return {
        loading: false,
        data:{},
        url: {
          find: '/notice/detail/',
          download: '/sys/common/download'
        }
      }
    },
    watch: {
    　　'$route': function (to, from) {
          console.log(this.$route.query.id);
    　　}
    },
    computed: {

    },
    created(){
      if(this.$route.query.id) {
        const that = this;
        that.loading = true;
        getAction(this.url.find + this.$route.query.id).then(res => {
          if (res.success) {
            that.data = res.result;
          }
        }).finally(() => {
          that.loading = false;
        })
      }
    },
    methods:{
      goPage(){
        this.$router.push({
            name:'notice'
        })
      },
      download(url) {
        window.open(process.env.VUE_APP_API_URL_XA + this.url.download + '?path=' + url)
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{padding:0 0 80px 0;margin:auto;}
  .main{display: flex;flex-direction: column;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{width:100%;padding:10px 20px 30px 20px;}
    .main{display: flex;flex-direction: column;margin-top:0;}
    .titled {color: #3B5998;font-size: 20px;font-weight: bold;text-align: center;margin: 20px 0 20px 0;}

    .db {display: flex;align-items: flex-start;border-bottom: 1px #c4c4c4 solid;padding: 5px 10px;}
    .db .left {display: flex;flex-direction:column;justify-content: center;align-items: center;}
    .db .left .type {background:#FFFFFF;display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 14px;border-radius: 20px;flex-shrink: 0;width: 64px;height:24px;}
    .db .left .type img {width:18px;margin-right: 5px;}
    .db .left .time {font-size: 14px;font-weight: bold;margin-top:5px;}
    .db .title {font-size: 18px;font-weight: bold;margin-left: 15px;}
    .notice-content {width:100%;margin: 0 auto;padding: 15px 5px;min-height:40vh;overflow: auto;}
    .notice-content >>>*{max-width: 100%;}
    .notice-content >>>img{max-width: 100% !important;}
    .download {width:100%;margin: 0 auto;border-top: 1px #C4C4C4 solid;border-bottom: 1px #C4C4C4 solid;padding: 8px 8px 3px 8px;display: flex;align-items: flex-start;}
    .download .title {font-size: 13px;width:60px;flex-shrink: 0;}
    .download .list {display: flex;align-items: flex-start;flex-wrap:wrap;min-height:10vh;overflow: auto;}
    .download .list .file {display: flex;align-items: center;justify-content: center;font-size: 13px;margin: 0 15px 15px 0;}
    .download .list .file img {width:24px;}
    .btn {width:100%;height:30px;margin:20px auto;background:#01388B;color:#ffffff;text-align: center;line-height: 30px;font-size: 15px;border-radius: 10px;margin-top:20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}


    .db {display: flex;align-items: center;border-bottom: 1px #c4c4c4 solid;width:750px;margin: 85px auto 0 auto;padding: 0px 6px 8px 6px;justify-content: space-between;}
    .db .type {background:#FFFFFF;display: flex;align-items: center;justify-content: center;border:1px #C4C4C4 solid;font-size: 16px;border-radius: 20px;flex-shrink: 0;margin: 0 16px;width:90px;height:40px;}
    .db .type img {width:22px;margin-right: 5px;}
    .db .title {font-size: 19px;font-weight: bold;width:450px;margin: 0 4px;}
    .db .time {font-size: 16px;font-weight: bold;margin: 0 10px 0 0px;}
    .notice-content {width:917px;margin: 0 auto;padding: 15px 28px;min-height:300px;overflow: auto;}
    .notice-content >>>*{max-width: 100%;}
    .notice-content >>>img{max-width: 100% !important;}
    .download {width:750px;margin: 0 auto;border-top: 1px #C4C4C4 solid;border-bottom: 1px #C4C4C4 solid;padding: 18px 18px 3px 18px;display: flex;align-items: flex-start;}
    .download .title {font-size: 16px;width:80px;flex-shrink: 0;}
    .download .list {display: flex;align-items: flex-start;flex-wrap:wrap;min-height:80px;overflow: auto;}
    .download .list .file {display: flex;align-items: center;justify-content: center;font-size: 16px;margin: 0 15px 15px 0;cursor: pointer;}
    .download .list .file img {width:24px !important;}
    .btn {width:320px;height:40px;margin:30px auto;background:#01388B;color:#ffffff;text-align: center;line-height: 40px;font-size: 20px;border-radius: 35px;cursor: pointer;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}

    .db {display: flex;align-items: center;border-bottom: 1px #c4c4c4 solid;width:917px;margin: 85px auto 0 auto;padding: 0px 6px 8px 6px;justify-content: space-between;}
    .db .type {background:#FFFFFF;display: flex;align-items: center;border:1px #C4C4C4 solid;font-size: 16px;border-radius: 20px;flex-shrink: 0;margin: 0 16px;width:90px;height:40px;justify-content: center;}
    .db .type img {width:22px;margin-right: 5px;}
    .db .title {font-size: 20px;font-weight: bold;width:670px;margin: 0 4px;}
    .db .time {font-size: 16px;font-weight: bold;margin: 0 10px 0 0px;}
    .notice-content {width:917px;margin: 15px auto 0 auto;padding: 15px 28px;min-height:300px;overflow: auto;}
    .notice-content >>>*{max-width: 100%;}
    .notice-content >>>img{max-width: 100% !important;}
    .download {width:917px;margin: 0 auto;border-top: 1px #C4C4C4 solid;border-bottom: 1px #C4C4C4 solid;padding: 18px 18px 3px 18px;display: flex;align-items: flex-start;}
    .download .title {font-size: 16px;width:80px;flex-shrink: 0;}
    .download .list {display: flex;align-items: flex-start;flex-wrap:wrap;min-height:80px;overflow: auto;}
    .download .list .file {display: flex;align-items: center;justify-content: center;font-size: 16px;margin: 0 15px 15px 0;cursor: pointer;}
    .download .list .file img {width:24px;}
    .btn {width:400px;height:40px;margin:30px auto;background:#01388B;color:#ffffff;text-align: center;line-height: 40px;font-size: 20px;border-radius: 35px;cursor: pointer;}
  }
</style>
