import { render, staticRenderFns } from "./Applycert.vue?vue&type=template&id=1985e0b6&scoped=true"
import script from "./Applycert.vue?vue&type=script&lang=js"
export * from "./Applycert.vue?vue&type=script&lang=js"
import style0 from "./Applycert.vue?vue&type=style&index=0&id=1985e0b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1985e0b6",
  null
  
)

export default component.exports