<template>
  <div class="body" v-loading="formLoading">
    <div class="top-imgad">
      <div class="div">
        <a>문의하기</a>
        <p>온누리 고객 문의 페이지입니다.</p>
        <p>문의 사항을 보내시거나, 찾아오시는 길을 볼 수 있습니다.</p>
      </div>
    </div>
    <div class="content">
      <div id="con2"></div>
      <div class="title">Contact us</div>
      <div class="info">
        <div class="item">
          <span>성함</span>
          <input v-model="param.name" maxlength="20"/>
        </div>
        <div class="item">
          <span>회사</span>
          <input v-model="param.companyName" maxlength="50"/>
        </div>
        <div class="item">
          <span>이메일</span>
          <input v-model="param.email" maxlength="50"/>
        </div>
        <div class="item">
          <span>문의 제목</span>
          <input v-model="param.title" maxlength="50"/>
        </div>
        <div class="item">
          <span>문의 내용</span>
          <textarea v-model="param.message" maxlength="500"></textarea>
        </div>
        <div class="check">
          <input type="checkbox" @change="changeCheck"/>
          <a href="privacy" target="_blank">개인정보보호약관</a>에 동의 합니다.
        </div>
        <div :class="'btn '+(isCheck ? 'ok' : '')" @click="saveInfo">제출</div>
      </div>
    </div>
    <div id="con1"></div>
    <div class="top-img" style="background-color: #f7f7f7;" id="custmap"></div>
    <div class="address" style="margin-bottom: 60px;">address. <span>경기도 고양시 일산서구 강성로 139</span></div>
  </div>
</template>
<script>
  import { postAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ContactUs',
    components: {
    },
    data() {
      return {
        param:{name:'',companyName:'',email:'',title:'',message:''},
        conType:0,
        isCheck:false,
        formLoading:false,
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    mounted(){
      const that = this;
      setTimeout(function(){
        if(that.$route.query.conType)
          that.conType = that.$route.query.conType
        if(that.conType != 0)
          window.scrollTo(0,document.getElementById("con"+that.conType).offsetTop - 100);
      },300);
      this.loadMap();

    },
    methods:{

      loadMap(){
        var container = document.getElementById('custmap'); //지도를 담을 영역의 DOM 레퍼런스
        var options = { //지도를 생성할 때 필요한 기본 옵션
        	center: new kakao.maps.LatLng(37.6714390545028, 126.760240385267), //지도의 중심좌표.
        	level: 3 //지도의 레벨(확대, 축소 정도)
        };
        var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
        // 주소-좌표 변환 객체를 생성합니다
        var geocoder = new kakao.maps.services.Geocoder();
        // 주소로 좌표를 검색합니다
        geocoder.addressSearch('경기도 고양시 일산서구 강성로 139', function(result, status) {
            // 정상적으로 검색이 완료됐으면
           if (status === kakao.maps.services.Status.OK) {
              var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              // 결과값으로 받은 위치를 마커로 표시합니다
              var marker = new kakao.maps.Marker({
                  map: map,
                  position: coords
              });
              // 인포윈도우로 장소에 대한 설명을 표시합니다
              var infowindow = new kakao.maps.InfoWindow({
                  content: '<div style="width:220px;text-align:center;padding:6px 0;">온누리영업비밀보호센터</div>'
              });
              infowindow.open(map, marker);
              // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
              map.setCenter(coords);
            }
        });
      },
      changeCheck(e){
        this.isCheck = e.target.checked;
      },
      saveInfo(){
        const that = this;
        var re = new RegExp(this.emailValidate);
        if(that.isCheck){
          //验证
          if(that.param.name == ''){
            that.$message.error('성함을 입력하여 주세요');
          }else if(that.param.companyName == ''){
            that.$message.error('회사명을 입력하여 주세요');
          }else if(that.param.email == ''){
            that.$message.error('이메일을 입력하세요');
          }else if(!re.test(this.param.email)){
            that.$message.error('이메일 양식 오류 : 정확한 이메일을 입력하세요');
          }else if(that.param.title == ''){
            that.$message.error('문의 제목을 입력하여 주세요');
          }else if(that.param.message == ''){
            that.$message.error('문의 내용을 입력하여 주세요');
          }else{
            that.formLoading = true;
            postAction('/user/userInformation/contact_us',that.param).then(res => {
              if (res.success) {
                //that.$message.success('success!');
                that.param = {name:'',companyName:'',email:'',title:'',message:''}
                that.$router.push({path:'/contactUsResult'});
              } else {
                that.$message.error(res.message);
              }
            }).catch(err => {
              that.$message.warning(err.message);
            }).finally(() => {
              that.formLoading = false;
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-imgad{width:100%;font-size:26px;background-image: url(../assets/img2/contact_bg.png);background-size: cover;background-position: center center;}
  .top-imgad .div{height:450px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.20);}
  .top-imgad .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-imgad .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-imgad .div p:nth-child(3){margin-top:5px !important;}
  .body{text-align: center;}
  .top-img{width:970px;height:480px;margin:0 auto;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .address{font-family: 'Pretendard';font-size:16px;margin:10px 0;letter-spacing: -1px;}
  .title{font-size: 26px;letter-spacing: -1px;margin:30px 0;font-family: 'Pretendard';}
  .content .remark{font-family: 'SF UI Display';color: #4A4A4A;font-size:18px;font-weight: bold;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;}
  .content .info .item input{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:38px;padding:0 10px;}
  .content .info .item textarea{border: 1px solid #5F5F5F;border-radius: 8px;width:100%;height:100px;padding:10px;}
  .content .info .check{display: flex;align-items: center;font-size:14px;}
  .content .info .check a{color: #007AFF;}
  .content .info .check input{width:20px;height:20px;margin-right:10px;}
  .content .info .btn{width: 140px;height: 40px;margin:auto;line-height:1;margin-top:40px;color:#ffffff;background: #797979;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  .content .info .ok{background: #01388B;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .top-imgad .div{height:50vw;}
    .top-imgad .div a{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;}
    .top-imgad .div p{font-size: 16px;letter-spacing: -0.6px;font-weight:blod;margin-top:10px;}
    .top-imgad .div p:nth-child(3){margin-top:0px !important;}
  	.pc{display: none !important;}
    .top-img{width:100%;height:50vw;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
