<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="windform" v-if="formwin">
      <div class="conts">
        <div class="clos">
          <h1>공동인증서 등록</h1>
          <img @click="formwin = false" src="../assets/img2/closeBtn.png"/>
        </div>

        <div class="main">
          <p>인증서 유형</p>
          <div class="regType">
            <span @click="regType = 0" :class="regType == 0 ? 'act' : ''">개인</span>
            <span @click="regType = 1" :class="regType == 1 ? 'act' : ''">회사</span>
          </div>
          <p v-if="regType == 0">성함</p>
          <div v-if="regType == 0" class="flex">
            <input class="bord"  v-model="name" placeholder="성함을 입력하세요"/>
          </div>
          <p v-if="regType == 0">주민등록 번호</p>
          <div v-if="regType == 0" class="flex">
            <input class="bord input" v-model="resident_number1" ref="resident_number1" @input="resident1Change" style="width: 45%;" maxlength="6" placeholder="000000"/>
            <a>-</a>
            <input class="bord input" type="password" v-model="resident_number2" ref="resident_number2" @input="resident2Change" style="width: 55%;" maxlength="7" placeholder="0000000"/>
          </div>
          <p v-if="regType == 1">회사명</p>
          <div v-if="regType == 1" class="flex">
            <input class="bord" v-model="business_name" placeholder="회사명을 입력하세요"/>
          </div>
          <p v-if="regType == 1">사업자등록번호</p>
          <div v-if="regType == 1" class="flex">
            <input class="bord input" v-model="business_number1" ref="business_number1" @input="business1Change" style="width: 34%;" maxlength="3" placeholder="000"/>
            <a style="margin:0 5px;">-</a>
            <input class="bord input" v-model="business_number2" ref="business_number2" @input="business2Change" style="width: 24%;" maxlength="2" placeholder="00"/>
            <a style="margin:0 5px;">-</a>
            <input class="bord input" v-model="business_number3" ref="business_number3" @input="business3Change" style="width: 60%;" maxlength="5" placeholder="00000"/>
          </div>
          <p class="msg" v-if="!((regType == 0 && residentCode == 1) || (regType == 1 && businessCode == 1))">{{validateMessage[8]}}</p>
          <p class="msg" v-if="regType == 0 && residentCode == 1">주민등록 번호가 잘못 입력 되었습니다.</p>
          <p class="msg" v-if="regType == 1 && businessCode == 1">사업자 등록 번호가 잘못 입력 되었습니다.</p>
          <div class="btn" v-if="!((regType == 0 && residentCode == 2 && name.trim() != '') || (regType == 1 && businessCode == 2 && business_name.trim() != ''))">인증하기</div>
          <div class="btn actbtn" @click="regCertFunction" v-if="(regType == 0 && residentCode == 2 && name.trim() != '') || (regType == 1 && businessCode == 2 && business_name.trim() != '')">인증하기</div>
        </div>
      </div>
    </div>
    <div class="content pc">
      <div class="title">회원 가입</div>
      <div class="pc" style="color:#00C999;font-size:18px;">온누리국제영업비밀보호센터 회원 가입을 감사드립니다.</div>
      <div class="pc" style="color:#00C999;font-size:18px;">본 센터의 서비스 이용을 위해서 공동인증서 등록이 요구됩니다.</div>
      <div class="mobile title-remark">본사이트의 서비스는 PC환경에서 원할히 제공 됩니다.</div>
      <div class="info">
        <div class="item">
          <span>아이디<a style="color:#00C999;font-size:13px;margin-left:10px;">영문 혹은 영문/숫자 조합으로 4자 이상 12자 이하</a></span>
          <input v-model="param.userId" placeholder="아이디를 입력하세요" @change="validateUserId" maxlength="50"/>
        </div>
        <div class="msgInfo">{{validateMessage[0]}}</div>
        <div class="item">
          <span>비밀번호<a style="color:#00C999;font-size:13px;margin-left:10px;">영문대문자/영문소문자/숫자/특수문자 모두 포함 8자 이상</a></span>
          <input v-model="param.password" maxlength="50" @change="validatePassword" placeholder="비밀번호를 입력하세요" type="password"/>
        </div>
        <div class="msgInfo">{{validateMessage[1]}}</div>
        <div class="item">
          <span>비밀번호 확인</span>
          <input v-model="param.password2" @change="validatePass2" maxlength="50" placeholder="비밀번호를 다시 입력하세요" type="password"/>
        </div>
        <div class="msgInfo">{{validateMessage[2]}}</div>
        <div class="item">
          <span>이메일</span>
          <div class="box-item" :class="emailDisabled?'disabled':''">
            <input v-model="param.email" :disabled="emailDisabled" placeholder="이메일을 입력하세요" maxlength="50"/>
            <a style="color:#0020C9 !important;" v-if="!emailDisabled && emailTime == 120" @click="sendEmailCode" v-loading="emailLoading">인증번호 받기</a>
          </div>
        </div>
        <div class="msgInfo">{{validateMessage[3]}}</div>
        <div class="item">
          <span>인증코드</span>
          <div class="box-item" :class="emailDisabled?'disabled':''">
            <input v-model="param.email_verification" maxlength="6" @input="validateEmailCode" :disabled="emailDisabled" placeholder="6자리 코드를 입력하세요"/>
            <a style="color:#0020C9 !important;" v-if="!emailDisabled && emailTime != 120">{{emailTime}}</a>
            <a v-if="emailDisabled" style="color:#00C999 !important;cursor: default !important;">인증완료</a>
          </div>
        </div>
        <div class="msgInfo">{{validateMessage[4]}}</div>
        <div class="item">
          <span>휴대폰번호 (선택)</span>
          <div class="box-item" :class="mobileDisabled?'disabled':''">
            <input v-model="param.mobile" :disabled="mobileDisabled" placeholder="휴대폰 번호를 입력하세요" maxlength="11"/>
            <a style="color:#0020C9 !important;" v-if="!mobileDisabled && mobileTime == 120" @click="sendMobileCode" v-loading="mobileLoading">인증번호 받기</a>
          </div>
        </div>
        <div class="msgInfo">{{validateMessage[5]}}</div>
        <div class="item">
          <span>인증코드</span>
          <div class="box-item" :class="mobileDisabled?'disabled':''">
            <input v-model="param.mobile_verification" maxlength="6" @input="validateMobileCode" :disabled="mobileDisabled" placeholder="6자리 코드를 입력하세요"/>
            <a style="color:#0020C9 !important;" v-if="!mobileDisabled && mobileTime != 120">{{mobileTime}}</a>
            <a v-if="mobileDisabled" style="color:#00C999 !important;cursor: default !important;">인증완료</a>
          </div>
        </div>
        <div class="msgInfo">{{validateMessage[6]}}</div>
        <div class="pc" v-if="certId == null" style="margin: 30px auto 10px auto;display: flex;align-items: center;flex-direction: column;">
          <div @click="formwin = true" style="margin-bottom:10px;cursor: pointer;background-color: #00C999;color:#ffffff;width:273px;border-radius: 10px;display: flex;align-items:center;justify-content: center;height:80px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);font-size:24px;">
            <img src="../assets/img2/loginPlus.png" style="margin-right:10px;width:40px;height:40px;"/>공동인증서 등록
          </div>
          <a target="_blank" href="https://www.tradesign.net/ra/onnuriip" style="font-family:'Pretendard';margin-bottom:30px;font-size:18px;color:rgba(1, 56, 139, 1);text-decoration: underline;cursor: pointer;">공동인증서 발급하기</a>
        </div>
        <div class="pc" style="display: flex;margin: 30px auto;align-items: center;justify-content: center;font-size: 32px;color:#00C999;font-weight: bold;letter-spacing: -1px;" v-if="certId != null">
          인증서 등록 완료 <img style="height:40px;" src="../assets/img2/certSuccess.png"/>
        </div>
        <div>
          <div class="check-div">
            <input type="checkbox" v-model="check1" @change="checkboxChange1"/>
            <a href="privacy" target="_blank">개인정보 이용 약관</a>에 동의 합니다.
          </div>
          <div class="check-div">
            <input type="checkbox" v-model="check2" @change="checkboxChange2"/>
            <a href='terms' target="_blank">회원 이용 약관</a>에 동의 합니다.
          </div>
        </div>
        <div class="msgInfo" style="text-align: center;margin-top:10px;" v-if="validateMessage[7] != ''">{{validateMessage[7]}}</div>
        <div :class="'btn ' + (check1 && check2 && this.certId != null ? 'ok' : '')" @click="reg">가입 신청</div>
        <div class="remark pc">가입된 아이디가 있으신가요? <a @click="goPage('login')">로그인</a></div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,putActionApi } from '@/network/manageApi'
  import { getAction, postAction } from '@/network/manage'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Register',
    components: {
    },
    data() {
      return {
        check1:false,
        check2:false,
        name:'',
        resident_number1:'',
        resident_number2:'',
        business_name:'',
        business_number1:'',
        business_number2:'',
        business_number3:'',
        validateMessage:['','','','','','','','',''],
        residentCode:0,
        businessCode:0,
        emailDisabled:false,
        mobileDisabled:false,
        isZSValidate:false,
        certId:null,
        regType:0,
        emailTime:120,
        mobileTime:120,
        formwin:false,
        formLoading:false,
        emailLoading:false,
        mobileLoading:false,
        emailTimer:null,
        mobileTimer:null,
        emailCodeId:'',
        mobileCodeId:'',
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        param:{userId:'',password:'',password2:'',email:'',email_verification:'',mobile:'',mobile_verification:''},
        url: {
          emailCode:'verification/request-email-verification-code',
          mobileCode:'verification/request-sms-verification-code',
          emailValidate:'verification/email-verification',
          mobileValidate:'verification/sms-verification',
          reg:'login-management/signup1',
          sign_user:'/user/userInformation/user_sign',
          business_number:'/login-management/update-business-number',
          resident_number:'/login-management/update-resident-number',
        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      regCertFunction(){
        /* this.certId = 1;
        this.formwin = false;
        return; */
        const that = this;
        let name = this.regType == 0 ? this.name : this.business_name
        let ssn = '';
        if(this.regType == 0){
          ssn = this.resident_number1 + this.resident_number2
        }else{
          ssn = this.business_number1 + this.business_number2 + this.business_number3
        }
        nxTSPKI.signData(name, {ssn:ssn}, function(res) {
          that.sign_complete_callback(res);
        });
      },
      sign_complete_callback(res) {
        const that = this;
        that.$set(that.validateMessage,8,'')
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;
          //保存签证信息
          that.formLoading = true;
          postAction(this.url.sign_user, param).then(res => {
            if (res.success) {
              //认证成功
              that.certId = res.result.dn_uuid
              that.formwin = false;
            } else {
              that.$set(that.validateMessage,8,res.message)
            }
          }).catch(err => {
            that.$set(that.validateMessage,8,res.message)
          }).finally(() => {
            that.formLoading = false;
          })
        }else {
          that.$set(that.validateMessage,8,res.errorMessage)
        }
      },
      validateUserId(){
        const that = this
        this.$set(this.validateMessage,0,'')
        const userId = this.param.userId.trim();
        const reg = /^[a-zA-Z0-9]{4,12}$/
        if(!reg.test(userId)){
          this.$set(this.validateMessage,0,'영문 혹은 영문/숫자 조합으로 4자 이상 12자 이하');
          return;
        }
        getAction('/user/userInformation/validate_user_id',{user_id:userId}).then((res) => {
          if (!res.result) {
            that.$set(that.validateMessage,0,'이미 사용중인 아이디 입니다.')
          }
        }).catch(res => {
          that.$set(that.validateMessage,0,res.message)
        })
      },
      validatePassword(){
        const that = this
        this.$set(this.validateMessage,1,'')
        const password = this.param.password;
        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,}$/
        if(!reg.test(password)){
          this.$set(this.validateMessage,1,'영문대문자/영문소문자/숫자/특수문자 모두 포함 8자 이상');
        }
      },
      resident1Change(){
        this.validateResident()
        if(this.resident_number1.length == 6)
          this.$refs.resident_number2.focus()
      },
      resident2Change(){
        this.validateResident()
        if(this.resident_number2.length == 0)
          this.$refs.resident_number1.focus()
      },
      validateResident(){
        this.residentCode = 0
        if(!(this.resident_number1.length == 6 && this.resident_number2.length == 7 && !isNaN(this.resident_number1) && !isNaN(this.resident_number2) && this.resident_number1.indexOf('.') == -1 && this.resident_number2.indexOf('.') == -1))
          this.residentCode = 1
        else
          this.residentCode = 2
      },
      business1Change(){
        this.validateBusiness()
        if(this.business_number1.length == 3)
          this.$refs.business_number2.focus()
      },
      business2Change(){
        this.validateBusiness()
        if(this.business_number2.length == 2)
          this.$refs.business_number3.focus()
        if(this.business_number2.length == 0)
          this.$refs.business_number1.focus()
      },
      business3Change(){
        this.validateBusiness()
        if(this.business_number3.length == 0)
          this.$refs.business_number2.focus()
      },
      validateBusiness(){
        this.businessCode = 0
        if(!(this.business_number1.length == 3 && this.business_number2.length == 2 && this.business_number3.length == 5 && !isNaN(this.business_number1) && !isNaN(this.business_number2) && !isNaN(this.business_number3) && this.business_number1.indexOf('.') == -1 && this.business_number2.indexOf('.') == -1 && this.business_number3.indexOf('.') == -1))
          this.businessCode = 1
        else
          this.businessCode = 2
      },
      goPage(url){
        this.$router.push({path:url});
      },
      sendEmailCode(){
        const that = this;
        this.param.email = this.param.email.trim();
        var re = new RegExp(this.emailValidate);
        that.$set(that.validateMessage,3,'');
        if(this.param.email == '')
          that.$set(that.validateMessage,3,'이메일을 입력하세요');
        else if(!re.test(this.param.email))
          that.$set(that.validateMessage,3,'이메일 양식 오류 : 정확한 이메일을 입력하세요');
        else{
          this.emailLoading = true;
          //验证邮箱是否可用
          getAction('/user/userInformation/validate_email',{email:this.param.email}).then((res) => {
            if (!res.success) {
              that.$set(that.validateMessage,3,'다른 계정에서 사용중입니다.');
              that.emailLoading = false;
            } else {
              postActionApi(that.url.emailCode, {email:that.param.email}).then((res) => {
                if (res.code == 200) {
                  that.emailCodeId = res.data.codeId
                  //daojishi
                  that.emailTimer = setInterval(() =>{
                      that.emailTime = that.emailTime - 1;
                      that.emailLoading = false;
                      if(that.emailTime < 0){
                        clearInterval(that.emailTimer);
                        that.emailTime = 120;
                      }
                  },1000)
                } else {
                  that.$set(that.validateMessage,3,res.message);
                  that.emailLoading = false;
                }
              }).catch(res => {
                that.$set(that.validateMessage,3,res.message);
                that.emailLoading = false;
              })
            }
          }).catch(res => {
            that.$set(that.validateMessage,3,res.message);
            that.emailLoading = false;
          })
        }
      },
      validateEmailCode(){
        const that = this;
        that.$set(that.validateMessage,4,'');
        if(that.param.email_verification.length == 6 && that.param.email.length != 0){
          const param = {
            email:that.param.email,
            codeId:that.emailCodeId,
            verification_code:parseInt(that.param.email_verification)
          }
          postActionApi(that.url.emailValidate, param).then((res) => {
            if (res.code == 200) {
              that.$set(that.validateMessage,4,'');
              that.emailDisabled = true;
            } else {
              that.$set(that.validateMessage,4,res.message);
            }
          }).catch(res => {
            that.$set(that.validateMessage,4,res.message);
          })
        }
      },
      sendMobileCode(){
        const that = this;
        this.param.mobile = this.param.mobile.trim();
        that.$set(that.validateMessage,5,'');
        if(this.param.mobile == '')
          that.$set(that.validateMessage,5,'모바일 번호를 입력하세요');
        else if(this.param.mobile.length != 11)
          that.$set(that.validateMessage,5,'모바일 양식 오류 : 정확한 번호를 입력하세요');
        else{
          this.mobileLoading = true;

          getAction('/user/userInformation/validate_phone',{phone:this.param.mobile}).then((res) => {
            if (!res.success) {
              that.$set(that.validateMessage,5,'다른 계정에서 사용중입니다.');
              that.mobileLoading = false;
            } else {
              postActionApi(that.url.mobileCode, {phone_number:that.param.mobile}).then((res) => {
                if (res.code == 200) {
                  that.mobileCodeId = res.data.codeId
                  //daojishi
                  that.mobileTimer = setInterval(() =>{
                      that.mobileTime = that.mobileTime - 1;
                      that.mobileLoading = false;
                      if(that.mobileTime < 0){
                        clearInterval(that.mobileTimer);
                        that.mobileTime = 120;
                      }
                  },1000)
                } else {
                  that.$set(that.validateMessage,5,res.message);
                  that.mobileLoading = false;
                }
              }).catch(res => {
                that.$set(that.validateMessage,5,res.message);
                that.mobileLoading = false;
              })
            }
          }).catch(res => {
            that.$set(that.validateMessage,3,res.message);
            that.emailLoading = false;
          })
        }
      },
      validateMobileCode(){
        const that = this;
        that.$set(that.validateMessage,6,'');
        if(that.param.mobile_verification.length == 6 && that.param.mobile.length != 0){
          const param = {
            phone_number:that.param.mobile,
            codeId:that.mobileCodeId,
            verification_code:parseInt(that.param.mobile_verification)
          }
          postActionApi(that.url.mobileValidate, param).then((res) => {
            if (res.code == 200) {
              that.$set(that.validateMessage,6,'');
              that.mobileDisabled = true;
            } else {
              that.$set(that.validateMessage,6,res.message);
            }
          }).catch(res => {
            that.$set(that.validateMessage,6,res.message);
          })
        }
      },
      validatePass2(){
        const that = this;
        that.$set(that.validateMessage,2,'')
        that.param.password = that.param.password.trim();
        that.param.password2 = that.param.password2.trim();
        if(that.param.password2 != that.param.password)
          that.$set(that.validateMessage,2,'비밀번호가 일치하지 않습니다.')
      },
      reg(){
        const that = this;
        var re = new RegExp(this.emailValidate);
        if(that.check1 && that.check2){
          that.param.userId = that.param.userId.trim();
          that.param.password = that.param.password.trim();
          that.param.password2 = that.param.password2.trim();
          that.param.email = that.param.email.trim();
          that.param.email_verification = that.param.email_verification.trim();
          that.param.mobile = that.param.mobile.trim();
          that.param.mobile_verification = that.param.mobile_verification.trim();
          that.$set(that.validateMessage,0,'')
          if(that.param.userId == '')
            that.$set(that.validateMessage,0,'ID를 입력하여 주세요')
          let reg = /^[a-zA-Z0-9]{4,12}$/
          if(!reg.test(that.param.userId))
            that.$set(that.validateMessage,0,'4~12글자, 영어/숫자');
          that.$set(that.validateMessage,1,'')
          if(that.param.password == '')
            that.$set(that.validateMessage,1,'Password를 입력하여 주세요')
          reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,}$/
          if(!reg.test(that.param.password))
            that.$set(that.validateMessage,1,'8자이상, 숫자 영문 특수문자 포함');
          that.$set(that.validateMessage,2,'')
          if(that.param.password2 == '')
            that.$set(that.validateMessage,2,'Password를 확인 입력하여 주세요')
          else if(that.param.password2 != that.param.password)
            that.$set(that.validateMessage,2,'비밀번호가 일치하지 않습니다.')
          that.$set(that.validateMessage,3,'')
          if(that.param.email == '')
            that.$set(that.validateMessage,3,'이메일을 입력하세요')
          else if(!re.test(this.param.email))
            that.$set(that.validateMessage,3,'이메일 양식 오류 : 정확한 이메일을 입력하세요')
          that.$set(that.validateMessage,4,'')
          if(that.param.email_verification == '')
            that.$set(that.validateMessage,4,'이메일 인증 코드가 요구됩니다')
          else if(that.param.email_verification.length != 6)
            that.$set(that.validateMessage,4,'6자리 인증코드를 입력하세요')
          that.$set(that.validateMessage,5,'')
          that.$set(that.validateMessage,6,'')
          if(that.param.mobile != '' && this.param.mobile.length != 11)
            that.$set(that.validateMessage,5,'모바일 양식 오류 : 정확한 번호를 입력하세요')
          else if(that.param.mobile != '' && that.param.mobile_verification == '')
            that.$set(that.validateMessage,6,'모바일 인증 코드가 요구됩니다')
          else if(that.param.mobile != '' && that.param.mobile_verification.length != 6)
            that.$set(that.validateMessage,6,'6자리 인증코드를 입력하세요')

          //验证是否完成
          if(that.validateMessage[0] != '' || that.validateMessage[1] != '' || that.validateMessage[2] != '' || that.validateMessage[3] != '' ||
           that.validateMessage[4] != '' || that.validateMessage[5] != '' && that.validateMessage[6] != '')
           return;
          if(that.certId == null){
            that.$set(that.validateMessage,7,'인증서 등록 미완성')
            return;
          }
          if(that.emailCodeId == ''){
            that.$set(that.validateMessage,4,'인증번호가 일치하지 않습니다.')
            return;
          }
          if(that.param.mobile != '' && that.mobileCodeId == ''){
            that.$set(that.validateMessage,6,'인증번호가 일치하지 않습니다.')
            return;
          }
          //注册
          let params = {
            userId:that.param.userId,
            password:that.param.password,
            email:that.param.email,
            mobile:that.param.mobile != '' ? that.param.mobile : null,
            email_verification:that.emailCodeId,
            mobile_verification:that.param.mobile != '' ? that.mobileCodeId : null,
            dn_uuid:that.certId
          }
          if(that.regType == 0){
            params.sign_type = 'individual'
            params.name = that.name.trim()
            params.identity_number = that.resident_number1.trim() + '-' + that.resident_number2.trim()
          }else{
            params.sign_type = 'business'
            params.name = that.business_name.trim()
            params.identity_number = that.business_number1.trim() + that.business_number2.trim() + that.business_number3.trim()
          }
          that.$set(that.validateMessage,7,'')
          postActionApi(this.url.reg, params).then(res => {
            if (res.code == 200) {
              that.formLoading = true;
              postActionApi('/login-management/login',{userId:that.param.userId,password:that.param.password}).then((res) => {
                if (res.code == 200) {
                  //存储token
                  Vue.ls.set(ACCESS_TOKEN, res.data.token, 10 * 60 * 60 * 1000)
                  window.location.href='/'
                } else {
                  that.$set(that.validateMessage,7,res.message)
                }
              }).catch(res => {
                that.$set(that.validateMessage,7,res.message)
              }).finally(() => {
                that.formLoading = false;
              })
            } else {
              that.$set(that.validateMessage,7,res.message)
            }
          }).catch(err => {
            that.$set(that.validateMessage,7,err.message)
          }).finally(() => {
            that.formLoading = false;
          })
        }
      },
      checkboxChange1(e){
        this.check1 = e.target.checked;
      },
      checkboxChange2(e){
        this.check2 = e.target.checked;
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius: 10px;width:530px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content:space-between;align-items: center;padding:0 20px;}
  .windform .conts .clos img{width:40px;cursor: pointer;}
  .windform .conts .main{padding:20px 60px 40px 60px;display: flex;flex-direction: column;align-items: center;}
  .windform .conts h1{font-size:24px;font-weight: bold;margin:10px 0;text-align: left;}
  .windform .conts p{font-size:16px;width:100%;text-align: left;margin:10px 0 5px 0;}
  .windform .conts .regType{display: flex;width:100%;border-radius: 0 !important;}
  .windform .conts .flex{display: flex;width:100%;align-items: center;}
  .windform .conts .flex a{margin:0 5px;font-size:20px;}
  .windform .conts .regType span{width:50%;font-size:16px;height:48px;display: flex;align-items: center;justify-content: center;color:#C4C4C4;cursor: pointer;border:2px solid #C4C4C4;}
  .windform .conts .regType span:nth-child(1){border-radius: 10px 0 0 10px;border-right-width: 0;}
  .windform .conts .regType span:nth-child(2){border-radius: 0 10px 10px 0;border-left-width: 0;}
  .windform .conts .bord{border:2px solid #C4C4C4;border-radius: 10px;height:48px;overflow: hidden;width:100%;}
  .windform .conts .regType .act{background-color: #01388B;color:#ffffff;cursor: default;border:2px solid #01388B;}
  .windform .conts .btn{background-color: #C4C4C4;color:#ffffff;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);width:270px;height:50px;display: flex;justify-content: center;align-items: center;font-size:24px;font-weight: bold;border-radius: 10px;margin-top:20px;}
  .windform .conts .actbtn{background-color: #00C999 !important;cursor: pointer;}
  .windform .conts input{font-size:14px;padding-left:20px;}
  .windform .conts .input{letter-spacing: 15px;padding-left:20px;box-sizing: border-box;}
  .windform .conts .input::placeholder{color: #C4C4C4;}

  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:30px 0;font-family: 'Pretendard';font-weight: bold;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'SF UI Display';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .msgInfo{font-size: 14px;color: #C90000;width:100%;text-align: left;}
  .login-type{display: flex;margin-bottom: 20px;}
  .login-type span{background: #D9D9D9;font-family: 'Pretendard';font-weight: bold;border-radius: 18px;width:110px;height:40px;display: flex;align-items: center;justify-content: center;margin-right:20px;}
  .login-type .act{background: #01388B;color:#ffffff;}
  .check-div{display: flex;margin-top:10px;align-items: center;font-family: 'Pretendard';letter-spacing: -0.6px;}
  .check-div input{width:20px;height:20px;margin-right:10px;}
  .check-div a{color:#007AFF;cursor: pointer;}
  .remark{color:#888888;letter-spacing: -0.45px;font-family: 'Pretendard';}
  .remark a{color:#0020C9;font-weight: bold;cursor: pointer;}
  .link{font-family: 'Pretendard';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;display: flex;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:36px !important;}
  .box-item a{flex-shrink: 0;color:#4A4A4A !important;font-family: 'Pretendard';font-size:15px;cursor: pointer;margin-right:10px;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;display: flex;flex-direction: column;align-items: center;}
  .content .info .item{display: flex;flex-direction: column;margin-top:20px;align-items: flex-start;width:100%;}
  .content .info .item span{font-size:14px;margin-bottom:6px;font-family: 'Pretendard';}
  .content .info .item input{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;padding:0 10px;font-size:14px;}
  .content .info .item input:disabled{background-color:transparent !important;}
  .disabled{background-color:rgba(217, 217, 217, 0.20) !important;}
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;height: 48px;line-height:1;margin:20px 0 10px 0;color:#ffffff;background: #AFAFAF;border-radius: 10px;display: flex;align-items: center;justify-content: center;}
  .content .info .ok{background: #01388B;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 10px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .check-div{margin:0 10vw 10px 10vw;}
    .title-remark{font-size: 15px;letter-spacing: -0.24px;margin-bottom:10px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
