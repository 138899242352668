<template>
  <div class="con" v-loading="formLoading">
    <el-dialog
      top="30vh"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose">
      <div style="display: flex;align-items: center;justify-content: center;">
          <el-progress type="line" :textInside="true" :percentage="total_progress" style="width:100%;" :stroke-width="20"></el-progress>
      </div>
    </el-dialog>
    <div class="top-img pc">
      <div class="div">
        <a>마이페이지</a>
        <p>회원 정보 관리, 공동인증서 관리</p>
        <p>비밀 보관 현황, 원본증명서 신청, 결제내역, 환불처리</p>
      </div>
    </div>
    <div class="content">
      <div class="main">
        <div class="menu pc">
          <div class="menu pc">
            <a @click="goPage('/myInformation')">회원 정보 관리</a>
            <a @click="goPage('/myInformationCert')">공동인증서 관리</a>
            <a @click="goPage('/myFile')">파일 보관 현황</a>
            <a class="act">증명서 신청내역</a>
            <a @click="goPage('/myTransaction')">결제/환불</a>
          </div>
        </div>
        <div class="list-div">
          <div class="til pc">
            <label></label>
            <div style="width:200px;border-bottom:1px solid #000000;display: flex;align-items: center;">
              <input v-if="infoForm" style="border: none;width:100%;height:36px;text-align: center;" v-model="info" placeholder="파일명 검색"/>
              <span v-if="!infoForm" style="width:100%;display: flex;align-items: center;justify-content: center;line-height:36px;">{{info}} <img style="width: 26px;height:26px;cursor: pointer;" @click="clearInfo" src="../assets/img2/cancel.png"/></span>
              <img style="width: 24px;height:24px;flex-shrink: 0;cursor: pointer;" @click="searchInfo" src="../assets/img2/search.png"/>
            </div>
          </div>
          <div class="mobile" style="color:#3B5998;font-size: 12px;">* 증명서 발급은 PC에서 이용이 가능합니다. </div>
          <div class="list" v-loading="hashCertificateRequestsLoading">
            <div class="header">
              <span>신청날짜</span>
              <span>파일명</span>
              <span>파일관리코드</span>
              <span>상태</span>
              <span class="pc">발급번호</span>
              <span class="pc">다운로드 가능 기한</span>
            </div>
            <div class="item" v-for="(item,index) in hashCertificateRequestsList" :key="index">
              <span>{{item.requestedDatetime}}</span>
              <span>{{item.fileName}}</span>
              <span>{{item.fileNum}}</span>
              <span>
                <span style="color:#00C999;" v-if="item.releaseStatus == 1">발급완료</span>
                <span style="color:#F46969;" v-else-if="item.releaseStatus > 0 && item.diffDay < 0">만료</span>
                <span style="color:#F46969;" v-if="item.releaseStatus == 0 || item.releaseStatus == null">실패</span>
              </span>
              <span class="pc">{{item.noStr}}</span>
              <span class="pc">
                <!-- 下载 -->
                <div v-if="item.releaseStatus > 0 && item.diffDay > 0" style="color:#5F5F5F;">
                  <a @click="down_load_file_pdf(item)">다운로드</a>
                  <br/>{{item.downloadTime}}만료
                </div>
                <!-- 重新申请 -->
                <a @click="reCertFunction(item.contentsOriginalityRequestUuid)" v-if="item.releaseStatus == 0 || item.releaseStatus == null">다시신청</a>
              </span>
            </div>
            <div class="load-more" v-if="total > 10">
              <!-- <a v-if="hashServiceUsageIsMore" @click="loadMoreHashServiceUsageList()">+ 더보기</a> -->
              <pagination
                layout="prev, pager, next"
                :page-size="pageSize"
                @current-change="change_page"
                :total="total">
              </pagination>
            </div>
          </div>
        </div>
      </div>
      <div style="width:800px;position: absolute;left:-800px;" id="pdfDiv">
        <vue-office-pdf v-if="pdf_src != null && pdf_src != ''" :src="pdf_src" style="width: 100%;margin-bottom:40px;"/>
        <div style="padding:30px;" v-if="imgUrl != null && imgUrl != ''">
          <img style="width: 100%;" @load="savePdf" :src="imgUrl"/>
        </div>
        <pre v-if="txtContent != null && txtContent != ''" style="width:100%;overflow-x: hidden;white-space: pre-wrap;word-wrap: break-word;padding:30px;">{{txtContent}}</pre>
        <vue-office-pdf v-if="pdf_src_content != null && pdf_src_content != ''" :src="pdf_src_content" @rendered="savePdf" style="width: 100%;"/>
        <div id="hwpcontent" style="width: 100%;background-color: #ffffff;"></div>
        <vue-office-docx v-if="docx_src != null && docx_src != ''" :src="docx_src" @rendered="savePdfTim" style="width: 100%;"/>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { v4 as uuidv4 } from 'uuid';
  import { api as viewerApi } from 'v-viewer'
  import Tiff from 'tiff.js'
  import { getAction,postAction,putAction,downFile,deleteAction } from '@/network/manage'
  import { deleteActionApi, postActionApi,putActionApi,getActionApi,postDownloadActionApi } from '@/network/manageApi'
  import VueOfficePdf from '@vue-office/pdf'
  import VueOfficeDocx from '@vue-office/docx'
  import '@vue-office/docx/lib/index.css'
  import html2Canvas from 'html2canvas'
  import JsPDF from 'jspdf'
  import { Collapse,Pagination } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { CERT_LOGIN } from '@/store/mutation-types'
  import '@/assets/js/jsencryptKey'
  export default {
    name: 'MyService',
    components: {
      VueOfficePdf,
      Pagination,
      VueOfficeDocx
    },
    data() {
      return {
        certImg:null,
        certIsShow:false,
        formLoading:false,
        isChecked:false,
        total_progress: 0,
        pdf_title:"",
        pdf_src:"",
        total:0,
        pageSize:10,
        pdf_src_content:"",
        docx_src:"",
        nowFileName:'',
        nowNoStr:'',
        imgUrl:null,
        txtContent:null,
        dialogVisible:false,
        dialogVisiblePdf:false,
        certLogin:false,
        paymentWidget:null,
        user:null,
        infoForm:true,
        info:'',
        customerInfo:null,
        yearList:[],
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        hashCertificateRequestsLoading:false,
        hashCertificateRequestsPage:1,
        hashCertificateRequestsList:[],
        hashCertificateRequestsIsMore:false,
        url: {
          view_hashed_service_usage:'/user/userInformation/view_hashed_service_usage',
          view_certificate_requests:'/user/userInformation/view_certificate_requests',
          view_purchase_status:'/user/userInformation/purchase_log_list',
          view_hash_delete_log:'/user/userInformation/view_hash_delete_log',
          sign_failed:'/get-timestamp/sign-failed'
        }
      }
    },
    computed: {

    },
    created(){
      this.loadCertificateRequests();
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      const that = this;
      that.formLoading = true;
      that.getBase64('/img/pdf_title_new.png')
      getActionApi('/login-management/user').then((res) => {
        if (res.code == 200) {
          that.user = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getAction('/user/userInformation/my_info').then(res => {
        if (res.success) {
          if(res.result.userinfo.userName == null && res.result.userinfo.residentNumber == null && res.result.userinfo.businessName == null && res.result.userinfo.businessNumber == null)
            window.location.href = '/registerIDCert'
          that.customerInfo = res.result.userinfo
          that.paymentWidget = PaymentWidget('live_ck_YZ1aOwX7K8mzvBWmy6A3yQxzvNPG', that.customerInfo.id);
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      }).finally(() => {
        that.formLoading = false;
      })
    },
    methods:{
      getBase64(imgUrl) {
        const that = this;
         var xhr = new XMLHttpRequest();
         fetch(imgUrl, {
              responseType: 'blob'
         }).then(response => {
              return response.blob();
          }).then(blob => {
              let oFileReader = new FileReader();
              oFileReader.onloadend = function(e) {
                  // base64结果
                  const base64 = e.target.result
                  that.pdf_title = base64
              };
              oFileReader.readAsDataURL(blob);
          });
      },
      savePdfTim(){
        const that = this;
        setTimeout(function(){
          that.savePdf()
        },2000)
      },
      savePdf(){
        const that = this
        this.total_progress = 100;
        that.dialogVisible = false
        html2Canvas(document.querySelector('#pdfDiv'), {
            // allowTaint: true,
            dpi:150,
            scale:150/96,
            useCORS: true
        }).then(function(canvas) {
            let contentWidth = canvas.width
            let contentHeight = canvas.height
            //一页pdf显示html页面生成的canvas高度;
            let pageHeight = contentWidth / 592.28 * 900
            //生成pdf的html页面高度
            let leftHeight = contentHeight
            //页面偏移
            let position = 0
            //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            let imgWidth = 595.28
            let imgHeight = 592.28 / contentWidth * contentHeight
            // canvas.crossOrigin="anonymous";
            let pageData = canvas.toDataURL('image/png', 1.0);

            let PDF = new JsPDF('', 'pt', 'a4')
            //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
            //当内容未超过pdf一页显示的范围，无需分页
            if (leftHeight < pageHeight) {
                PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
                while (leftHeight > 0) {
                    PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight
                    position -= 841.89
                    if (leftHeight > 0) {
                        //避免添加空白页
                        PDF.addPage()
                    }
                }
            }
             // 添加页脚
            var totalPages = PDF.internal.getNumberOfPages();
            for (var i = 2; i <= totalPages; i++) {
              PDF.setPage(i);
              PDF.setFontSize(10);
              PDF.setTextColor(204, 210, 244);
              PDF.addImage(that.pdf_title, 'JPEG', PDF.internal.pageSize.getWidth()/2 - 39.5, 4, 79, 15);
              PDF.text(
                ''+i,
                PDF.internal.pageSize.getWidth() / 2,
                PDF.internal.pageSize.getHeight() - 15
              );
              PDF.text(
                ''+that.nowFileName,
                30,
                PDF.internal.pageSize.getHeight() - 15
              );
              PDF.text(
                ''+that.nowNoStr,
                PDF.internal.pageSize.getWidth() - 130,
                PDF.internal.pageSize.getHeight() - 15
              );
            }
            let oldIndex = that.nowFileName.lastIndexOf(".")
            PDF.save(that.nowFileName.substring(0,oldIndex)+'.pdf')
        })
      },
      handleClose(done) {
        this.$confirm('로드를 중단하시겠습니까?')
        .then(_ => {
          this.now_uuid_file = 0
          this.total_progress = 0
          done();
        })
        .catch(_ => {});
      },
      searchInfo(){
        this.hashCertificateRequestsPage = 1;
        this.infoForm = false;
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },
      clearInfo(){
        this.hashCertificateRequestsPage = 1;
        this.infoForm = true;
        this.info = '';
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },
      down_load_file_pdf(item){
        const that = this
        let fileName = "cert"
        that.pdf_src = ""
        that.imgUrl = ""
        that.txtContent = ""
        that.pdf_src_content = ""
        that.docx_src = ""
        that.total_progress = 0
        that.nowFileName = item.fileName + '('+item.fileNum+')'
        that.nowNoStr = item.noStr
        document.getElementById("hwpcontent").innerHTML = ""
        let params = {id: item.contentsOriginalityRequestUuid}
        this.dialogVisible = true
        downFile("/user/userInformation/show_cert_view",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            that.pdf_src = url
            if(item.attachmentRequested == 1){
              //预览文件
              that.pre_view(item.uploadedFileUuid,item.fileName)
            }else{
              that.savePdfTim();
            }

            /* let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.pdf')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); */
          }
        })
      },
      get_file_type(fileName) {
        let num = fileName.split(".").length - 1
        return fileName.split(".")[num];
      },
      get_type(fileName) {
        let suffix_name = this.get_file_type(fileName).toLowerCase();
        //其他类型
        let type = -1;
        if(suffix_name == 'jpg' || suffix_name == 'jpeg'
        || suffix_name == 'png' || suffix_name == 'gif'){
          //图片
          type = 1
        } else if(suffix_name == 'tif') {
          //tif图像
          type = 5
        } else if(suffix_name == 'txt') {
          type = 6
        } else if(suffix_name == 'pdf') {
          type = 7
        }else if(suffix_name == 'docx') {
          type = 9
        } else if(suffix_name == 'hwp') {
          type = 10
        }
        return type;
      },
      async pre_view(uuid, fileName) {
        let that = this
        try {
           let type = this.get_type(fileName)
           if(type == -1) {
             //-1的情况都是无法预览饿提示无法预览
             //this.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
             return;
           }
           this.total_progress = Math.floor(Math.random()*10)

           let uuid_new = uuidv4()
           this.now_uuid_file = uuid_new
           let blob = await this.load_resources(uuid)
           if(this.now_uuid_file == uuid_new) {
             const url = window.URL.createObjectURL(blob)
             if(type == 1) {
               this.imgUrl = url;
             }else if(type == 5) {
               var reader = new FileReader();
               reader.readAsArrayBuffer(blob);
               reader.onload = function() {
                 try{
                    const tiff = new Tiff({ buffer: this.result })
                    that.imgUrl = tiff.toDataURL();
                 } catch(e) {
                   that.dialogVisible = false
                   //that.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
                 }
               }
             } else if(type == 6) {
               blob.text().then(data =>{
                 this.txtContent = data
                 that.savePdfTim()
               })
             } else if(type == 7) {
               this.pdf_src_content = url
             } else if(type == 9) {
               this.docx_src = url
             } else if(type == 10) {
                //hwp
                var reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = function() {
                  try {
                    new HWP.Viewer(document.getElementById("hwpcontent"),new Uint8Array(this.result), { type: 'array' })
                    setTimeout(function(){
                      that.savePdfTim()
                    },3000)
                  } catch(e) {
                    that.dialogVisible = false
                    //that.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
                  }
                }
             }

           }
        } catch (e) {
          //that.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
        }
      },
      async load_resources(uuid){
        let fileBlob = []
        const tasks = [];
        const that = this;
        //总片数
        let chunks = 0;
        //当前下载数量
        let downloaded = 0;
        let obj = await postActionApi('/hash-generator/download/initial-create',{"contents_file_upload_uuid": uuid})
        //获得总片数
        chunks = obj.data.contents.piece_information.length
        if(obj.code == 200) {
          let file_array = obj.data.contents.piece_information
          for (let index = 0; index < file_array.length; index++) {
            tasks.push(
              new Promise((resolve, reject) => {
                let downData = {download_order: file_array[index].download_order}
                const params = {"download_piece_uuid": file_array[index].contents_file_download_piece_information}
                this.downLoadbyPiece(params).then(res => {
                  downData.data = res
                  fileBlob.push(downData)
                  //已下载数量+1
                  downloaded++;
                  //计算总进度
                  this.total_progress = Math.floor((downloaded / chunks) * 100);
                  resolve()
                }).catch(err => {
                  reject(err)
                })
              })
            )
          }
          await Promise.all(tasks);
          let parts = fileBlob.sort((a, b) => a.download_order - b.download_order)
          let fileBlobDatas = []
          for (let index = 0; index < parts.length; index++) {
            fileBlobDatas.push(parts[index].data)
          }
          const blob = new Blob(fileBlobDatas)
          return blob;
        }
      },
      downLoadbyPiece(params) {
        return postDownloadActionApi('/hash-generator/download/piece-download',params);
      },
      reCertFunction(uuid){
        this.$router.push({name:'myServiceCert',query:{uuid:uuid}});
      },
      loadMoreCertificateRequestsList(){
        this.hashCertificateRequestsPage = this.hashCertificateRequestsPage + 1;
        this.loadCertificateRequests();
      },
      change_page(e){
        this.hashCertificateRequestsPage = e;
        this.hashCertificateRequestsList = [];
        this.loadCertificateRequests();
      },
      loadCertificateRequests(){
        const that = this;
        that.hashCertificateRequestsLoading = true;
        getAction(that.url.view_certificate_requests,{pageNo:that.hashCertificateRequestsPage,pageSize:10,info:this.info.trim()}).then(res => {
          if (res.success) {
            if(res.result.current == res.result.pages || res.result.pages == 0)
              that.hashCertificateRequestsIsMore = false
            else
              that.hashCertificateRequestsIsMore = true
            that.hashCertificateRequestsList = that.hashCertificateRequestsList.concat(res.result.records)
            that.total = res.result.total;
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        }).finally(() => {
          that.hashCertificateRequestsLoading = false;
        })
      },
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>
<style>

</style>
<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  >>> #pdfDiv table{max-width: 100%;}
  >>> #pdfDiv section{max-width: 100%;padding:20px !important;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/mypage_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-img .div p:nth-child(3){margin-top:5px !important;}
  .window-confirm{position: fixed;z-index: 1001;width:100vw;height:100vh;left:0;top:0;background-color: rgba(255,255,255,0.8);display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;border: 2px solid #888888;border-radius: 15px;width: 760px;padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .yz{position: fixed;align-self: flex-end;margin-top:270px;margin-right:50px;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .btn{background: #797979;border-radius: 10px;margin-top:30px;height: 40px;width: 80px;cursor:no-drop;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .list{width:500px;line-height:40px;padding:50px 0;}
  .window-confirm .window .radio-div{display: flex;width:220px;justify-content: space-between;}
  .window-confirm .window .radio-div label{display: flex;align-items: center;line-height: 1;font-size:13px;cursor: pointer;}
  .window-confirm .window .radio-div input{margin-right:10px;width:18px;height:18px;}
  .window-confirm .window .remark{font-size: 18px;list-style:1;margin:30px 0;}
  .window-confirm .window .price{font-size: 16px;line-height:1;margin:30px 0;}
  .window-confirm .window .price a{font-size: 16px;color: #007AFF;line-height:1;}
  .window-confirm .window .confirm{font-size: 16px;letter-spacing: -0.6px;line-height:1;color:#4A4A4A;display: flex;align-items: center;}
  .window-confirm .window .confirm input{width:20px;height:20px;margin-right:6px;}
  .window-confirm .window .confirm a{color: #1296DB;cursor: pointer;}
  .window-confirm .window .note{font-size: 16px;line-height: 2;margin-top:50px;}
  .window-confirm .window .a{cursor: pointer;font-size: 16px;line-height: 1;color:#1296DB;margin-bottom:20px;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;padding:20px 0;color: #888888;cursor: pointer;font-weight: bold;letter-spacing: -1px;border-bottom:1px solid #000000;}
  .main .menu a:hover{color: #01388B;}
  .main .menu a:last-child{border-bottom-width: 0;}
  .main .menu .act{color: #01388B;}
  .main .list-div{width:100%;padding:30px;}
  .main .list-div .til{display: flex;justify-content: space-between;align-items: center;font-family: 'Pretendard';margin-top:10px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div .list{display: flex;flex-direction: column;margin-bottom:30px;}
  .main .list-div .list .header{background-color: rgba(0, 91, 227, 0.1);display: flex;align-items: center;margin-top:10px;}
  .main .list-div .list .header span{display: flex;justify-content: center;align-items: center;width:220%;height:34px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item{border-bottom:1px solid #C4C4C4;display: flex;align-items: center;}
  .main .list-div .list .item span{display: flex;justify-content: center;word-break: break-all;align-items: center;width:220%;height:60px;flex-shrink: 1;text-align: center;}
  .main .list-div .list .item span a{margin:0 6px;}
  .main .list-div .list .remark{color:#0020C9;margin-top:10px;}
  .main .list-div .list .load-more{text-align: center;margin-top:10px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .main .list-div .list{margin: 12px 0;}
    .main .list-div .list span{font-size:12px !important;}
    .main .list-div .til{flex-direction: column;}
    .main .list-div .til p{color: #3B5998;font-size: 12px;margin-top:10px;margin-bottom: 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .title{font-size:20px;}
    .con{min-height:calc(100vh - 206px);}
    .main .menu a{font-family: 'Pretendard';font-size: 16px;color: #888888;margin:10px 0;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .con{min-height:calc(100vh - 206px);}
  }
</style>
