import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Notification, MessageBox } from 'element-ui'
import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5 * 60 * 1000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 403:
        Notification.error({ title: '온누리국제영업비밀보호센터 알림', message: 'Access denied by server' })
        break
      case 500:
        Notification.error({ title: '온누리국제영업비밀보호센터 알림', message: 'Internal server error' })
        break
      case 404:
        Notification.error({ title: '온누리국제영업비밀보호센터 알림', message: 'Sorry, the requested resource was not found' })
        break
      case 504:
        Notification.error({ title: '온누리국제영업비밀보호센터 알림', message: 'Request timed out, please try again later' })
        break
      case 401:
        MessageBox.confirm('Sorry, the login status has expired. Please login again', '아이디가 차단 되었습니다.', {
          confirmButtonText: '로그인',
          type: 'error'
        }).then(() => {
          Vue.ls.remove(ACCESS_TOKEN);
          Vue.ls.remove(CERT_LOGIN);
          window.location.href='/login';
        }).catch((err) => {
          Vue.ls.remove(ACCESS_TOKEN);
          Vue.ls.remove(CERT_LOGIN);
          window.location.href='/login';
          return;
        })
        break
      default:
        Notification.error({title: '온누리국제영업비밀보호센터 알림', message: error.message })
        break
    }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
