<template>
  <div class="content">
    <div class="bg mobile"></div>
    <div class="title">온누리 국제영업비밀보호센터 이용약관</div>
    <div style="width: 360px;height: 1px;background-color: #000000;margin-bottom: 20px;"></div>
    <div class="desc">
      <h1>[제1장 총 칙]</h1>
      <br/>
      <h2>제1조(목적)</h2>
      <p>이 약관은 온누리 국제영업비밀보호센터(이하 "온누리 영비센터"이라 한다)에서 제공하는 서비스(이하 "서비스"라 한다)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.</p>
      <br/>
      <h2>제2조(정의)</h2>
      <p>이 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다. 이하에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래 관행에 따릅니다.</p>
      <p>  1. 회원 : 이 약관에 대한 동의 및 회원가입신청을 하고 온누리 영비센터가 회원가입신청에 대해 승낙한 자로서 온누리 영비센터에서 제공하는 서비스를 이용할 수 있는 자</p>
      <p>  2. 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 정하고 보호센터가 승인하는 문자 또는 숫자의 조합</p>
      <p>  3. 비밀번호(PASSWORD) : 회원이 부여받은 아이디와 일치하는 회원임을 확인하고 비밀 보호를 위해 회원 자신이 설정한 문자 또는 숫자의 조합</p>
      <br/>
      <h2>제2조의2(서비스의 제공)</h2>
      <p>온누리 영비센터는 다음 각호와 같은 서비스를 제공합니다.</p>
      <p>  1. 회원이 의뢰하는 파일의 암호화 보관</p>
      <p>  2. 회원의 요청에 따른 파일의 원본증명서 발행</p>
        <br/>
      <h2>제3조(약관의 효력의 발생과 변경)</h2>
      <p>① 이 약관의 내용은 온누리 영비센터 화면에 게시하거나 기타의 방법으로 공지함으로써 효력이 발생됩니다. ② 온누리 영비센터가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정 사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다.</p>
      <p>③ 온누리 영비센터가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.</p>
      <p>④ 회원이 개정약관의 적용에 동의하지 않는 경우 온누리 영비센터 또는 회원은 서비스 이용계약을 해지할 수 있습니다.</p>
      <br/>
      <h2>제4조(준용규정)</h2>
      <p>이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법 및 기타 관련법령의 규정에 따릅니다.</p>
      <br/>
      <br/>
      <h1>[제2장 서비스 이용계약]</h1>
      <br/>
      <h2>제5조(이용계약의 성립)</h2>
      <p>이용계약은 회원이 이 약관에 동의한 후 온누리 영비센터의 승인 절차를 통해 성립됩니다.</p>
      <br/>
      <h2>제6조(회원가입)</h2>
      <p>온누리 영비센터는 회원의 회원가입 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 다음 각호의 1에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.</p>
      <p>  1. 회원가입내용을 허위로 기재한 경우</p>
      <p>  2. 다른 사람의 명의를 사용하여 신청한 경우</p>
      <p>  3. 기타 온누리 영비센터가 정한 회원가입요건이 미비된 경우</p>
        <br/>
        <br/>
      <h1>[제3장 계약당사자의 의무]</h1>
      <br/>
      <h2>제7조(온누리 영비센터의 의무)</h2>
      <p>① 온누리 영비센터는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.</p>
      <p>② 온누리 영비센터는 서비스 제공과 관련해서 알고 있는 회원의 개인 신상 정보 및 모든 관련 정보를 회원 본인의 승낙 없이 제3자에게 누설, 배포하지 않으며, 문제가 생겼을 경우 필요한 법적 책임을 다합니다. 단, 전기통신기본법 등 관계법령에 의하여 국가기관 등의 요구가 있는 경우에는 그러하지 않습니다.</p>
      <p>③ 온누리 영비센터는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.</p>
      <br/>
      <h2>제8조(회원의 의무)</h2>
      <p>① 회원은 회원 가입시 입력하는 정보를 정확하게 기입하여야 하며, 만일 주소, 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우 이를 온누리 영비센터에 알려야 합니다.</p>
      <p>② 회원은 자신의 아이디와 비밀번호를 타인에게 제공하여 이용하게 해서는 안됩니다.</p>
      <p>③ 온누리 영비센터가 관계 법령 및 개인정보 보호정책에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 아이디와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.</p>
      <p>④ 회원은 온누리 영비센터 및 제3자의 지적 재산권을 침해해서는 안됩니다.</p>
      <p>⑤ 회원은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도·증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>
      <p>⑥ 회원은 다음 각호의 행위를 하여서는 안되며, 해당 행위를 하는 경우에 온누리 영비센터는 회원의 서비스 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.</p>
      <p>  1. 다른 회원의 아이디, 비밀번호 등을 부정하게 사용하는 행위</p>
      <p>  2. 범죄와 결부된다고 객관적으로 판단되는 행위</p>
      <p>  3. 기타 관계법령에 위반되는 행위</p>
        <br/>
        <br/>
      <h1>[제4장 서비스 이용]</h1>
      <br/>
      <h2>제9조(회원의 온누리 영비센터에 관한 의무)</h2>
      <p>① 회원이 온누리 영비센터에 등록한 자료에 대한 관리 책임은 회원에게 있습니다.</p>
      <p>② 회원이 온누리 영비센터에 전자지문만을 보관 의뢰하는 경우, 회원은 반드시 의뢰한 파일의 원본을 보관하고 있어야 합니다. 회원은 원본 파일이 없는 경우 추후 원본 증명서의 발행이 불가하다는 점을 인지하고 이에 동의합니다.</p>
      <br/>
      <h2>제10조(서비스 제공의 중지)</h2>
      <p>다음 각호의 1에 해당하는 경우 온누리 영비센터는 회원에 대한 서비스 제공을 전부 또는 일부 일시적으로 제한하거나 중지할 수 있습니다. 이 경우 온누리 영비센터는 회원에게 통지합니다. 다만, 온누리 영비센터가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
      <p>  1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</p>
      <p>  2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</p>
      <p>  3. 시스템 점검이 필요한 경우</p>
      <p>  4. 기타 불가항력의 사태로 인해 서비스 제공이 불가한 경우</p>
        <br/>
        <br/>
      <h1>[제5장 계약해지 및 이용제한]</h1>
      <br/>
      <h2>제11조(계약해지 및 이용제한)</h2>
      <p>① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 인터넷을 통하여 회원탈퇴신청을 하여야 하며, 온누리 영비센터에서는 본인 여부를 확인 후 조치합니다.</p>
      <p>② 회원이 다음 각호의 1에 해당하는 행위를 하였을 경우, 온누리 영비센터는 회원자격을 제한 및 정지시킬 수 있습니다.</p>
      <p>  1. 타인의 아이디 및 비밀번호를 도용한 경우</p>
      <p>  2. 서비스 운영을 고의로 방해한 경우</p>
      <p>  3. 회원가입 신청 시 허위 내용을 등록한 경우</p>
      <p>  4. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</p>
      <p>  5. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 행위를 하는 경우</p>
      <p>  6. 온누리 영비센터 또는 다른 회원이나 제3자의 지적재산권을 침해하는 경우</p>
      <p>  7. 이 약관 및 기타 관련법령에 위반되는 행위를 하는 경우</p>
      <p>③ 온누리 영비센터가 회원자격을 제한·정지시킨 후 [30]일 이내에 그 사유가 시정되지 아니하는 경우 온누리 영비센터는 회원자격을 상실시킬 수 있습니다.</p>
      <p>④ 온누리 영비센터가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전 최소한 [30]일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
      <br/>
      <h2>제12조 (결재 및 취소, 환불)</h2>
      <p>① 회원은 서비스 이용 대금을 다음 각 호의 방법 중 하나의 방법으로 지급할 수 있습니다.</p>
      <p>  1. 계좌이체</p>
      <p>  2. 신용카드 혹은 체크카드 결제</p>
      <p>  3. 기타 회원과 온누리 영비센터가 상호 합의한 방법</p>
      <p>② 회원은 서비스 이용 대금 지급 후 7일 이내에 서비스 이용계약을 취소(청약철회)하고 대금을 환불받을 수 있습니다.</p>
      <p>③ 회원은 그 이후에도 별도로 정한 취소수수료를 제하고 전자상거래법 등 관계 법령이 허여하는 취소와 환불이 가능합니다.</p>
      <br/>
      <h2>제12조의2(손해배상)</h2>
      <p>① 온누리 영비센터 또는 회원의 귀책사유로 인하여 상대방 또는 제3자에게 손해가 발생한 경우 귀책 당사자는 해당 손해를 배상하여야 합니다.</p>
      <p>② 온누리 영비센터 또는 회원이 이 약관의 규정 및 관렵 법령 등을 위반하여 상대방에 손해가 발생한 경우 그 상대방은 손해의 발생에 책임 있는 당사자에 대하여 손해배상을 청구할 수 있습니다.</p>
      <br/>
      <br/>
      <h1>[제6장 면책]</h1>
      <br/>
      <h2>제13조(면책 조항)</h2>
      <p>온누리 영비센터는 회원이 서비스 이용과 관련하여 제공한 자료의 정보, 사실의 신뢰도, 정확성 등의 내용에 관하여 책임을 지지 않습니다.<p>
      <br/>
      <h2>제14조(준거법 및 재판 관할)</h2>
      <p>① 이 약관의 해석 및 온누리 영비센터와 회원 간의 분쟁에 관하여는 대한민국의 법률을 적용합니다.</p>
      <p>② 이 약관 및 서비스 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 온누리 영비센터의 본청 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</p>
      <br/>
      <br/>
      <h3>[부 칙] 이 약관은 2023년 9월 1일부터 시행합니다.</h3>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  export default {
    name: 'TermsConditions',
    components: {
    },
    data() {
      return {
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .content .title{font-family: 'Pretendard';font-size: 26px;letter-spacing: -1px;margin:30px 0 10px 0;}
  .content .button{align-self: flex-end;margin:10px 0;color:#01388B;cursor: pointer;border: 1px solid #01388B;height: 36px;width: 150px;border-radius: 3px;display: flex;justify-content: center;align-items: center;}
  .content .button:hover{color:#ffffff;background-color: #ffffff;border: 1px solid #01388B;background: #01388B;}
  .content .button span{margin-left:10px;width:22px;height:8px;background-image: url(../assets/img/point_right_act.png);}
  .content .button:hover span{background-image: url(../assets/img/point_right.png);}
  .content .desc{display: flex;flex-direction: column;}
  .content .desc h1{font-size:24px;font-weight:bold;}
  .content .desc h2{font-size:20px;font-weight:bold;color: #0020C9;}
  .content .desc p{font-size: 18px;line-height:1.5;}
  .content .desc h3{font-size:18px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .content{height:100vh;margin:0 20px;}
    .bg{position: fixed;z-index: -1;width:100vw;height:40vw;left:0;top:0;background:linear-gradient(180deg, rgba(217, 217, 217, 0.58) 0%, rgba(217, 217, 217, 0) 100%);}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
