<template>
  <div id="app">
    <hash-header></hash-header>
    <router-view/>
    <hash-bottom></hash-bottom>
  </div>
</template>

<script>
  import HashHeader from "components/HashHeader"
  import HashBottom from "components/HashBottom"
  import { getAction, postAction } from '@/network/manage'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import Vue from 'vue'
  export default {
    name: 'App',
    data() {
      return {
        gap_time: 0,
        beforeUnload_time: 0,
      };
    },
    components: {
      HashHeader,
      HashBottom
    },
    beforeCreate() {
      
    },
    methods: {
      // 关闭窗口之前执行
      beforeunloadHandler(e) {
        this.beforeUnload_time = new Date().getTime();
      },
      unloadHandler(e) {
        this.gap_time = new Date().getTime() - this.beforeUnload_time;
        //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
        postAction('/user/userInformation/loginOut').then((res) => {}).catch(res => {})
      },
    },
    destroyed() {
      // 移除监听
      window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
      window.removeEventListener("unload", () => this.unloadHandler());
    },
    mounted() {
      // 监听浏览器关闭
      window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
      window.addEventListener("unload", (e) => this.unloadHandler(e));
    }
  }
</script>

<style>
	@import "./assets/css/base.css";
  @media (max-width: 800px) {
  	#app{padding-top:60px;}
    .el-message {
      min-width: 300px !important;
    }
    .el-message-box{
      width: 80vw !important;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	#app{padding-top:80px;}
  }
  @media(min-width: 1200px) {
  	#app{padding-top:80px;}
  }
</style>
