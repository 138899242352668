<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content">
      <div class="title">인증서 등록</div>
      <div class="title-remark">서비스 이용을 위하여 공동인증서 등록을 진행하여 주세요,<br/>1개 계정 1개 인증서 등록이 요구되며, 계정 등록된 인증서만을 통해 서비스 이용이 가능합니다.</div>
      <div class="info">
        <div class="login-type pc">
          <span :class="regType == 0 ? 'act' : ''" @click="regType = 0">개인</span>
          <span :class="regType == 1 ? 'act' : ''" @click="regType = 1">회사</span>
        </div>
        <div class="item" v-if="regType == 0">
          <span>성함</span>
          <input v-model="name"/>
        </div>
        <div class="item" v-if="regType == 0">
          <span>주민등록 번호</span>
          <div>
            <input v-model="resident_number1" maxlength="6"/><input v-model="resident_number2" type="password" maxlength="7"/>
          </div>
        </div>
        <div class="item" v-if="regType == 1">
          <span>회사명</span>
          <input v-model="business_name"/>
        </div>
        <div class="item" v-if="regType == 1">
          <span>사업자등록번호</span>
          <div>
            <input style="width:120px;" v-model="business_number1" maxlength="3"/>-<input style="width:80px;" v-model="business_number2" maxlength="2"/>-<input v-model="business_number3" type="password" maxlength="5" style="width:200px;"/>
          </div>
        </div>
        <div class="btn" @click="saveInfo">인증서 등록</div>
        <div class="remark pc">
          공동인증서가 없으신가요?<br/>
          범용인증서 발급 서비스를 이용하여 보세요 <a target="_blank" href="https://www.tradesign.net/ra/onnuriip">바로가기</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction,postAction } from '@/network/manage'
  import { putActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'RegisterIDCert',
    components: {
    },
    data() {
      return {
        regType: 0,
        formLoading:false,
        name:'',
        resident_number1:'',
        resident_number2:'',
        business_name:'',
        business_number1:'',
        business_number2:'',
        business_number3:'',
        url: {
          business_number:'/login-management/update-business-number',
          resident_number:'/login-management/update-resident-number',
          sign_user:'/user/userInformation/user_sign_update',
        }
      }
    },
    computed: {

    },
    created(){
      nxTSPKI.onInit(function(){});
      nxTSPKI.init(true);
    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      sign_complete_callback(res) {
        const that = this;
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;
          that.formLoading = true;
          if(that.regType == 0){
            param.userType = 0
            param.name = that.name.trim()
            param.residentNumber = that.resident_number1.trim() + '-' + that.resident_number2.trim()
          }else{
            param.userType = 1
            param.businessName = that.business_name.trim();
            param.businessNumber = that.business_number1.trim() + that.business_number2.trim() + that.business_number3.trim();
          }
          postAction(that.url.sign_user, param).then(res => {
            if (res.success) {
              that.$message.success('success');
              that.goPage('/');
            } else {
              that.$message.error(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        }else {
          that.$message.error(res.errorMessage);
        }
      },
      saveInfo(){
        const that = this;
        let name = '';
        let remark = '';
        if(this.regType == 0){
          if(this.name.trim() == ''){
            this.$message.error('성함을 입력하여 주세요');
            return;
          }else{
            name = this.name.trim();
            remark = this.resident_number1.trim() + this.resident_number2.trim();
          }
        }else{
          if(this.business_name.trim() == ''){
            this.$message.error('회사명을 입력하여 주세요');
            return;
          }else{
            name = this.business_name.trim();
            remark = this.business_number1.trim() + this.business_number2.trim() + this.business_number3.trim();
          }
        }
        nxTSPKI.signData(name, {ssn:remark}, function(res) {
          that.sign_complete_callback(res);
        });
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:30px 0;font-family: 'Pretendard';font-weight: bold;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'SF UI Display';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .title-remark{font-family: 'SF UI Display';color: #888888;line-height: 1.5;font-size:15px;}
  .login-type{display: flex;margin-bottom: 40px;justify-content: center;}
  .login-type span{background: #D9D9D9;font-family: 'Pretendard';cursor: pointer;font-weight: bold;border-radius: 18px;width:110px;height:40px;display: flex;align-items: center;justify-content: center;margin-left:20px;}
  .login-type span:nth-child(1){margin-left: 0 !important;}
  .login-type .act{background: #01388B;color:#ffffff;}
  .check-div{display: flex;margin-top:10px;align-items: center;font-family: 'Pretendard';letter-spacing: -0.6px;}
  .check-div input{width:20px;height:20px;margin-right:10px;}
  .check-div a{color:#007AFF;cursor: pointer;}
  .remark{color:#888888;letter-spacing: -0.45px;font-family: 'Pretendard';margin-top:20px;font-weight: bold;line-height: 1.6;}
  .remark a{color:#1296DB;font-weight: bold;cursor: pointer;}
  .link{font-family: 'Pretendard';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:38px;padding:0 10px;display: flex;margin-top:10px;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:36px !important;}
  .box-item a{flex-shrink: 0;color:#00C999;font-family: 'Pretendard';font-size:15px;cursor: pointer;font-weight: bold;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;font-family: 'Pretendard';color:#888888;}
  .content .info .item div{display: flex;justify-content: space-between;width:100%;align-items: center;}
  .content .info .item input{border: 1px solid #C4C4C4;border-radius: 8px;width:210px;height:38px;padding:0 10px;font-size:14px;}
  .content .info .btn{width: 100%;cursor: pointer;height: 40px;line-height:1;margin:60px 0 10px 0;color:#ffffff;background: #01388B;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 10px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .check-div{margin:0 10vw 10px 10vw;}
    .title-remark{font-size: 15px;letter-spacing: -0.24px;margin-bottom:10px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
