import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { MessageBox,Notification,Loading } from 'element-ui'
import { isMobile } from '@/utils/utils'

NProgress.configure({ showSpinner: false })

const whiteList = ['/404', '/','/privacy','/applycert','/termsConditions','/contactUsResult','/terms','/service','/about1','/about2','/about3','/about4', '/consulting','/contactUs','/faq','/helpCenter','/registerIDCert','/login','/register','/findId','/findPwd','/notice','/noticeDetail']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (store.getters.token) {
    /* has token */
    if (to.path === '/register') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    if (to.path == '/' || (to.path != '/' && whiteList.indexOf(to.path) !== -1)) {
      next()
    } else {
      NProgress.done()
      MessageBox.confirm('로그인 후 이용이 가능합니다.', '온누리국제영업비밀보호센터 알림', {
        confirmButtonText: '로그인',
        callback: (action, instance) => {
          if (action === 'confirm') {
            next({path: '/login'})
          } else {
            console.log(from);
            next(from)
          }
        }
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
