<template>
  <div class="content" v-loading="formLoading">
    <div style="display: none;">
      <div id="payment-method"></div>
    </div>
    <div class="windform" v-if="formwin1">
      <div class="conts" style="height:460px;width:630px;">
        <div style="display: flex;width:100%;justify-content: space-between;padding:0px 20px 0 30px;align-items: center;font-size: 24px;font-weight: bold;">
          파일선택
          <img style="width:40px;cursor: pointer;" @click="formwin1 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <div class="main" v-loading="fileLoading">
          <div class="header row">
            <span>파일명</span>
            <span>파일관리코드</span>
            <span>보관 의뢰 일시</span>
            <span></span>
          </div>
          <div class="list">
            <div class="row" v-for="(item,index) in myFileList" :key="index">
              <span>{{item.fileName}}</span>
              <span>{{item.fileNum}}</span>
              <span>{{item.serviceExpiredDate}}</span>
              <span>
                <a @click="selectFile(item)" style="width: 54px;height: 22px;border-radius: 5px;background-color: #01388B;color:#ffffff;display: flex;align-items: center;justify-content: center;cursor: pointer;">선택</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pc window-confirm" v-if="formwin2">
      <div class="window">
        <div style="color: #3B5998;letter-spacing: -0.6px;font-weight: bold;font-size: 35px;margin:20px 0;">PROCESSING</div>
        <div style="font-size: 18px;letter-spacing: -0.6px;margin:4px 0;">프로세스 진행 중 페이지 이탈 시 작업이 중단 됩니다.</div>
        <div style="font-size: 18px;letter-spacing: -0.6px;margin:4px 0;margin-bottom:50px;">중단된 작업은 마이페이지>>증명서 신청내역 내에서 작업 재개가 가능합니다.</div>
        <div style="width:600px;display: flex;align-items: stretch;flex-direction: column;margin-top:30px;">
          <div style="display: flex;justify-content: space-between;align-items: center;font-size:18px;letter-spacing: -0.6px;margin-bottom:16px;">
            <div style="display: flex;align-items: center;">
              <span style="width:60px;">파일</span>
              {{fileList[0].raw.name}} ({{(fileList[0].raw.size/(1024*1024)).toFixed(2)}} mb)
            </div>
            <a style="color: #000000;" v-if="state == 0">작업 대기 중</a>
            <a style="color: #3B5998;" v-if="state == 1">작업 진행 중</a>
            <a style="color: #3B5998;" v-if="state == 2">작업 완료</a>
            <a style="color: #EC2222;" v-if="state == 3">작업 오류</a>
          </div>
          <div style="font-size:12px;display: flex;justify-content: space-between;align-items: center;">
            <span>0%</span>
            <span>100%</span>
          </div>
          <div class="jdtMain">
            <div :style="'width:' + width + '%'"></div>
          </div>
          <div style="height:24px;align-items: center;">
            <div style="color: #3B5998;font-size:14px;font-weight: bold;" v-if="error == ''">{{process}}</div>
          </div>
        </div>
        <div style="margin-top:50px;font-size: 18px;color: #EC2222;letter-spacing: -0.6px;font-weight: bold;" v-if="error != ''">{{error}}</div>
        <div class="btn ok" v-if="finishCertState" style="width:550px;height:50px;border-radius: 10px;margin:50px 0;" @click="finishCert">확인</div>
        <div class="btn" v-if="!finishCertState" style="width:550px;height:50px;border-radius: 10px;margin:50px 0;">확인</div>
      </div>
    </div>
    <div v-if="!formwin2">
      <div class="title">원본 증명서 발급 신청</div>
      <div class="remark">원본 증명서 신청은 최초 1회 무료입니다.</div>
      <div class="main pc">
        <div class="list-div">
          <div class="flex" v-if="userCI.user_name != null">
            <span>성명(법인명)</span>
            <p>{{userCI.user_name}}</p>
          </div>
          <div class="flex" v-if="userCI.resident_number != null">
            <span>주민등록번호<br/>(사업자 번호)</span>
            <p>{{userCI.resident_number | personFilter}}</p>
          </div>
          <div class="flex" v-if="userCI.business_name != null">
            <span>회사명</span>
            <p>{{userCI.business_name}}</p>
          </div>
          <div class="flex" v-if="userCI.business_number != null">
            <span>사업자등록번호</span>
            <p>{{userCI.business_number}}</p>
          </div>
          <div class="flex">
            <span>영문명(기업명) *</span>
            <div>
              <input @input="validateEng" v-model="requestInfo.englishName"/>
              <span v-if="!validateEng()" style="color:#EC2222;margin-left:6px;">영문</span>
              <img v-if="requestInfo.englishName && validateEng()" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png">
            </div>
          </div>
          <div class="flex">
            <span>전화 번호 *</span>
            <div><input v-model="requestInfo.phoneNumber"/><img v-if="requestInfo.phoneNumber" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png"></div>
          </div>
          <div class="flex">
            <span>전자메일 *</span>
            <div><input v-model="requestInfo.email"/><img v-if="requestInfo.email" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png"></div>
          </div>
          <div class="flex">
            <span>주소 *</span>
            <div><input v-model="requestInfo.address"/><img v-if="requestInfo.address" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png"></div>
          </div>
          <p class="tils">전자 문서 정보</p>
          <div class="flex">
            <span>파일명</span>
            <p style="display: flex;align-items: center;">{{fileItem.fileName}}<a @click="formwin1 = true" v-if="!isEditInfo" style="margin-left:20px;width: 95px;height:24px;border-radius: 35px;color:#ffffff;font-weight: bold;display: flex;align-items: center;justify-content: center;background: #00C999;">파일선택</a></p>
          </div>
          <div class="flex">
            <span>최초 등록일시</span>
            <p>{{fileItem.serviceExpiredDate}}</p>
          </div>
          <div class="flex">
            <span>제출처</span>
            <div><input v-model="requestInfo.whereToSubmit"/><img v-if="requestInfo.whereToSubmit" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png"></div>
          </div>
          <div class="flex">
            <span>제출 용도</span>
            <div><input v-model="requestInfo.reasonOfSubmit"/><img v-if="requestInfo.reasonOfSubmit" style="width:20px;margin-left:10px;height:11px;" src="../assets/img2/validate_ok.png"></div>
          </div>
          <div class="flex" v-if="isSaveFile">
            <span>문서 붙임물 설정</span>
            <div>
              <img style="cursor: pointer;" v-if="requestInfo.attachmentRequested == 0" @click="changeAttachmentRequest(1)" src="../assets/img2/checked.png"/>
              <img style="cursor: pointer;" v-if="requestInfo.attachmentRequested == 1" @click="changeAttachmentRequest(0)" src="../assets/img2/checked_checked.png"/>
            </div>
          </div>
          <div class="flex" v-if="fileItem.isFileContains == 0">
            <span>증명 신청 파일 *</span>
            <div>
              <el-upload
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="changeFileList"
                :multiple="false"
                :show-file-list="false"
                :auto-upload="false">
                <a v-if="fileList.length == 0">
                  파일 업로드
                </a>
                <a v-if="fileList.length > 0">{{fileList[0].name}}</a>
              </el-upload>
              <p style="font-size:14px;">의뢰하신 파일명에 해당 하는 원본 파일을 업로드하여 주세요</p>
            </div>
          </div>
          <div class="flex" v-if="isSaveFile || fileItem.isFileContains == 0">
            <span>전자문서 제공 동의 *</span>
            <div class="flex alignitem"><input style="margin-left:0;" class="checkbox" @change="changeCheck" type="checkbox"/><a class="nohover" style="color:#000000;text-decoration: underline;">동의 합니다.</a></div>
          </div>
          <div class="flex">
            <span>전자 서명 *</span>
            <div class="flex alignitem"><a style="line-height:1;" @click="certFunction" v-if="!certLogin">공동인증서 서명하기</a><a v-if="certLogin" style="line-height:1;color: #00C999;margin:0 0 !important;padding-top:0;display: flex;align-items: center;">서명완료<img style="width:20px;margin-left:10px;" src="../assets/img2/validate_ok.png"></a></div>
          </div>
          <div style="text-align: center;" v-if="isSaveFile || fileItem.isFileContains == 0">
            <a v-loading="btnLoading" @click="submitInfo" :class="'btn ' + (isChecked ? 'ok' : '')">신청하기</a>
          </div>
          <div style="text-align: center;" v-if="!(isSaveFile || fileItem.isFileContains == 0)">
            <a v-loading="btnLoading" @click="submitInfo" class="btn ok">신청하기</a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isShowCertImg">
      <div style="background: rgba(1, 56, 139, 0.20);z-index:99999;width:100vw;height:100vh;left:0;top:0;position: fixed;display: flex;justify-content: center;align-items: center;">
        <div style="background-color: #ffffff;padding:10px;" v-loading="showImgLoading">
          <div style="display: flex;justify-content: flex-end;margin-bottom:20px;">
            <span @click="finishCertEnd" style="color:#01388B;margin-right:20px;display: flex;align-items: center;cursor: pointer;"><img style="width: 20px;margin-right:10px;" src="../assets/img2/cert_save.png"/>발급하기</span>
            <span @click="endCertEnd" style="color:#01388B;display: flex;align-items: center;cursor: pointer;"><img style="width: 20px;margin-right:10px;" src="../assets/img2/cert_close.png"/>발급취소</span>
          </div>
          <div style="width:860px;height:700px;overflow-y: auto;" id="pdfDiv">
            <vue-office-pdf v-if="pdf_src != null && pdf_src != ''" :src="pdf_src" style="width: 100%;"/>
            <div style="padding:30px;" v-if="imgUrl != null && imgUrl != ''">
              <img style="width: 100%;" :src="imgUrl"/>
            </div>
            <pre v-if="txtContent != null && txtContent != ''" style="width:100%;overflow-x: hidden;white-space: pre-wrap;word-wrap: break-word;padding:30px;">{{txtContent}}</pre>
            <vue-office-pdf v-if="pdf_src_content != null && pdf_src_content != ''" :src="pdf_src_content" style="width: 100%;"/>
            <div id="hwpcontent" style="width: 100%;background-color: #ffffff;"></div>
            <vue-office-docx v-if="docx_src != null && docx_src != ''" :src="docx_src" style="width: 100%;"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { v4 as uuidv4 } from 'uuid';
  import { api as viewerApi } from 'v-viewer'
  import Tiff from 'tiff.js'
  import { getAction,putAction,postAction,postFileAction,downFile } from '@/network/manage'
  import { getActionApi,postActionApi,postDownloadActionApi} from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  import { Upload } from 'element-ui'
  import VueOfficePdf from '@vue-office/pdf'
  import VueOfficeDocx from '@vue-office/docx'
  import '@vue-office/docx/lib/index.css'
  import html2Canvas from 'html2canvas'
  import JsPDF from 'jspdf'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import CryptoJS from 'crypto-js'
  export default {
    name: 'MyServiceCert',
    components: {
      elUpload:Upload,
      VueOfficePdf,
      VueOfficeDocx
    },
    data() {
      return {
        formLoading:false,
        customerInfo:null,
        paymentWidget:null,
        fileLoading:false,
        isShowCertImg:false,
        userCI:{},
        user:null,
        plans:[],
        fileItem:{},
        myFileList:[],
        total_progress: 0,
        pdf_src:"",
        pdf_src_content:"",
        finishCertState:false,
        docx_src:"",
        imgUrl:null,
        showImgLoading:false,
        txtContent:null,
        formwin1:false,
        formwin2:false,
        finished:false,
        certLogin:false,
        isEditInfo:false,
        oldHashContent:'',
        isChecked:false,
        btnLoading:false,
        windowConfirm:false,
        purchase_uuid:'',
        requestInfo:{},
        contentInfo:{},
        fileList:[],
        serviceManagementId:'',
        requestId:'',
        width:0,
        msg:'',
        state:1,
        process:'',
        error:'',
        url: {
          contentInfo:'/user/userInformation/contents_originality_request',
          contentInfoUpdate:'/user/userInformation/contents_originality_request_update',
          certificate_information:'/dn-information/certificate-information',
          updateInfo:'/user/userInformation/update_contents_originality_request',
          updateInfoEdit:'/user/userInformation/update_contents_originality_request_re',
          file_list:'/user/userInformation/file_list'
        }
      }
    },
    filters:{
      personFilter:function(value){
        if(value != null)
          return value.substring(0, value.length - 7) + '*******'
        else
          return ''
      },
      companyFilter:function(value){
        if(value != null)
          return value.substring(0, value.length - 5) + '*****'
        else
          return ''
      }
    },
    computed: {
      isSaveFile(){
        if(this.fileItem.fileName){
          const fileName = this.fileItem.fileName.substring(this.fileItem.fileName.lastIndexOf("."));
          if(this.fileItem.isFileContains == 1 && (fileName.toLowerCase() == '.hwp' ||
          fileName.toLowerCase() == '.docx' || fileName.toLowerCase() == '.txt'
           || fileName.toLowerCase() == '.pdf' || fileName.toLowerCase() == '.jpg'
            || fileName.toLowerCase() == '.jpeg' || fileName.toLowerCase() == '.png'
             || fileName.toLowerCase() == '.gif'|| fileName.toLowerCase() == '.tif')){
            return true;
          }
        }
        return false;
      }
    },
    created(){
      const that = this;
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      that.fileLoading = true;
      getAction(that.url.file_list).then((res) => {
        if (res.success) {
          that.myFileList = res.result
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      }).finally(res =>{
        that.fileLoading = false;
      })
      getActionApi('/login-management/user').then((res) => {
        if (res.code == 200) {
          that.user = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      getActionApi(this.url.certificate_information).then((res) => {
        if (res.code == 200) {
          that.userCI = res.data.contents[0];
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })
      if(this.$route.query.uuid){
        this.isEditInfo = true
        this.loadFileInfo(this.$route.query.uuid);
      }

      if(this.$route.query.cuuid){
        this.loadFileInfoByC(this.$route.query.cuuid);
      }

      getAction('/user/servicePlans/plansList').then((res) => {
        if (res.success) {
          that.plans = res.result.plans;
        } else {
          that.$message.error(res.message);
        }
      }).catch(res => {
        that.$message.warning(res.message);
      })

      getAction('/user/userInformation/my_info').then(res => {
        if (res.success) {
          if(res.result.userinfo.userName == null && res.result.userinfo.residentNumber == null && res.result.userinfo.businessName == null && res.result.userinfo.businessNumber == null)
            window.location.href = '/registerIDCert'
          that.customerInfo = res.result.userinfo
          that.paymentWidget = PaymentWidget('live_ck_YZ1aOwX7K8mzvBWmy6A3yQxzvNPG', that.customerInfo.id);
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        that.$message.warning(err.message);
      })
    },
    methods:{
      validateEng(){
        return /^[a-zA-Z\s0-9]+$/.test(this.requestInfo.englishName);
      },
      handleClose(done) {
        this.$confirm('로드를 중단하시겠습니까?')
        .then(_ => {
          this.now_uuid_file = 0
          this.total_progress = 0
          done();
        })
        .catch(_ => {});
      },
      arrayBufferToWordArray(ab) {
        var i8a = new Uint8Array(ab);
        var a = [];
        for (var i = 0; i < i8a.length; i += 4) {
          a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
        }
        return CryptoJS.lib.WordArray.create(a, i8a.length);
      },
      selectFile(item){
        this.fileItem = item;
        this.$set(this.requestInfo,'hashedContents',item.electricFingerPrint);
        this.$set(this.requestInfo,'serviceContentsUuid',item.contentUuid);
        this.$set(this.requestInfo,'attachmentRequested',0);
        this.formwin1 = false
      },
      changeAttachmentRequest(state){
        this.$set(this.requestInfo,'attachmentRequested',state);
      },
      changeFileList(file,fileList){
        this.$set(this.fileList,0,file);
      },
      gbsx(){
        this.goPage('/myService');
      },
      changeCheck(e){
        this.isChecked = e.target.checked;
      },
      goPage(url){
        this.$router.push({path:url});
      },
      certFunction(){
        this.loginByCert();
      },
      loginByCert(){
        //调起证书
        const that = this;
        this.loginType = 1;
        nxTSPKI.signData('hash', {}, function(res) {
          that.sign_complete_callback(res);
        });
      },
      sign_complete_callback(res) {
        this.msg = '';
        const that = this;
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;
          param.isValidateCertLogin = true;
          //保存签证信息
          that.formLoading = true;
          postAction('/user/userInformation/auto_login_validate',param).then((res) => {
            if (res.success) {
              Vue.ls.set(CERT_LOGIN, true, 10 * 60 * 60 * 1000);
              that.formLoading = false;
              that.certLogin = true;
            } else {
              that.$message.error(res.message);
              that.formLoading = false;
            }
          }).catch(res => {
            that.$message.error(res.message);
            that.formLoading = false;
          })
        }else {
            alert("error code = " + res.code + ", message = " + res.errorMessage);
        }
      },
      submitInfo(){
        const that = this;
        if(!that.certLogin){
          that.$message.warning('인증서 서명이 요구됩니다.');
          return;
        }
        //验证
        if(!that.fileItem.fileName){
          that.$message.warning('파일을 선택하십시오');
          return;
        }else if(!that.requestInfo.englishName){
          that.$message.warning('영문명(기업명) 입력하세요');
          return;
        }else if(!that.requestInfo.phoneNumber){
          that.$message.warning('전화 번호 입력하세요');
          return;
        }else if(!that.requestInfo.email){
          that.$message.warning('전자메일 입력하세요');
          return;
        }else if(!that.requestInfo.address){
          that.$message.warning('주소 입력하세요');
          return;
        }
        if(that.fileItem.isFileContains == 0 && that.fileList.length == 0){
          that.$message.warning('해당하는 원본 파일을 업로드 하여 주세요');
          return;
        }
        if((that.isSaveFile || that.fileItem.isFileContains == 0) && !that.isChecked){
          return;
        }
        that.btnLoading = true;
        if(this.$route.query.uuid){
          postAction(this.url.updateInfoEdit,that.requestInfo).then((res) => {
            if (res.result) {
              that.btnLoading = false;
              that.serviceManagementId = res.result.serviceManagementId
              that.requestId = res.result.requestId
              that.startToDu();
            } else {
              that.$message.error(res.message);
              that.btnLoading = false;
            }
          }).catch(res => {
            that.$message.warning(res.message);
            that.btnLoading = false;
          })
        }else{
          postAction(this.url.updateInfo,that.requestInfo).then((res) => {
            if (res.result) {
              that.btnLoading = false;
              that.serviceManagementId = res.result.serviceManagementId
              that.requestId = res.result.requestId
              if(res.result.tossPayment == 0){
                if(res.result.paymentAll > 0)
                  that.sendMsg(res.result.paymentAll,res.result.transactionType);
                that.startToDu();
              }else{
                //调用支付
                that.paymentWidget.renderPaymentMethods('#payment-method', res.result.tossPayment);
                that.paymentWidget.requestPayment({
                  orderId: res.result.transactionUuid,
                  orderName: 'Cert Service',
                  //successUrl: 'https://www.onnuriip.com/paySuccess',
                  //failUrl: 'https://www.onnuriip.com/payFail',
                  customerEmail: that.user.email,
                  customerName: that.customerInfo.userId
                }).then(function (data) {
                  // 성공 처리: 결제 승인 API를 호출하세요
                  const param = {
                    orderId:data.orderId,
                    paymentKey:data.paymentKey,
                    amount:data.amount
                  }
                  putAction('/user/userInformation/company_transaction',param).then((ress) => {
                    if (ress.success) {
                      that.sendMsg(res.result.paymentAll,res.result.transactionType);
                      that.startToDu();
                    } else {
                      that.$message.warning('결제 실패');
                    }
                  }).catch(res => {
                    that.$message.warning('결제 실패');
                  })
                }).catch(function (error) {
                  that.$message.warning('결제 실패');
                  putAction('/user/userInformation/cancel_transaction/'+res.result.transactionUuid).then((ress) => {
                  }).catch(res => {
                  })
                })
              }
            } else {
              that.$message.error(res.message);
              that.btnLoading = false;
            }
          }).catch(res => {
            that.$message.warning(res.message);
            that.btnLoading = false;
          })
        }
      },
      sendMsg(moneyAll,payType){
        const that = this;
        //发送邮件
        getAction('/user/userInformation/sendEmailToss',{fileName:that.fileItem.fileName,serviceName:'원본증명서 발급',email:that.user.email}).then((ress) => {

        }).catch(res => {
        })

        if(that.user.phone_number != null){
          getAction('/user/userInformation/sendMessageToss',{phone:that.user.phone_number,money:moneyAll,payType:payType}).then((res) => {
          }).catch(res => {
          })
        }
      },
      loadFileInfo(uuid){
        const that = this;
        that.formLoading = true;
        getAction(this.url.contentInfoUpdate,{uuid:uuid}).then((res) => {
          if (res.success) {
            this.fileItem = res.result.content;
            this.requestInfo = res.result.requestInfo;
            this.$set(this.requestInfo,'hashedContents',res.result.content.electricFingerPrint);
            this.$set(this.requestInfo,'serviceContentsUuid',uuid);
            this.$set(this.requestInfo,'attachmentRequested',0);
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.formLoading = false;
        })
      },
      loadFileInfoByC(uuid){
        const that = this;
        that.formLoading = true;
        getAction(this.url.contentInfo,{uuid:uuid}).then((res) => {
          if (res.success) {
            this.fileItem = res.result.content;
            this.$set(this.requestInfo,'hashedContents',res.result.content.electricFingerPrint);
            this.$set(this.requestInfo,'serviceContentsUuid',uuid);
            this.$set(this.requestInfo,'attachmentRequested',0);
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.formLoading = false;
        })
      },
      fileToHash(contractFile){
        let reader = new FileReader(), self = this;
        let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;
        let chunkSize = 10 * 1024 * 1024;
        let chunks = Math.ceil(contractFile.size / chunkSize);
        let currentChunk = 0;
        let hasher = CryptoJS.algo.SHA256.create();
        let start = currentChunk * chunkSize;
        let end = Math.min(start + chunkSize, contractFile.size);
        reader.readAsArrayBuffer(blobSlice.call(contractFile, start, end));
        reader.onload = function (evt) {
          let fileStr = evt.target.result;
          let tmpWordArray = self.arrayBufferToWordArray(fileStr);
          hasher.update(tmpWordArray);
          currentChunk += 1;
          fileStr = null;
          tmpWordArray = null;
          if (currentChunk < chunks) {
            start = currentChunk * chunkSize;
            end = Math.min(start + chunkSize, contractFile.size);
            reader.readAsArrayBuffer(blobSlice.call(contractFile, start, end));
          }else{
            const hashStr = hasher.finalize().toString();
            self.width = 100
            if(self.requestInfo.hashedContents == hashStr){
              //进行下一步
              self.finishCertState = true
              self.finishCert();
            }else{
              self.msg = ''
              self.state = 2
              self.process = ''
              self.error = '작업 실패 :  원본과 일치하지 않습니다.'
            }
          }
        }
      },
      async finishCert(){
        //查询信息
        const that = this
        let fileName = "cert"
        that.showImgLoading = true
        that.isShowCertImg = true
        that.pdf_src = ""
        that.imgUrl = ""
        that.txtContent = ""
        that.pdf_src_content = ""
        that.docx_src = ""
        that.total_progress = 0
        document.getElementById("hwpcontent").innerHTML = ""
        let params = {id: that.requestId}
        downFile("/user/userInformation/show_cert_view_before",params).then((data)=>{
          if (!data) {
            item.$message.warning("파일 다운로드 실패")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/pdf'}), fileName + '.pdf')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/pdf'}))
            if(that.requestInfo.attachmentRequested == 1){
              //预览文件
              that.pre_view(that.fileItem.uploadedFileUuid,that.fileItem.fileName)
            }else{
              that.showImgLoading = false;
            }
            that.pdf_src = url
          }
        })
      },
      get_file_type(fileName) {
        let num = fileName.split(".").length - 1
        return fileName.split(".")[num];
      },
      get_type(fileName) {
        let suffix_name = this.get_file_type(fileName).toLowerCase();
        //其他类型
        let type = -1;
        if(suffix_name == 'jpg' || suffix_name == 'jpeg'
        || suffix_name == 'png' || suffix_name == 'gif'){
          //图片
          type = 1
        } else if(suffix_name == 'tif') {
          //tif图像
          type = 5
        } else if(suffix_name == 'txt') {
          type = 6
        } else if(suffix_name == 'pdf') {
          type = 7
        }else if(suffix_name == 'docx') {
          type = 9
        } else if(suffix_name == 'hwp') {
          type = 10
        }
        return type;
      },
      async pre_view(uuid, fileName) {
        let that = this
        try {
           let type = this.get_type(fileName)
           if(type == -1) {
             //-1的情况都是无法预览饿提示无法预览
             //this.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
             return;
           }
           this.total_progress = Math.floor(Math.random()*10)

           let uuid_new = uuidv4()
           this.now_uuid_file = uuid_new
           let blob = await this.load_resources(uuid)
           if(this.now_uuid_file == uuid_new) {
             const url = window.URL.createObjectURL(blob)
             if(type == 1) {
               this.imgUrl = url;
             }else if(type == 5) {
               var reader = new FileReader();
               reader.readAsArrayBuffer(blob);
               reader.onload = function() {
                 try{
                    const tiff = new Tiff({ buffer: this.result })
                    that.imgUrl = tiff.toDataURL();
                 } catch(e) {
                   //that.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
                 }
               }
             } else if(type == 6) {
               blob.text().then(data =>{
                 this.txtContent = data
               })
             } else if(type == 7) {
               this.pdf_src_content = url
             } else if(type == 9) {
               this.docx_src = url
             } else if(type == 10) {
                //hwp
                var reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = function() {
                  try {
                    new HWP.Viewer(document.getElementById("hwpcontent"),new Uint8Array(this.result), { type: 'array' })
                  } catch(e) {
                    that.$message.warning("해당문서는 붙임물 열람이 불가합니다. 다운로드해서 사용하시고 추가진행을 원하실 경우 문의바랍니다.")
                  }
                }
             }
              this.isShowCertImg = true;
              this.showImgLoading = false;
           }
        } catch (e) {
          //that.$message.warning("열람을 지원하지 않는 파일 형식입니다.")
        }
      },
      async load_resources(uuid){
        let fileBlob = []
        const tasks = [];
        const that = this;
        //总片数
        let chunks = 0;
        //当前下载数量
        let downloaded = 0;
        let obj = await postActionApi('/hash-generator/download/initial-create',{"contents_file_upload_uuid": uuid})
        //获得总片数
        chunks = obj.data.contents.piece_information.length
        if(obj.code == 200) {
          let file_array = obj.data.contents.piece_information
          for (let index = 0; index < file_array.length; index++) {
            tasks.push(
              new Promise((resolve, reject) => {
                let downData = {download_order: file_array[index].download_order}
                const params = {"download_piece_uuid": file_array[index].contents_file_download_piece_information}
                this.downLoadbyPiece(params).then(res => {
                  downData.data = res
                  fileBlob.push(downData)
                  //已下载数量+1
                  downloaded++;
                  //计算总进度
                  this.total_progress = Math.floor((downloaded / chunks) * 100);
                  resolve()
                }).catch(err => {
                  reject(err)
                })
              })
            )
          }
          await Promise.all(tasks);
          let parts = fileBlob.sort((a, b) => a.download_order - b.download_order)
          let fileBlobDatas = []
          for (let index = 0; index < parts.length; index++) {
            fileBlobDatas.push(parts[index].data)
          }
          const blob = new Blob(fileBlobDatas)
          return blob;
        }
      },
      downLoadbyPiece(params) {
        return postDownloadActionApi('/hash-generator/download/piece-download',params);
      },
      async finishCertEnd(){
        this.showImgLoading = true
        const res = await postActionApi('/get-timestamp/verification',{service_management_uuid:this.serviceManagementId,request_uuid:this.requestId});
        if(res.code == 200){
          this.goPage('/myCert');
        }else{
          this.$message.error(res.message);
        }
      },
      endCertEnd(){
        this.goPage('/myFile');
      },
      async startToDu(){
        //判断是否传了附件
        if(this.fileItem.isFileContains == 0){
          this.formwin2 = true
          this.msg = ''
          this.state = 1
          this.process = '전자 지문 추출 중'
          this.width = 0
          this.fileToHash(this.fileList[0].raw)
        }else{
          //进行下一步
          this.finishCert();
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .jdtMain{height:16px;background-color: #D9D9D9;display: flex;justify-content: flex-start;margin:6px 0;}
  .jdtMain div{height:16px;background-color: #00C999;width:0;transition: all 0.2 linear;}
  .window-confirm{display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;width: 760px;min-height:calc(100vh - 206px);padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .process-list{margin-top:40px;border: 1px solid #F0F0F0;border-radius: 20px;width:500px;}
  .window-confirm .window .process-list .item{border-top:1px solid #F0F0F0;padding:10px 16px;display: flex;justify-content: space-between;align-items: center;}
  .window-confirm .window .process-list .item a{width:30px;height:30px;flex-shrink: 0;background-image: url(../assets/img/processing_wait.png);background-repeat: no-repeat;background-position: center;background-size: 15px;}
  .window-confirm .window .process-list .item div{display: flex;}
  .window-confirm .window .process-list .finish a{background-image: url(../assets/img/processing_ok.png);background-size: 20px;}
  .window-confirm .window .process-list .finish *{color:#3B5998 !important;}
  .window-confirm .window .process-list .error a{background-image: url(../assets/img/processing_error.png);}
  .window-confirm .window .process-list .error *{color:#C90000 !important;}
  .window-confirm .window .process-list .loading a{background-image: url(../assets/img/processing_wait.png);animation: spin 1.5s linear infinite;-webkit-animation: spin 1.5s linear infinite;}
  @keyframes spin{
    from{transform: rotate(0deg);}
    to{transform: rotate(360deg);transition: all 1.5s;}
  }
  .pro-remark{font-family: 'Pretendard';color: #797979;margin:10px 0 30px 0;letter-spacing: -0.6px;}
  .result{font-family: 'Pretendard';color: #3B5998;margin:20px 0 10px 0;}
  .result a{color:#F46969;}
  .window-confirm .window .process-list .item div span{flex-shrink: 0;margin-right:14px;color: #888888;}
  .window-confirm .window .process-list .item div p{color: #888888;}
  .window-confirm .window .process-list .item:nth-child(1){border-top-width:0px;}


  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .title2{margin:16px 0;}
  .window-confirm .window .link{color:#1296DB;cursor: pointer;}

  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius:30px;width:530px;height:270px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px 0;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;margin-right:14px;}
  .windform .conts p{font-size: 24px;font-weight: bold;margin:80px 0 50px 0;}
  .windform .conts .main{width:100%;flex-direction: column;padding:0 30px;font-size: 15px;align-items:center;}
  .windform .conts .main .list{height:260px;overflow-y: auto;width:100%;}
  .windform .conts .main .ok{background-color: #01388B !important;cursor: pointer;}
  .windform .conts .main .btn{width:300px;height: 40px;font-size:18px;border-radius:10px;margin-top:20px;background-color: #C4C4C4;color:#ffffff;display: flex;justify-content: center;align-items: center;}
  .windform .conts .row{display: flex;align-items: center;width:96%;margin:auto;}
  .windform .conts .row span{display: flex;justify-content:center;align-items: center;padding:8px 0;}
  .windform .conts .row span a{color:#00C999;}
  .windform .conts .row span:nth-child(1){width:35%;justify-content: flex-start;word-break: break-all;}
  .windform .conts .row span:nth-child(2){width:30%;}
  .windform .conts .row span:nth-child(3){width:30%;}
  .windform .conts .row span:nth-child(4){width:14%;}
  .windform .conts .header{padding:13px 20px 13px 0;color:#5F5F5F;font-weight: bold;border-bottom:1px solid rgba(72, 83, 100, 0.46);margin-bottom:10px;}

  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;width:600px;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;text-align: center;}
  .remark{font-size: 18px;text-align: center;margin-bottom: 20px;}
  .main{display: flex;}
  .main{display: flex;margin-top:20px;}
  .main .list-div{width:100%;}
  .main .list-div input{border: 1px solid #C4C4C4;width:322px;height:36px;padding:0 10px;font-size:14px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div div{margin:10px 0;}
  .main .list-div p{margin:10px 0;}
  .main .list-div .flex{display: flex;margin:0 0;}
  .main .list-div .flex span{width:200px;margin:10px 0;flex-shrink: 0;line-height:1;padding-top:10px;}
  .main .list-div .flex p{padding-top:10px;line-height:1;}
  .main .list-div .cont p{font-size:20px;margin:40px 0 30px 0;}
  .tils{font-size:18px;}
  .tils2{font-size:22px;margin-bottom:20px !important;font-weight: bold;}
  .checkbox{width:20px !important;height:20px;margin:0 10px;}
  .alignitem{align-items: center;padding-top:10px;}
  .btn{background: #797979;color:#ffffff !important;margin:50px auto 0 auto;font-size:20px;width:500px;height:50px;border-radius: 10px;display: flex;justify-content: center;align-items: center;}
  .ok{background-color: #01388B;cursor: pointer;}
  .file{width:96px;height:100px;border:3px dashed #888888;display: flex;flex-direction: column;align-items: center;justify-content: center;font-size:14px;font-family: 'SF UI Display';cursor: pointer;}
  .file img{margin:6px 0;}
  .nohover:hover{cursor: default !important;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:600px;}
    .title{font-size:20px;}
    .main .menu a{font-family: 'Pretendard';font-size: 16px;color: #888888;margin:10px 0;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:600px;}
  }
</style>
