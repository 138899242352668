<template>
  <div class="body" v-loading="formLoading">
    <div class="content">
      <div class="info">
        <div class="title" v-if="!payComplete">결제가 완료되었습니다.</div>
        <div class="title" v-if="payComplete">결제가 완료되었습니다.</div>
        <div class="btn" v-if="payComplete && urlType == 0" @click="goPage('/')">complete</div>
        <div class="btn" v-if="payComplete && urlType == 1" @click="goPageCert">complete</div>
        <div class="btn" v-if="payComplete && urlType == 2" @click="goPage('/myService')">complete</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { putAction,getAction,postAction,postTossAction } from '@/network/manage'
  import { putActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'paySuccess',
    components: {
    },
    data() {
      return {
        formLoading:false,
        payComplete:false,
        urlType:0,
        uuid:'',
        url: {
          emailCode:'verification/request-email-verification-code',
          date_extention:'/get-timestamp/date-extention'
        }
      }
    },
    computed: {

    },
    created(){
      const that = this;
      //调用付款确认
      const data = {
        orderId:this.$route.query.orderId,
        paymentKey:this.$route.query.paymentKey,
        amount:parseInt(this.$route.query.amount)
      }
      const orderId = this.$route.query.orderId;
      postTossAction('https://api.tosspayments.com/v1/payments/confirm',JSON.stringify(data)).then(res => {
        if(res.status == 200){
          data.approveNo = parseInt(res.data.card.approveNo);
          postAction('/user/userInformation/purchase_callback/', data).then(res => {
            if(res.success){
              if(res.result.serviceContentsUuid != null){
                that.uuid = res.result.contentsOriginalityRequestUuid;
                that.urlType = 1;
                that.payComplete = true;
                setTimeout(function(){
                  that.$router.push({name:'myServiceCert',query:{uuid:this.uuid}});
                },3000);
              }else if(res.result.servicePlanOwnUuid != null){
                that.urlType = 0;
                that.payComplete = true;
                setTimeout(function(){
                  that.goPage('/');
                },3000);
              }else{
                putActionApi(that.url.date_extention,{contents_uuid:res.result.hashedServiceContentsUuid,service_extension_purchase_status:res.result.serviceExtensionPurchaseStatusUuid}).then(res => {
                  if (res.code == 200) {
                    that.urlType = 2;
                    that.payComplete = true;
                    setTimeout(function(){
                      that.goPage('/myService');
                    },3000);
                  } else {
                    that.$message.error(res.message);
                  }
                }).catch(err => {
                  that.$message.warning(err.message);
                })
              }
            }else{
              that.$message.warning(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        }else{
          that.$message.warning('결제 실패');
        }
      }).catch(err => {
        that.$message.warning('결제 실패');
      }).finally(() => {
        that.formLoading = false;
      })

    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      goPageCert(){
        this.$router.push({name:'myServiceCert',query:{uuid:this.uuid}});
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:60px 0 30px 0;font-family: 'Pretendard';font-weight: bold;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'SF UI Display';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:38px;padding:0 10px 0 0;display: flex;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:36px !important;}
  .box-item a{flex-shrink: 0;color:#0020C9;font-family: 'Pretendard';font-size:15px;cursor: pointer;font-weight: bold;}
  .link{font-family: 'Pretendard';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;font-family: 'Pretendard';}
  .content .info .item input{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:38px;padding:0 10px;font-size:13px;}
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;cursor: pointer;height: 40px;line-height:1;margin:60px 0 10px 0;color:#ffffff;background: #01388B;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
