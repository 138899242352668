import Vue from 'vue'
import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  timeout: 10000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    Notification.error({title: '온누리국제영업비밀보호센터 알림', message: error.message })
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Basic bGl2ZV9za19PQUxuUXZEZDJWSm9iTFA3N293ck1qN1g0MW1OOg=='
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response
}, err)

export default service
