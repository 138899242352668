<template>
  <div class="body" v-loading="formLoading">
    <div class="windform" v-if="formwin1">
      <div class="conts">
        <div class="clos">
          <img @click="formwin1 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p>공동 인증서 로그인이 요구되는 서비스 입니다.</p>
        <div @click="goLogin" style="width: 160px;height: 40px;border-radius: 3px;background-color: #01388B;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          확인
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin4">
      <div class="conts" style="height:240px;width:500px;">
        <div class="clos">
          <img @click="formwin4 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">업로드 실패 : 파일변경 불가</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>최초 신청한 파일을 업로드하여 주세요</a>
        <div @click="formwin4 = false" style="width: 160px;height: 40px;margin-top:20px;border-radius: 3px;background-color: #01388B;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          확인
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin2">
      <div class="conts" style="height:200px;width:430px;">
        <div class="clos">
          <img @click="formwin2 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0">해당 파일을 삭제 하시겠습니까?</p>
        <div @click="removeFileConfirm" style="width: 95px;height: 24px;border-radius: 12px;background-color: #C90000;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          삭제하기
        </div>
      </div>
    </div>
    <div class="windform" v-if="formwin3">
      <div class="conts" style="height:200px;width:430px;">
        <div class="clos">
          <img @click="formwin3 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">보관가능 용량을 초과하였습니다.</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;" src="../assets/img2/wh.png"/>1개 파일 당 2GB까지 보관이 가능합니다.</a>
        <div @click="formwin3 = false" style="width: 95px;height: 24px;border-radius: 12px;margin-top:20px;background-color: #01388B;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          확인
        </div>
      </div>
    </div>
    <div class="pc window-confirm" v-if="windowConfirm == 2">
      <div class="window">
        <div style="color: #3B5998;letter-spacing: -0.6px;font-weight: bold;font-size: 35px;margin:20px 0;">PROCESSING</div>
        <div style="font-size: 18px;letter-spacing: -0.6px;margin:4px 0;">프로세스 진행 중 페이지 이탈 시 작업이 중단 됩니다.</div>
        <div style="font-size: 18px;letter-spacing: -0.6px;margin:4px 0;margin-bottom:50px;">중단된 작업은 마이페이지 내에서 작업 재개가 가능합니다.</div>
        <div v-for="(item,i) in fileList" :key="i" style="width:600px;display: flex;align-items: stretch;flex-direction: column;margin-top:30px;">
          <div style="display: flex;justify-content: space-between;align-items: center;font-size:18px;letter-spacing: -0.6px;margin-bottom:16px;">
            <div style="display: flex;align-items: center;">
              <span style="width:60px;">파일{{i + 1}}.</span>
              {{item.name}} ({{(item.size/(1024*1024)).toFixed(2)}} mb)
            </div>
            <a style="color: #000000;" v-if="item.state == 0">작업 대기 중</a>
            <a style="color: #3B5998;" v-if="item.state == 1">작업 진행 중</a>
            <a style="color: #3B5998;" v-if="item.state == 2">작업 완료</a>
            <a style="color: #EC2222;" v-if="item.state == 3">작업 오류</a>
          </div>
          <div style="font-size:12px;display: flex;justify-content: space-between;align-items: center;">
            <span>0%</span>
            <span>100%</span>
          </div>
          <div class="jdtMain">
            <div :style="'width:' + item.width * 100 / item.max_width + '%'"></div>
          </div>
          <div style="height:24px;align-items: center;">
            <div style="color: #EC2222;font-size:14px;font-weight: bold;" v-if="item.error != ''">{{item.error}}</div>
            <div style="color: #3B5998;font-size:14px;font-weight: bold;" v-if="item.error == ''">{{item.process}}</div>
          </div>
        </div>
        <div style="margin-top:50px;font-size: 18px;color: #00C999;letter-spacing: -0.6px;font-weight: bold;" v-if="!workFinished">작업 예상 시간 : {{timeDjs / 60 > 9 ? parseInt(timeDjs/60) : ('0' + parseInt(timeDjs/60))}}:{{timeDjs % 60 > 9 ? timeDjs % 60 : ('0' + timeDjs % 60)}}</div>
        <div style="margin-top:50px;font-size: 18px;color: #00C999;letter-spacing: -0.6px;font-weight: bold;" v-if="workFinished">작업이 완료 되었습니다.</div>
        <div class="btn ok" v-if="workFinished" style="width:550px;height:50px;border-radius: 10px;margin:50px 0;" @click="goPage('myFile')">확인</div>
        <div class="btn" v-if="!workFinished" style="width:550px;height:50px;border-radius: 10px;margin:50px 0;">확인</div>
      </div>
    </div>
    <div v-if="windowConfirm == 0">
      <div class="top-img">
        <div class="div">
          <a>영업 비밀 보관 서비스</a>
          <p>당신의 비밀을 온누리에 안전하게 보관하세요</p>
        </div>
      </div>
      <div class="content pc">
        <div class="upload-icon">
          <img style="width:100px;" src="../assets/img2/file-upload-export-icon_1.png"/>
          <p style="font-size: 28px;font-weight: bold;color:#000000;margin:20px 0;">보관 의뢰하실 파일을 업로드 하여 주세요</p>
          <div class="remark" style="font-size:15px;font-weight: bold;">
            <div style="line-height: 1.5;">모든 확장자의 파일은 보관이 가능하나,</div>
            <div style="color: #01388B;line-height: 1.5;">* 열람은 아래의 확장자 파일만 가능하니 참고하시기 바랍니다.</div>
            <div style="line-height: 1.5;">문서 : hwp, docx, xlsx, txt, pdf</div>
            <div style="line-height: 1.5;">이미지 : jpg, jpeg, png, gif, psd, tif</div>
            <div style="line-height: 1.5;">영상 : mov, mp4, mkv</div>
            <div style="line-height: 1.5;">오디오 : mp3, flac, wav</div>
            <br/>
            <br/>
            <div style="color: #408D10;line-height: 1.5;">* 원본증명서 붙임물 인쇄는 아래의 확장자 파일만 가능하니 참고하시기 바랍니다.</div>
            <div style="line-height: 1.5;">문서 : hwp, docx, txt, pdf</div>
            <div style="line-height: 1.5;">이미지 : jpg, jpeg, png, gif, tif</div>
            <br/>
            <br/>
            <div style="color:#D94925;">* 파일명은 “영문”과 “숫자”만 지원 됩니다.</div>
            <br/>
            <br/>
            <div style="line-height: 1.5;">* 문서파일의 경우 도형이나 이미지를 포함하고 있는 경우 열람이나 붙임물</div>
            <div style="line-height: 1.5;">제한될 수 있으니 가능한 PDF 형식으로 보관하시는 것을 권장합니다.</div>
            <br/>
          </div>
          <div class="btn" v-if="userCI.dn_uuid == null">
            <div @click="uploadCertPage" style="display: block;line-height:44px;width:177px;color:#ffffff;cursor: pointer;text-align: center;">업로드</div>
          </div>
          <div class="btn" v-if="userCI.dn_uuid != null && !certLogin">
            <div @click="formwin1 = true" style="display: block;line-height:44px;width:177px;color:#ffffff;cursor: pointer;text-align: center;">업로드</div>
          </div>
          <el-upload v-if="userCI.dn_uuid != null && certLogin"
            class="btn"
            ref="upload"
            action=""
            :on-change="changeFileList"
            :multiple="false"
            :limit="20"
            :show-file-list="false"
            :auto-upload="false">
            <div style="display: block;line-height:44px;width:177px;color:#ffffff;">업로드</div>
          </el-upload>
        </div>
        <div class="title" v-if="fileList.length > 0">업로드된 파일</div>
        <p v-if="fileList.length > 0" style="text-align: center;color: #00C999;font-weight: bold;line-height: 1;margin:10px 0 30px 0;">파일갯수 : {{fileList.length}}개</p>
        <div class="file-list" v-if="fileList.length > 0">
          <div class="item" v-for="(item,i) in fileList" :key="i">
            <div class="left">
              <img src="../assets/img/info.png"/>
              <span>File {{i+1}}.</span>
              <p>{{item.name}}</p>
            </div>
            <div class="right">
              <span>{{(item.size/(1024*1024)).toFixed(2)}}MB</span>
              <img @click="removeFile(i)" src="../assets/img/x_2.png"/>
            </div>
          </div>
        </div>
        <div v-if="fileList.length > 0" :class="fileList.length == 0 ? 'btn' : 'ok btn'" @click="openForm1">제출하기</div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { putAction,getAction,postAction,postFileAction } from '@/network/manage'
  import { postActionApi,getActionApi,deleteActionApi } from '@/network/manageApi'
  import { Upload } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import CryptoJS from 'crypto-js'
  import '@/assets/js/jsencryptKey'
  export default {
    name: 'ReUpload',
    components: {
      elUpload:Upload
    },
    data() {
      return {
        nowid:null,
        windowConfirm: 0,
        viewHashedContentsFailed:null,
        formwin1:false,
        formwin2:false,
        formwin3:false,
        formwin4:false,
        indexIds:[],
        myCount:0,
        workFinished:false,
        certLogin:false,
        uuid:null,
        userCI:{},
        plansList:[],
        discountList:[],
        nowIndex:0,
        timeDjs:0,
        isChecked:false,
        serviceManagement:[],
        formLoading:false,
        fileList:[]
      }
    },
    computed: {
      moneyAll(){
        let moneyAll = 0;
        for(let i = 0; i < this.fileList.length; i++){
          moneyAll += parseInt(this.itemPrice(i));
        }
        return moneyAll;
      },
      discountMoney(){
        let discountMoney = 0;

        let moneyAll = 0;
        for(let i = 0; i < this.fileList.length; i++){
          moneyAll += parseInt(this.itemPrice(i));
        }

        for(let i = 0; i < this.discountList.length; i++){
          if(moneyAll >= this.discountList[i].priceMinimum)
            discountMoney = this.discountList[i].discountedPrice
        }

        return discountMoney;
      },
      okCount(){
        let cou = 0;
        for(let i = 0; i < this.fileList.length; i++){
          if(this.fileList[i].validateState == 1)
            cou ++;
        }
        return cou;
      },
      errCount(){
        let cou = 0;
        for(let i = 0; i < this.fileList.length; i++){
          if(this.fileList[i].validateState == -1)
            cou ++;
        }
        return cou;
      }
    },
    created(){
      this.certLogin = Vue.ls.get(CERT_LOGIN);
      this.loadOwnPlan()
      this.loadUserCIInfo()
      if(this.$route.query.nowid != null && this.$route.query.nowid != undefined)
        this.nowid = this.$route.query.nowid;
    },
    methods:{
      openForm1(){
        //验证
        const regex = /^[\w\-\. ]+$/;
        for(let i = 0;i<this.fileList.length;i++){
          if(!regex.test(this.fileList[i].name)){
            this.$message.error('파일명은 "영문"과 "숫자"만 지원됩니다.');
            return;
          }
        }

        if(this.fileList.length > 1){
          this.formwin4 = true;
          return;
        }

        if(this.fileList.length > 0)
          this.startToDu();
      },
      itemPrice(index){
        let itemPrice = 0;
        if(this.fileList[index].years == 3){
          itemPrice += this.plansList[18].servicePrice;
          if(this.fileList[index].saveFile == 1){
            let fileSize = this.fileList[index].size/(1024*1024);
            if(fileSize <= this.plansList[0].fileSize)
              itemPrice += this.plansList[0].servicePrice
            else if(fileSize <= this.plansList[1].fileSize)
              itemPrice += this.plansList[1].servicePrice
            else if(fileSize <= this.plansList[2].fileSize)
              itemPrice += this.plansList[2].servicePrice
            else if(fileSize <= this.plansList[3].fileSize)
              itemPrice += this.plansList[3].servicePrice
            else if(fileSize <= this.plansList[4].fileSize)
              itemPrice += this.plansList[4].servicePrice
            else if(fileSize <= this.plansList[5].fileSize)
              itemPrice += this.plansList[5].servicePrice
          }
        }else if(this.fileList[index].years == 7){
          itemPrice += this.plansList[19].servicePrice;
          if(this.fileList[index].saveFile == 1){
            let fileSize = this.fileList[index].size/(1024*1024);
            if(fileSize <= this.plansList[6].fileSize)
              itemPrice += this.plansList[6].servicePrice
            else if(fileSize <= this.plansList[7].fileSize)
              itemPrice += this.plansList[7].servicePrice
            else if(fileSize <= this.plansList[8].fileSize)
              itemPrice += this.plansList[8].servicePrice
            else if(fileSize <= this.plansList[9].fileSize)
              itemPrice += this.plansList[9].servicePrice
            else if(fileSize <= this.plansList[10].fileSize)
              itemPrice += this.plansList[10].servicePrice
            else if(fileSize <= this.plansList[11].fileSize)
              itemPrice += this.plansList[11].servicePrice
          }
        }else if(this.fileList[index].years == 10){
          itemPrice += this.plansList[20].servicePrice;
          if(this.fileList[index].saveFile == 1){
            let fileSize = this.fileList[index].size/(1024*1024);
            if(fileSize <= this.plansList[12].fileSize)
              itemPrice += this.plansList[12].servicePrice
            else if(fileSize <= this.plansList[13].fileSize)
              itemPrice += this.plansList[13].servicePrice
            else if(fileSize <= this.plansList[14].fileSize)
              itemPrice += this.plansList[14].servicePrice
            else if(fileSize <= this.plansList[15].fileSize)
              itemPrice += this.plansList[15].servicePrice
            else if(fileSize <= this.plansList[16].fileSize)
              itemPrice += this.plansList[16].servicePrice
            else if(fileSize <= this.plansList[17].fileSize)
              itemPrice += this.plansList[17].servicePrice
          }
        }
        return itemPrice.toFixed(0);
      },
      changeYears(index,years){
        this.$set(this.fileList[index],'years',years);
      },
      changeSaveFile(index){
        if(this.fileList[index].saveFile == 0 && this.fileList[index].size > 2 * 1024 * 1024 * 1024){
          this.formwin3 = true;
          return;
        }
        this.$set(this.fileList[index],'saveFile',this.fileList[index].saveFile == 0 ? 1 : 0);
      },
      uploadCertPage(){
        const that = this;
        this.$messageBox('인증서 등록이 요구 됩니다', 'Warning', {
          confirmButtonText: 'register',
          type: 'warning'
        }).then(() => {
          that.goPage('/registerIDCert');
        })
      },
      uploadCertLogin(){
        const that = this;
        this.$messageBox('인증서 로그인이 요구됩니다', 'Warning', {
          confirmButtonText: 'register',
          type: 'warning'
        }).then(() => {
          that.loginByCert();
        })
      },
      goLogin(){
        this.goPage('/login?loginType=1');
      },
      loginByCert(){
        //调起证书
        const that = this;
        this.loginType = 1;
        nxTSPKI.signData('hash', {}, function(res) {
          that.sign_complete_callback(res);
        });
        /* nxTSPKI.onInit(function(){
          nxTSPKI.signData('테스트용 서명 데이터 입니다.', {}, function(res) {
            that.sign_complete_callback(res);
          });
        }); */
        //nxTSPKI.init(true);
      },
      sign_complete_callback(res) {
        this.msg = '';
        const that = this;
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;
          param.isValidateCertLogin = true;
          //保存签证信息
          that.formLoading = true;
          postAction('/user/userInformation/auto_login_validate',param).then((res) => {
            if (res.success) {
              Vue.ls.set(CERT_LOGIN, true, 10 * 60 * 60 * 1000);
              window.location.reload();
            } else {
              that.$message.error(res.message);
              that.formLoading = false;
            }
          }).catch(res => {
            that.$message.error(res.message);
            that.formLoading = false;
          })
        }else {
            alert("error code = " + res.code + ", message = " + res.errorMessage);
        }
      },
      loadUserCIInfo(){
        const that = this;
        getActionApi('/dn-information/certificate-information').then((res) => {
          if (res.code == 200) {
            that.userCI = res.data.contents[0];
            if(that.userCI.dn_uuid == null){
              that.$messageBox('인증서 등록이 요구 됩니다', 'Warning', {
                confirmButtonText: 'register',
                type: 'warning'
              }).then(() => {
                that.goPage('/registerIDCert');
              })
            }else if(!that.certLogin){
              /* that.$messageBox('인증서 로그인이 요구됩니다', 'Warning', {
                confirmButtonText: 'register',
                type: 'warning'
              }).then(() => {
                that.loginByCert();
              }) */
            }
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      goRequestBank(){
        this.$router.push({path:'/serviceBankMessage'});
      },
      goPage(url){
        this.$router.push({path:url});
      },
      changeFileList(file,fileList){
        this.fileList = fileList;
      },
      showAlert(){
        this.windowConfirm = true;
      },
      removeFile(i){
        //this.$refs.upload.abort(this.fileList[i]);
        this.removeIndex = i;
        this.formwin2 = true;
      },
      removeFileConfirm(){
        this.fileList.splice(this.removeIndex,1);
        this.formwin2 = false;
      },
      async loadOwnPlan(){
        const that = this;
        that.formLoading = true;
        let res = await getAction('/user/servicePlans/plansList');
        if(res.success){
          that.plansList = res.result.plans
        }else{
          that.$message.error(res.message);
        }
        res = await getAction('/user/servicePlans/plansListDiscount');
        if(res.success){
          that.discountList = res.result
        }else{
          that.$message.error(res.message);
        }
        res = await getAction('/user/userInformation/my_info');
        if(res.success){
          that.myCount = res.result.userinfo.userPoints
        }else{
          that.$message.error(res.message);
        }
        that.formLoading = false;
      },
      arrayBufferToWordArray(ab) {
        var i8a = new Uint8Array(ab);
        var a = [];
        for (var i = 0; i < i8a.length; i += 4) {
          a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
        }
        return CryptoJS.lib.WordArray.create(a, i8a.length);
      },
      fileToHash(contractFile,index){
        this.$set(this.fileList[index],'msg','')
        this.$set(this.fileList[index],'state',1);
        this.$set(this.fileList[index],'process','전자 지문 추출 중')

        let reader = new FileReader(), self = this;
        let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;

        let chunkSize = 10 * 1024 * 1024;
        let chunks = Math.ceil(contractFile.size / chunkSize);
        let currentChunk = 0;

        let hasher = CryptoJS.algo.SHA256.create();
        let start = currentChunk * chunkSize;
        let end = Math.min(start + chunkSize, contractFile.size);
        reader.readAsArrayBuffer(blobSlice.call(contractFile, start, end));
        reader.onload = function (evt) {
          let fileStr = evt.target.result;
          let tmpWordArray = self.arrayBufferToWordArray(fileStr);
          hasher.update(tmpWordArray);
          currentChunk += 1;
          fileStr = null;
          tmpWordArray = null;
          if (currentChunk < chunks) {
            self.$set(self.fileList[index],'width',self.fileList[index].width + 1);
            start = currentChunk * chunkSize;
            end = Math.min(start + chunkSize, contractFile.size);
            reader.readAsArrayBuffer(blobSlice.call(contractFile, start, end));
          }else{
            self.$set(self.fileList[index],'width',self.fileList[index].width + 1);
            const hashStr = hasher.finalize().toString();
            if(hashStr == self.viewHashedContentsFailed.electricFingerPrint){
              self.signFunction(hashStr,self.viewHashedContentsFailed.uploadedFileUuid,index);
            }else{
              self.$set(self.fileList[index],'error','파일변경 불가')
              self.$set(self.fileList[index],'state',3)
              self.formwin4 = true;
            }
          }
        }
      },

      async signFunction(hashContent,contents_file_upload_uuid,index){
        let serviceManagementUuid;
        for(let i = 0;i<this.serviceManagement.length;i++){
          if(this.indexIds[i] == index){
            serviceManagementUuid = this.serviceManagement[i].serviceManagementUuid
            break;
          }
        }
        this.$set(this.fileList[index],'error','')
        const param = {
          service_management_uuid:this.viewHashedContentsFailed.serviceManagementUuid,
          file_name:this.fileList[index].name,
          hashed_file:hashContent,
          verification_signature:this.$getCode(hashContent),
          contents_file_upload_uuid:contents_file_upload_uuid
        }
        let res = await postActionApi('/get-timestamp/sign', param);
        if(res.code == 200){
          if(res.data.contents.sha256 != hashContent){
            this.$set(this.fileList[index],'error',res.message)
            this.$set(this.fileList[index],'state',3)
            const contentUuid = res.data.contents.contents_uuid;
            res = await deleteActionApi('/get-timestamp/delete-hashed-contents', {contents_uuid:contentUuid});
          }else{
            this.$set(this.fileList[index],'state',2);
            this.$set(this.fileList[index],'process','')
            this.$set(this.fileList[index],'width',this.fileList[index].max_width);
          }
        }else{
          this.$set(this.fileList[index],'error',res.message)
          this.$set(this.fileList[index],'state',3)
          this.$set(this.fileList[index],'width',this.fileList[index].max_width);
          res = await deleteActionApi('/get-timestamp/sign-failed', {contents_fail_uuid:res.data.error_uuid});
        }
        this.$set(this.fileList[index],'workFinished',true)
        let finishCou = 0;
        for(let i = 0;i<this.fileList.length;i++){
          if(this.fileList[i].workFinished)
            finishCou ++;
        }
        if(finishCou == this.fileList.length)
          this.workFinished = true
      },
      djsFunction(){
        const that = this;
        setTimeout(function(){
          if(that.timeDjs > 0){
            that.timeDjs = that.timeDjs - 1
            that.djsFunction()
          }
        },1000)
      },
      hashGo(){
        this.workFinished = false
        this.windowConfirm = 2
        let timeCurrent = 0;
        for(let i = 0; i < this.fileList.length; i++){
          let fileSize = parseInt(this.fileList[i].size/(1024*1024*10));
          if(this.fileList[i].saveFile == 1){
            fileSize += fileSize * 5;
          }
          this.$set(this.fileList[i],'max_width',fileSize == 0 ? 1 : fileSize);
          fileSize += 1;
          timeCurrent += fileSize;
        }
        this.timeDjs = timeCurrent;
        this.djsFunction();

        for(let i = 0; i < this.fileList.length; i++){
          this.$set(this.fileList[i],'process','오류 검사 중');
          this.$set(this.fileList[i],'state',0);
          this.$set(this.fileList[i],'width',0);
          this.$set(this.fileList[i],'error','');
          this.fileToHash(this.fileList[i].raw,i);
        }
      },
      async startToDu(){
        this.formLoading = true;
        const res = await postAction('/user/userInformation/init_re_upload',{contentUuid:this.nowid})
        this.formLoading = false;
        if(res.success){
          this.viewHashedContentsFailed = res.result;
          this.hashGo();
        }else{
          this.$message.error(res.message);
        }
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;line-height:1;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/upload_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 24px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}

  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius:30px;width:530px;height:270px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px 0;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;}
  .windform .conts p{font-size: 24px;font-weight: bold;margin:80px 0 50px 0;}
  .jdtMain{height:16px;background-color: #D9D9D9;display: flex;justify-content: flex-start;margin:6px 0;}
  .jdtMain div{height:16px;background-color: #00C999;width:0;transition: all 0.5 linear;}
  .content{padding:50px 0 80px 0;margin:auto;width:650px;}
  .content .upload-icon{background: rgba(1, 102, 255, 0.1);border: 1px dashed #01388B;border-radius: 15px;display: flex;flex-direction: column;align-items: center;padding:28px 0 35px 0;}
  .content .upload-icon p{font-size: 22px;color: #01388B;margin-top:5px;}
  .content .upload-icon .remark{margin:10px 0;}
  .content .upload-icon .remark p{font-size: 16px;color: rgba(69, 69, 69, 0.80);font-weight: normal;line-height: 1.5;}
  .content .upload-icon .btn{width: 177px;height: 44px;background: #01388B;border-radius: 8px;font-size: 20px;color:#ffffff !important;display: flex;justify-content: center;align-items: center;margin-top:20px;letter-spacing: -0.6px;}
  .content .title{font-size: 28px;letter-spacing: -1px;margin:20px 0;text-align: center;font-weight: bold;}
  .content .file-list{margin-bottom:40px;}
  .content .file-list .item{margin-bottom:10px;border: 2px dashed #01388B;padding:8px 12px;display: flex;justify-content: space-between;align-items: center;}
  .content .file-list .item .left{display: flex;align-items: center;color: #4A4A4A;font-size: 16px;}
  .content .file-list .item .left img{margin-right:10px;flex-shrink: 0;}
  .content .file-list .item .left span{width:60px;flex-shrink: 0;font-size: 16px;}
  .content .file-list .item .right{flex-shrink: 0;display: flex;align-items: center;font-family: 'Pretendard';color: #888888;font-size: 16px;}
  .content .file-list .item .right img{margin-left:10px;background-color: #D9D9D9;padding:5px;width:18px;cursor: pointer;}
  .content .service-count{border: 1px solid #AFAFAF;border-radius: 20px;padding:10px;display: flex;flex-direction: column;align-items: center;}
  .content .service-count div{width:360px;display: flex;justify-content: space-between;align-items: center;color: #4A4A4A;margin:6px 0;}
  .content .service-count div span{color: #0020C9;}
  .content .service-count hr{width:360px;background-color: #797979;height:1px;border: none;margin:6px 0;}
  .content .btn{width:560px;height:48px;background: #4A4A4A;border-radius: 10px;color:#ffffff;display: flex;justify-content: center;align-items: center;margin:auto;margin-top:60px;font-size: 20px;letter-spacing: 0;}
  .content .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm{display: flex;justify-content: center;align-items: center;}
  .window-confirm .window{background: #FFFFFF;width: 760px;min-height:calc(100vh - 206px);padding:40px;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;}
  .window-confirm .window .close{align-self: flex-end;position: fixed;}
  .window-confirm .window .close img{cursor: pointer;border: 1px solid #D0D0D0;border-radius: 5px;padding:6px;}
  .window-confirm .window .til{font-size: 30px;line-height:1;letter-spacing: 0px;color: #4A4A4A;}
  .window-confirm .window .til a{color:#0020C9;font-size:24px;}
  .window-confirm .window .title{text-align: center;margin:16px 0;}
  .window-confirm .window .process-list{margin-top:40px;border: 1px solid #F0F0F0;border-radius: 20px;width:500px;}
  .window-confirm .window .process-list .item{border-top:1px solid #F0F0F0;padding:10px 16px;display: flex;justify-content: space-between;align-items: center;}
  .window-confirm .window .process-list .item a{width:30px;height:30px;flex-shrink: 0;background-image: url(../assets/img/processing_wait.png);background-repeat: no-repeat;background-position: center;background-size: 15px;}
  .window-confirm .window .process-list .item div{display: flex;}
  .window-confirm .window .process-list .finish a{background-image: url(../assets/img/processing_ok.png);background-size: 20px;}
  .window-confirm .window .process-list .finish *{color:#3B5998 !important;}
  .window-confirm .window .process-list .error a{background-image: url(../assets/img/processing_error.png);}
  .window-confirm .window .process-list .error *{color:#C90000 !important;}
  .window-confirm .window .process-list .loading a{background-image: url(../assets/img/processing_wait.png);animation: spin 1.5s linear infinite;-webkit-animation: spin 1.5s linear infinite;}
  @keyframes spin{
    from{transform: rotate(0deg);}
    to{transform: rotate(360deg);transition: all 1.5s;}
  }
  .pro-remark{font-family: 'Pretendard';color: #797979;margin:10px 0 30px 0;letter-spacing: -0.6px;}
  .result{font-family: 'Pretendard';color: #3B5998;margin:20px 0 10px 0;}
  .result a{color:#F46969;}
  .window-confirm .window .process-list .item div span{flex-shrink: 0;margin-right:14px;color: #888888;}
  .window-confirm .window .process-list .item div p{color: #888888;}
  .window-confirm .window .process-list .item:nth-child(1){border-top-width:0px;}
  .window-confirm .window .file-list{margin-bottom:20px;width:100%;overflow-y: auto;}
  .window-confirm .window .file-list .item{padding:30px 0;border-top:1px solid #000000;margin-bottom:10px;}
  .window-confirm .window .file-list .item:nth-child(1){border-top-width:0;}
  .window-confirm .window .file-list .item .top{display: flex;align-items: center;color: #4A4A4A;align-items: center;}
  .window-confirm .window .file-list .item .top div{margin-left:30px;width:95px;height:24px;cursor: pointer;border-radius: 35px;background: #EC2222;font-size: 16px;color:#ffffff;font-weight:bold;display: flex;align-items: center;justify-content: center;}
  .window-confirm .window .file-list .item .bottom{margin-top:20px;width:100%;}
  .window-confirm .window .file-list .item .bottom .isd{display: flex;margin-top:20px;}
  .window-confirm .window .file-list .item .bottom .isd .left{display: flex;flex-direction: column;width:100%;font-size: 18px;letter-spacing: -0.6px;color:#4A4A4A;}
  .window-confirm .window .file-list .item .bottom .isd .left p{font-size: 14px;letter-spacing: -0.6px;margin-top:10px;color:#000000;}
  .window-confirm .window .file-list .item .bottom .isd .right{display: flex;align-items: center;flex-shrink: 0;width:230px;}
  .window-confirm .window .file-list .item .bottom .isd .right span{margin-bottom:10px;}
  .window-confirm .window .file-list .item .bottom .isd .right img{cursor: pointer;}
  .window-confirm .window .file-list .item .bottom .isd .right label{display: flex;align-items: center;margin:0 5px;cursor: pointer;}
  .window-confirm .window .file-list .item .bottom .isd .right label a{width:24px;height:24px;margin-right:5px;border-radius: 50%;border:1px solid #D0D0D0;}
  .window-confirm .window .file-list .item .bottom .isd .right label .act{border:7px solid #000000;}
  .window-confirm .window .service-count{width:100%;border: 1px solid #AFAFAF;border-radius: 20px;padding:10px;display: flex;flex-direction: column;align-items: center;}
  .window-confirm .window .service-count div{width:80%;margin:10px 0;display: flex;justify-content: space-between;align-items: center;color: #4A4A4A;}
  .window-confirm .window .service-count div p{display: flex;flex-direction: column;font-size: 18px;letter-spacing: -0.6px;color:#4A4A4A;}
  .window-confirm .window .service-count div p a{font-size: 14px;color:#000000;letter-spacing: -0.6px;margin-top:10px;}
  .window-confirm .window .service-count div span{ color: #0020C9;}
  .window-confirm .window .service-validate{color: #C90000;letter-spacing:0;display:flex;align-items:center;margin:10px 0;}
  .window-confirm .window .service-validate span{background: #01388B;border-radius: 10px;height: 40px;width: 80px;margin-left:10px;cursor: pointer;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .che-div{display: flex;align-items: center;font-size: 16px;letter-spacing: 0;color:#4A4A4A;margin:20px 0;}
  .window-confirm .window .che-div input{width:20px;height:20px;margin-right:10px;}
  .window-confirm .window .che-div a{color: #007AFF;}
  .window-confirm .window .btn{background: #797979;border-radius: 10px;height: 40px;width: 80px;cursor:no-drop;color:#ffffff;font-family: 'Pretendard';font-size: 15px;display: flex;justify-content: center;align-items: center;}
  .window-confirm .window .ok{background: #01388B !important;cursor: pointer;}
  .window-confirm .window .title2{margin:16px 0;}
  .window-confirm .window .link{color:#1296DB;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
  }
</style>
