<template>
  <div class="con" v-loading="formLoading">
    <div class="windform" v-if="formwin3">
      <div class="conts" style="height:270px;width:530px;">
        <div class="clos">
          <img @click="formwin3 = false" src="../assets/img2/closeBtn.png"/>
        </div>
        <p style="margin:50px 0 20px 0;">회원을 탈퇴 하시겠습니까?</p>
        <a style="display: flex;align-items: center;color:#01388B;font-size:15px;"><img style="margin-right:10px;width:24px;height:24px;" src="../assets/img2/th.png"/>탈퇴 시 시스템 상에 이용 중인 모든 서비스가 중단 됩니다.</a>
        <div @click="deleteInit" style="width: 160px;height: 40px;font-size:18px;border-radius: 3px;margin-top:20px;background-color: #B03E3E;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;">
          폐기하기
        </div>
      </div>
    </div>
    <div class="top-img pc">
      <div class="div">
        <a>마이페이지</a>
        <p>회원 정보 관리, 공동인증서 관리</p>
        <p>비밀 보관 현황, 원본증명서 신청, 결제내역, 환불처리</p>
      </div>
    </div>
    <div class="content">
      <div class="main">
        <div class="menu pc">
          <a class="act">회원 정보 관리</a>
          <a @click="goPage('/myInformationCert')">공동인증서 관리</a>
          <a @click="goPage('/myFile')">파일 보관 현황</a>
          <a @click="goPage('/myCert')">증명서 신청내역</a>
          <a @click="goPage('/myTransaction')">결제/환불</a>
        </div>
        <div class="list-div">
          <p style="font-size: 20px;">회원 가입 정보 (보유금액 : {{formatPrice(myCount)}}원)</p>
          <table v-if="user != null" border="0">
            <tr>
              <td style="height:60px;"><b>ID</b></td>
              <td style="color:#5F5F5F">{{user.user_id}}</td>
              <td style="width:140px;letter-spacing: -0.6px;"><a @click="goPage('/updatePwd')">비밀번호 변경</a></td>
            </tr>
            <tr>
              <td style="height:60px;"><b>이메일 등록 정보</b></td>
              <td style="color:#5F5F5F">{{user.email}}</td>
              <td style="letter-spacing: -0.6px;"><a @click="goPage('/modifyEmail')">이메일 변경</a></td>
            </tr>
            <tr>
              <td style="height:60px;"><b>휴대폰번호 등록 정보</b></td>
              <td style="color:#5F5F5F">{{user.phone_number}}</td>
              <td style="letter-spacing: -0.6px;" @click="goPage('/modifyMobile')"><a>휴대폰번호 변경</a></td>
            </tr>
            <tr>
              <td colspan="3">
                <div @click="formwin3 = true" style="width:200px;height:40px;border-radius: 3px;background: #B03E3E;font-size:18px;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:20px auto;">회원 탈퇴하기</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { deleteAction, getAction,postAction } from '@/network/manage'
  import { getActionApi} from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  import { ACCESS_TOKEN,CERT_LOGIN } from '@/store/mutation-types'
  import Vue from 'vue'
  export default {
    name: 'MyInformation',
    components: {
    },
    data() {
      return {
        formLoading:false,
        user:null,
        myCount:0,
        formwin3:false,
        msg:'',
        validateResult:false,
        url: {
          user:'/login-management/user',
          certificate_information:'/dn-information/certificate-information',
          delete:'/user/userInformation/delete_member'
        }
      }
    },
    computed: {

    },
    created(){
      this.loadUserInfo()
    },
    filters:{

    },
    methods:{
      formatPrice(value){
        return new Intl.NumberFormat('en-US').format(value);
      },
      goPage(url){
        this.$router.push({path:url});
      },
      deleteInit(){
        //调起证书
        const that = this;
        nxTSPKI.signData('hash', {}, function(res) {
          that.sign_complete_callback(res);
        });
      },
      deleteUser(){
        const that = this;
        that.formLoading = true;
        deleteAction(this.url.delete).then((res) => {
          if (res.success) {
            Vue.ls.remove(ACCESS_TOKEN);
            Vue.ls.remove(CERT_LOGIN);
            window.location.href='/'
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.formLoading = false
        })
      },
      sign_complete_callback(res) {
        this.msg = '';
        this.validateResult = false;
        const that = this;
        if (res.code == 0) {
          let signedData = res.data.signedData;
          let param = res.data.certInfo;
          param.dn_login_signature = signedData;
          //保存签证信息
          that.formLoading = true;
          postAction('/user/userInformation/deleteUserInit',param).then((res) => {
            that.formLoading = false;
            if (res.success) {
              that.deleteUser();
            } else {
              that.$message.warning(res.message);
            }
          }).catch(res => {
            that.$message.warning(res.message);
            that.formLoading = false;
          })
        }else {
          alert("error code = " + res.code + ", message = " + res.errorMessage);
        }
      },
      loadUserInfo(){
        const that = this;
        that.formLoading = true;
        getActionApi(this.url.user).then((res) => {
          if (res.code == 200) {
            that.user = res.data.contents[0];
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        }).finally(res => {
          that.formLoading = false
        })

        getAction('/user/userInformation/my_info').then(res => {
          if (res.success) {
            if(res.result.userinfo.userName == null && res.result.userinfo.residentNumber == null && res.result.userinfo.businessName == null && res.result.userinfo.businessNumber == null)
              window.location.href = '/registerIDCert'
            that.myCount = res.result.userinfo.userPoints
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.warning(err.message);
        })
      },

    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .top-img{width:100%;font-size:26px;background-image: url(../assets/img2/mypage_bg.png);background-size: cover;background-position: center center;}
  .top-img .div{height:250px;color:#ffffff;display: flex;align-items: center;justify-content: center;flex-direction: column;background: rgba(0, 0, 0, 0.40);}
  .top-img .div a{font-size: 35px;letter-spacing: -0.6px;font-weight:blod;}
  .top-img .div p{font-size: 20px;letter-spacing: -0.6px;font-weight:blod;margin-top:20px;}
  .top-img .div p:nth-child(3){margin-top:5px !important;}
  .options{display: flex;padding:6px 0;height:auto !important;}
  .body{text-align: center;}
  .windform{position: fixed;width:100vw;height:100vh;top:0;left:0;background: rgba(1, 56, 139, 0.2);z-index:99999;display: flex;justify-content: center;align-items: center;}
  .windform .conts{background-color: #ffffff;border-radius:30px;width:530px;height:270px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);padding:10px 0;display: flex;flex-direction: column;align-items: center;}
  .windform .conts .clos{width:100%;display:flex;justify-content: flex-end;}
  .windform .conts .clos img{width:40px;position: absolute;cursor: pointer;margin-right:14px;}
  .windform .conts p{font-size: 24px;font-weight: bold;margin:80px 0 50px 0;}
  .windform .conts .main{width:100%;flex-direction: column;padding:0 30px;font-size: 15px;align-items:center;}
  .windform .conts .main .list{height:260px;overflow-y: auto;width:100%;}
  .windform .conts .main .ok{background-color: #01388B !important;cursor: pointer;}
  .windform .conts .main .btn{width:300px;height: 40px;font-size:18px;border-radius:10px;margin-top:20px;background-color: #C4C4C4;color:#ffffff;display: flex;justify-content: center;align-items: center;}
  .windform .conts .row{display: flex;align-items: center;width:100%;}
  .windform .conts .row span{display: flex;justify-content:center;align-items: center;padding:5px 0;}
  .windform .conts .row span a{color:#00C999;}
  .windform .conts .row span:nth-child(1){width:50%;}
  .windform .conts .row span:nth-child(2){width:25%;}
  .windform .conts .row span:nth-child(3){width:25%;}
  .windform .conts .row span img{width:22px;height:22px;cursor: pointer;}
  .windform .conts .header{padding:13px 20px 13px 0;color:#5F5F5F;font-weight: bold;border-bottom:1px solid rgba(72, 83, 100, 0.46);margin-bottom:10px;}
  .content{display: flex;flex-direction: column;padding:0px 0 60px 0;margin:auto;}
  .title{font-family: 'Pretendard';font-size:30px;font-weight: bold;color: #01388B;}
  .main{display: flex;}
  .main .menu{width:140px;flex-shrink: 0;display: flex;flex-direction: column;}
  .main .menu a{font-family: 'Pretendard';font-size: 20px;padding:20px 0;color: #888888;cursor: pointer;font-weight: bold;letter-spacing: -1px;border-bottom:1px solid #000000;}
  .main .menu a:hover{color: #01388B;}
  .main .menu a:last-child{border-bottom-width: 0;}
  .main .menu .act{color: #01388B;}
  .main .list-div{width:100%;padding:71px 160px 0 160px;}
  table{width:550px;}
  table tr td:nth-child(1){width:160px;}
  table tr td:nth-child(3){padding-left:50px;}
  .main .list-div input{border: 1px solid #C4C4C4;border-radius: 8px;width:360px;height:38px;padding:0 10px;font-size:14px;}
  .main .list-div a{color:#0020C9;cursor: pointer;}
  .main .list-div div{margin:10px 0;}
  .main .list-div .flex{display: flex;margin:0 0;}
  .main .list-div .flex span{width:140px;color:#888888;margin:10px 0;}
  .main .list-div .cont p{font-size:20px;margin:40px 0 30px 0;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .content{padding:10px 0 20px 0;}
    .main{margin-top:0px;}
    .pc{display: none !important;}
    .main .list-div{padding:20px;}
    table{width:100%;}
    table tr td:nth-child(1){width:120px;}
    table tr td:nth-child(3){padding-left:0px;}
    .title{margin:10px 0 6px 0 !important;font-size:20px !important;}
    .title{font-size: 20px;margin:16px 0;text-align: center;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .con{min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .con{min-height:calc(100vh - 206px);}
  }
</style>
