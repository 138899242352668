<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content" v-if="!showLogin">
      <div class="title">비밀번호 재설정</div>
      <div class="info">
        <div class="item">
          <span>새 비밀번호</span>
          <input v-model="password" type="password" placeholder="새 비밀번호를 입력하세요"/>
        </div>
        <div class="item">
          <span>비밀번호 확인</span>
          <input v-model="password2" type="password" placeholder="새 비밀번호를 다시 입력하세요"/>
        </div>
        <div v-if="msg != ''" style="color:#C90000;font-size: 14px;text-align: left;">{{msg}}</div>
        <div class="btn" @click="updatePassword">확인</div>
      </div>
    </div>
    <div class="content" v-if="showLogin">
      <div class="info">
        <div class="title">암호가 성공적으로 수정되었습니다.</div>
        <div class="btn" @click="goPage('/myInformation')">확인</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getActionApi,putActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  export default {
    name: 'FindPwd',
    components: {
    },
    data() {
      return {
        emailMobile:'',
        showPassword:false,
        showLogin:false,
        code:'',
        userId:'',
        emailDisabled:false,
        msg:'',
        password:'',
        password2:'',
        time:120,
        timer:null,
        loading:false,
        formLoading:false,
        codeId:'',
        updateParam:{},
        validateParam:{},
        validateUrl:'',
        emailValidate:'^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$',
        url: {
          updateUrl:'login-management/password-update'
        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      updatePassword(){
        const that = this;
        this.password = this.password.trim()
        this.password2 = this.password2.trim()
        that.msg = '';
        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,}$/
        if(this.password == ''){
          that.msg = '신규 비밀번호를 입력하여 주세요'
        }else if(!reg.test(this.password)){
          that.msg = '8자이상, 숫자 영문 특수문자 포함'
        }else if(this.password != this.password2){
          that.msg = '패스워드 불일치'
        }else{
          that.formLoading = true;
          //修改密码
          putActionApi(that.url.updateUrl,{new_password:this.password}).then((res) => {
            if (res.code == 200) {
              //that.showPassword
              that.showLogin = true;
              that.formLoading = false;
            } else {
              that.msg = res.message;
              that.formLoading = false;
            }
          }).catch(res => {
            that.msg = res.message;
            that.formLoading = false;
          })
        }
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'Noto Sans CJK JP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:30px 0;font-family: 'Pretendard';font-weight: bold;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'SF UI Display';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;padding:0 10px 0 0;display: flex;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:36px !important;}
  .box-item a{flex-shrink: 0;color:#0020C9;font-family: 'Pretendard';font-size:15px;cursor: pointer;font-weight: bold;}
  .link{font-family: 'Pretendard';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:10px;font-family: 'Pretendard';}
  .content .info .item input{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:48px;padding:0 10px;font-size:13px;}
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;cursor: pointer;height: 48px;line-height:1;margin:60px 0 10px 0;color:#ffffff;background: #01388B;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:50px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
